import {
	Box,
	Button,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	Link,
	MenuItem,
	Select,
	SelectChangeEvent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import {
	AddCircleOutline,
	ArrowDownwardOutlined,
	ArrowUpwardOutlined,
	CloseOutlined,
	KeyboardArrowDown,
	KeyboardArrowUp,
	SendOutlined,
	Visibility,
} from "@mui/icons-material";
import { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React from "react";
import Sidebar from "../../../components/layouts/sidebar";
import Header from "../../../components/layouts/header";

const Dashboard: React.FC = () => {
	// Dialog
	const [dialogAddNewFollowupOpen, setDialogAddNewFollowupOpen] =
		React.useState(false);
	const dialogAddNewFollowupClickOpen = () => {
		setDialogAddNewFollowupOpen(true);
	};
	const dialogAddNewFollowupClose = () => {
		setDialogAddNewFollowupOpen(false);
	};

	const [enquiryDialogOpen, setEnquiryDialogOpen] = React.useState(false);
	const enquiryDialogHandleClickOpen = () => {
		setEnquiryDialogOpen(true);
	};
	const enquiryDialogHandleClose = () => {
		setEnquiryDialogOpen(false);
	};

	const [dialogRemarkOpen, setDialogRemarkOpen] = React.useState(false);
	const dialogRemarkClickOpen = () => {
		setDialogRemarkOpen(true);
	};
	const dialogRemarkClose = () => {
		setDialogRemarkOpen(false);
	};

	// DatePicker
	const [value, setValue] = React.useState<Dayjs | null>(null);

	// Select
	const [selectParent, setSelectParent] = React.useState("");
	const selectParentChange = (event: SelectChangeEvent) => {
		setSelectParent(event.target.value as string);
	};

	const [selectChild, setSelectChild] = React.useState("");
	const selectChildChange = (event: SelectChangeEvent) => {
		setSelectChild(event.target.value as string);
	};

	const [selectStatus2, setSelectStatus2] = React.useState("");
	const selectStatus2Change = (event: SelectChangeEvent) => {
		setSelectStatus2(event.target.value as string);
	};
	const [open, setOpen] = React.useState(false);

	// Table Pagination
	const [page, setPage] = React.useState(2);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	return (
		<>
			<div className="main-content">
				<Header />
				<div className="page-content">
					<Sidebar />
					<div className="page-inner">
						<div className="page-heading-wrapper">
							<h4 className="page-heading">Dashboard</h4>
						</div>
						<Grid container spacing={2} className="mb-20">
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Link
									href="#"
									title="Today"
									className="white-paper statistic-card"
									underline="none"
								>
									<Typography variant="h6">Today</Typography>
									<Typography variant="body1">0</Typography>
								</Link>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Link
									href="#"
									title="Tomorrow"
									className="white-paper statistic-card"
									underline="none"
								>
									<Typography variant="h6">Tomorrow</Typography>
									<Typography variant="body1">0</Typography>
								</Link>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Link
									href="#"
									title="Pending"
									className="white-paper statistic-card"
									underline="none"
								>
									<Typography variant="h6">Pending</Typography>
									<Typography variant="body1">12</Typography>
								</Link>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Link
									href="#"
									title="Item"
									className="white-paper statistic-card"
									underline="none"
								>
									<Typography variant="h6">Item</Typography>
									<Typography variant="body1">12</Typography>
								</Link>
							</Grid>
						</Grid>
						<div className="white-paper">
							<div className="table-negative-space">
								<TableContainer>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>
													Sr.
													<IconButton aria-label="Sort" className="sort-icon">
														<ArrowDownwardOutlined />
													</IconButton>
												</TableCell>
												<TableCell>
													Added At
													<IconButton aria-label="Sort" className="sort-icon">
														<ArrowUpwardOutlined />
													</IconButton>
												</TableCell>
												<TableCell>
													For
													<IconButton aria-label="Sort" className="sort-icon">
														<ArrowUpwardOutlined />
													</IconButton>
												</TableCell>
												<TableCell>
													Name
													<IconButton aria-label="Sort" className="sort-icon">
														<ArrowUpwardOutlined />
													</IconButton>
												</TableCell>
												<TableCell>
													Mobile
													<IconButton aria-label="Sort" className="sort-icon">
														<ArrowDownwardOutlined />
													</IconButton>
												</TableCell>
												<TableCell>
													Type
													<IconButton aria-label="Sort" className="sort-icon">
														<ArrowUpwardOutlined />
													</IconButton>
												</TableCell>
												<TableCell>
													Segment
													<IconButton aria-label="Sort" className="sort-icon">
														<ArrowUpwardOutlined />
													</IconButton>
												</TableCell>
												<TableCell>
													Bhk/Office
													<IconButton aria-label="Sort" className="sort-icon">
														<ArrowUpwardOutlined />
													</IconButton>
												</TableCell>
												<TableCell className="remark-col">
													Last Remark
													<IconButton aria-label="Sort" className="sort-icon">
														<ArrowUpwardOutlined />
													</IconButton>
												</TableCell>
												<TableCell>
													NFD
													<IconButton aria-label="Sort" className="sort-icon">
														<ArrowUpwardOutlined />
													</IconButton>
												</TableCell>
												<TableCell>
													Status
													<IconButton aria-label="Sort" className="sort-icon">
														<ArrowUpwardOutlined />
													</IconButton>
												</TableCell>
												<TableCell>
													Employee
													<IconButton aria-label="Sort" className="sort-icon">
														<ArrowUpwardOutlined />
													</IconButton>
												</TableCell>
												<TableCell>
													<div className="d-flex justify-content-center align-item-center">
														Add
														<IconButton aria-label="Sort" className="sort-icon">
															<ArrowUpwardOutlined />
														</IconButton>
													</div>
												</TableCell>
												<TableCell>
													<div className="d-flex justify-content-center align-item-center">
														WP
														<IconButton aria-label="Sort" className="sort-icon">
															<ArrowUpwardOutlined />
														</IconButton>
													</div>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow className="green">
												<TableCell>1</TableCell>
												<TableCell>18/05/23</TableCell>
												<TableCell>Buy</TableCell>
												<TableCell>Kamlesh mali</TableCell>
												<TableCell>7742073462</TableCell>
												<TableCell>Commercial</TableCell>
												<TableCell>Showroom / Shop</TableCell>
												<TableCell>Showroom</TableCell>
												<TableCell className="remark-col">
													<Link href="#" onClick={dialogRemarkClickOpen}>
														He will available for meeting on Tuesday
													</Link>
												</TableCell>
												<TableCell>22/05/23</TableCell>
												<TableCell>Open</TableCell>
												<TableCell>Bhargav Rakholiya</TableCell>
												<TableCell align="center">
													<div className="button-wrapper ml-ng-10">
														<IconButton onClick={dialogAddNewFollowupClickOpen}>
															<AddCircleOutline className="blue-icon" />
														</IconButton>
														<IconButton onClick={enquiryDialogHandleClickOpen}>
															<Visibility className="blue-icon" />
														</IconButton>
													</div>
												</TableCell>
												<TableCell align="center">
													<IconButton
														href="http://wa.me/917742073462"
														className="ml-ng-10"
													>
														<SendOutlined className="blue-icon" />
													</IconButton>
												</TableCell>
											</TableRow>
											<TableRow className="red">
												<TableCell>2</TableCell>
												<TableCell>18/05/23</TableCell>
												<TableCell>Buy</TableCell>
												<TableCell>Chirag Thakkar</TableCell>
												<TableCell>9913802767</TableCell>
												<TableCell>Residential</TableCell>
												<TableCell>Villa</TableCell>
												<TableCell>3Bhk</TableCell>
												<TableCell className="remark-col">
													<Link href="#" onClick={dialogRemarkClickOpen}>
														Need bungalow 3Cr, 2-3Car parking, share details he
														will visit weekdays
													</Link>
												</TableCell>
												<TableCell>15/05/23</TableCell>
												<TableCell>Open</TableCell>
												<TableCell>Bhargav Rakholiya</TableCell>
												<TableCell align="center">
													<div className="button-wrapper ml-ng-10">
														<IconButton onClick={dialogAddNewFollowupClickOpen}>
															<AddCircleOutline className="blue-icon" />
														</IconButton>
														<IconButton onClick={enquiryDialogHandleClickOpen}>
															<Visibility className="blue-icon" />
														</IconButton>
													</div>
												</TableCell>
												<TableCell align="center">
													<IconButton
														href="http://wa.me/917742073462"
														className="ml-ng-10"
													>
														<SendOutlined className="blue-icon" />
													</IconButton>
												</TableCell>
											</TableRow>
											<TableRow className="red">
												<TableCell>3</TableCell>
												<TableCell>13/05/23</TableCell>
												<TableCell>Buy</TableCell>
												<TableCell>Girish Thakkar</TableCell>
												<TableCell>9924276006</TableCell>
												<TableCell>Residential</TableCell>
												<TableCell>Flat</TableCell>
												<TableCell>3Bhk</TableCell>
												<TableCell className="remark-col">
													<Link href="#" onClick={dialogRemarkClickOpen}>
														He is on vacation trip, follow Saturday
													</Link>
												</TableCell>
												<TableCell>03/06/23</TableCell>
												<TableCell>Open</TableCell>
												<TableCell>Bhargav Rakholiya</TableCell>
												<TableCell align="center">
													<div className="button-wrapper ml-ng-10">
														<IconButton onClick={dialogAddNewFollowupClickOpen}>
															<AddCircleOutline className="blue-icon" />
														</IconButton>
														<IconButton onClick={enquiryDialogHandleClickOpen}>
															<Visibility className="blue-icon" />
														</IconButton>
													</div>
												</TableCell>
												<TableCell align="center">
													<IconButton
														href="http://wa.me/917742073462"
														className="ml-ng-10"
													>
														<SendOutlined className="blue-icon" />
													</IconButton>
												</TableCell>
											</TableRow>
											<TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
												<TableCell>
													<IconButton
														aria-label="expand row"
														size="small"
														onClick={() => setOpen(!open)}
													>
														{open ? (
															<ArrowDownwardOutlined />
														) : (
															<ArrowUpwardOutlined />
														)}
													</IconButton>
												</TableCell>
												<TableCell>18/05/23</TableCell>
												<TableCell>Buy</TableCell>
												<TableCell>Kamlesh mali</TableCell>
												<TableCell>7742073462</TableCell>
												<TableCell>Commercial</TableCell>
												<TableCell>Showroom / Shop</TableCell>
												<TableCell>Showroom</TableCell>
												<TableCell className="remark-col">
													<Link href="#" onClick={dialogRemarkClickOpen}>
														He will available for meeting on Tuesday
													</Link>
												</TableCell>
												<TableCell>22/05/23</TableCell>
												<TableCell>Open</TableCell>
												<TableCell>Bhargav Rakholiya</TableCell>
												<TableCell align="center">
													<div className="button-wrapper ml-ng-10">
														<IconButton onClick={dialogAddNewFollowupClickOpen}>
															<AddCircleOutline className="blue-icon" />
														</IconButton>
														<IconButton onClick={enquiryDialogHandleClickOpen}>
															<Visibility className="blue-icon" />
														</IconButton>
													</div>
												</TableCell>
												<TableCell align="center">
													<IconButton
														href="http://wa.me/917742073462"
														className="ml-ng-10"
													>
														<SendOutlined className="blue-icon" />
													</IconButton>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell
													style={{ paddingBottom: 0, paddingTop: 0 }}
													colSpan={6}
												>
													<Collapse in={open} timeout="auto" unmountOnExit>
														<Box sx={{ margin: 1 }}>
															<Typography
																variant="h6"
																gutterBottom
																component="div"
															>
																History
															</Typography>
														</Box>
													</Collapse>
												</TableCell>
											</TableRow>
											<TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
												<TableCell>
													<IconButton
														aria-label="expand row"
														size="small"
														onClick={() => setOpen(!open)}
													>
														{open ? (
															<ArrowDownwardOutlined />
														) : (
															<ArrowUpwardOutlined />
														)}
													</IconButton>
												</TableCell>
												<TableCell>18/05/23</TableCell>
												<TableCell>Buy</TableCell>
												<TableCell>Kamlesh mali</TableCell>
												<TableCell>7742073462</TableCell>
												<TableCell>Commercial</TableCell>
												<TableCell>Showroom / Shop</TableCell>
												<TableCell>Showroom</TableCell>
												<TableCell className="remark-col">
													<Link href="#" onClick={dialogRemarkClickOpen}>
														He will available for meeting on Tuesday
													</Link>
												</TableCell>
												<TableCell>22/05/23</TableCell>
												<TableCell>Open</TableCell>
												<TableCell>Bhargav Rakholiya</TableCell>
												<TableCell align="center">
													<div className="button-wrapper ml-ng-10">
														<IconButton onClick={dialogAddNewFollowupClickOpen}>
															<AddCircleOutline className="blue-icon" />
														</IconButton>
														<IconButton onClick={enquiryDialogHandleClickOpen}>
															<Visibility className="blue-icon" />
														</IconButton>
													</div>
												</TableCell>
												<TableCell align="center">
													<IconButton
														href="http://wa.me/917742073462"
														className="ml-ng-10"
													>
														<SendOutlined className="blue-icon" />
													</IconButton>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell
													style={{ paddingBottom: 0, paddingTop: 0 }}
													colSpan={6}
												>
													<Collapse in={open} timeout="auto" unmountOnExit>
														<Box sx={{ margin: 1 }}>
															<Typography
																variant="h6"
																gutterBottom
																component="div"
															>
																History
															</Typography>
														</Box>
													</Collapse>
												</TableCell>
											</TableRow>
											<TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
												<TableCell>
													<IconButton
														aria-label="expand row"
														size="small"
														onClick={() => setOpen(!open)}
													>
														{open ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
													</IconButton>
												</TableCell>
												<TableCell>18/05/23</TableCell>
												<TableCell>Buy</TableCell>
												<TableCell>Kamlesh mali</TableCell>
												<TableCell>7742073462</TableCell>
												<TableCell>Commercial</TableCell>
												<TableCell>Showroom / Shop</TableCell>
												<TableCell>Showroom</TableCell>
												<TableCell className="remark-col">
													<Link href="#" onClick={dialogRemarkClickOpen}>
														He will available for meeting on Tuesday
													</Link>
												</TableCell>
												<TableCell>22/05/23</TableCell>
												<TableCell>Open</TableCell>
												<TableCell>Bhargav Rakholiya</TableCell>
												<TableCell align="center">
													<div className="button-wrapper ml-ng-10">
														<IconButton onClick={dialogAddNewFollowupClickOpen}>
															<AddCircleOutline className="blue-icon" />
														</IconButton>
														<IconButton onClick={enquiryDialogHandleClickOpen}>
															<Visibility className="blue-icon" />
														</IconButton>
													</div>
												</TableCell>
												<TableCell align="center">
													<IconButton
														href="http://wa.me/917742073462"
														className="ml-ng-10"
													>
														<SendOutlined className="blue-icon" />
													</IconButton>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell
													style={{ paddingBottom: 0, paddingTop: 0 }}
													colSpan={6}
												>
													<Collapse in={open} timeout="auto" unmountOnExit>
														<Box sx={{ margin: 1 }}>
															<Typography
																variant="h6"
																gutterBottom
																component="div"
															>
																History
															</Typography>
														</Box>
													</Collapse>
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
								<div className="pagination-wrapper">
									<TablePagination
										component="div"
										count={100}
										page={page}
										onPageChange={handleChangePage}
										rowsPerPage={rowsPerPage}
										onRowsPerPageChange={handleChangeRowsPerPage}
									/>
								</div>
							</div>
						</div>
					</div>
					{/* Add New Follow Up Dialog */}
					<Dialog
						open={dialogAddNewFollowupOpen}
						onClose={dialogAddNewFollowupClose}
						maxWidth="sm"
						fullWidth
					>
						<DialogTitle>
							<Typography variant="body1" className="d-flex align-item-center">
								Add New Followup
								<span className="d-block mx-4">Chirag Thakkar</span>
								<span>7742073462</span>
							</Typography>
							<IconButton
								aria-label="Close"
								onClick={dialogAddNewFollowupClose}
							>
								<CloseOutlined />
							</IconButton>
						</DialogTitle>
						<DialogContent>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<FormControl fullWidth>
										<InputLabel id="parent-label">Parent</InputLabel>
										<Select
											labelId="parent-label"
											id="parent-select"
											value={selectParent}
											label="Parent"
											onChange={selectParentChange}
										>
											<MenuItem value="PhoneCall">Phone Call</MenuItem>
											<MenuItem value="visit">Visit</MenuItem>
											<MenuItem value="meeting">Meeting</MenuItem>
											<MenuItem value="deal">Deal</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6}>
									<FormControl fullWidth>
										<InputLabel id="child-label">Child</InputLabel>
										<Select
											labelId="child-label"
											id="child-select"
											value={selectChild}
											label="Child"
											onChange={selectChildChange}
										>
											<MenuItem value="PhoneCall">Phone Call</MenuItem>
											<MenuItem value="visit">Visit</MenuItem>
											<MenuItem value="meeting">Meeting</MenuItem>
											<MenuItem value="deal">Deal</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6} className="datepicker-wrappper">
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DemoContainer components={["DatePicker"]}>
											<DatePicker
												value={value}
												className="w-full"
												label="NFD"
												onChange={(newValue) => setValue(newValue)}
											/>
										</DemoContainer>
									</LocalizationProvider>
								</Grid>
								<Grid item xs={12} sm={6}>
									<FormControl fullWidth>
										<InputLabel id="statuslabel">Status</InputLabel>
										<Select
											labelId="statuslabel"
											id="statusselect"
											value={selectStatus2}
											label="Status"
											onChange={selectStatus2Change}
										>
											<MenuItem value="PhoneCall">Phone Call</MenuItem>
											<MenuItem value="visit">Visit</MenuItem>
											<MenuItem value="meeting">Meeting</MenuItem>
											<MenuItem value="deal">Deal</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="remark"
										label="Remark"
										type="text"
										fullWidth
										variant="outlined"
									/>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button variant="contained" onClick={dialogAddNewFollowupClose}>
								Add
							</Button>
							<Button variant="outlined" onClick={dialogAddNewFollowupClose}>
								Cancel
							</Button>
						</DialogActions>
					</Dialog>
					{/* Enquiry Detail */}
					<Dialog
						open={enquiryDialogOpen}
						onClose={enquiryDialogHandleClose}
						maxWidth="md"
						fullWidth
					>
						<DialogTitle>
							Enquiry Details
							<IconButton aria-label="Close" onClick={enquiryDialogHandleClose}>
								<CloseOutlined />
							</IconButton>
						</DialogTitle>
						<DialogContent>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Typography variant="body2" className="text-light-grey">
										Added At
									</Typography>
									<Typography variant="body1" className="text-dark-black">
										18/05/23
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Typography variant="body2" className="text-light-grey">
										For
									</Typography>
									<Typography variant="body1" className="text-dark-black">
										Buy
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Typography variant="body2" className="text-light-grey">
										Name
									</Typography>
									<Typography variant="body1" className="text-dark-black">
										Kamlesh mali
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Typography variant="body2" className="text-light-grey">
										Mobile
									</Typography>
									<Typography variant="body1" className="text-dark-black">
										7742073462
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Typography variant="body2" className="text-light-grey">
										Type
									</Typography>
									<Typography variant="body1" className="text-dark-black">
										Commercial
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Typography variant="body2" className="text-light-grey">
										Segment
									</Typography>
									<Typography variant="body1" className="text-dark-black">
										Showroom / Shop
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Typography variant="body2" className="text-light-grey">
										Bhk-Floor
									</Typography>
									<Typography variant="body1" className="text-dark-black">
										Showroom
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Typography variant="body2" className="text-light-grey">
										Last Remark
									</Typography>
									<Typography variant="body1" className="text-dark-black">
										Aristo new
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Typography variant="body2" className="text-light-grey">
										Next Followup Date
									</Typography>
									<Typography variant="body1" className="text-dark-black">
										22/05/23
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Typography variant="body2" className="text-light-grey">
										Budget
									</Typography>
									<Typography variant="body1" className="text-dark-black">
										70-1cr
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Typography variant="body2" className="text-light-grey">
										Status
									</Typography>
									<Typography variant="body1" className="text-dark-black">
										Open
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Typography variant="body2" className="text-light-grey">
										Source
									</Typography>
									<Typography variant="body1" className="text-dark-black">
										Reference
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Typography variant="body2" className="text-light-grey">
										Non Use?
									</Typography>
									<Typography variant="body1" className="text-dark-black">
										-
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Typography variant="body2" className="text-light-grey">
										Assign By
									</Typography>
									<Typography variant="body1" className="text-dark-black">
										Bhargav Rakholiya
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Typography variant="body2" className="text-light-grey">
										Employee?
									</Typography>
									<Typography variant="body1" className="text-dark-black">
										Bhargav Rakholiya
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Typography variant="body2" className="text-light-grey">
										Area
									</Typography>
									<Typography variant="body1" className="text-dark-black">
										Gota
									</Typography>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button variant="contained" onClick={enquiryDialogHandleClose}>
								Cancel
							</Button>
						</DialogActions>
					</Dialog>
					{/* Remark Dialog */}
					<Dialog
						open={dialogRemarkOpen}
						onClose={dialogRemarkClose}
						maxWidth="md"
						fullWidth
					>
						<DialogTitle>
							<Typography variant="body1" className="d-flex align-item-center">
								Communication
								<span className="d-block mx-4">Kamlesh mali</span>
								<span>7742073462</span>
							</Typography>
							<IconButton aria-label="Close" onClick={dialogRemarkClose}>
								<CloseOutlined />
							</IconButton>
						</DialogTitle>
						<DialogContent>
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Sr.</TableCell>
											<TableCell>Date</TableCell>
											<TableCell className="remark-col">Remark</TableCell>
											<TableCell>NFD</TableCell>
											<TableCell>User</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell>1</TableCell>
											<TableCell>05-18-2023</TableCell>
											<TableCell className="remark-col">
												Phone Call : Call back request : He will available for
												meeting on Tuesday
											</TableCell>
											<TableCell>05-22-2023</TableCell>
											<TableCell>Bhargav Rakholiya</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>2</TableCell>
											<TableCell>05-18-2023</TableCell>
											<TableCell className="remark-col">
												Phone Call : Call back request : He will available for
												meeting on Tuesday
											</TableCell>
											<TableCell>05-22-2023</TableCell>
											<TableCell>Bhargav Rakholiya</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>3</TableCell>
											<TableCell>05-18-2023</TableCell>
											<TableCell className="remark-col">
												Phone Call : Call back request : He will available for
												meeting on Tuesday
											</TableCell>
											<TableCell>05-22-2023</TableCell>
											<TableCell>Bhargav Rakholiya</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>4</TableCell>
											<TableCell>05-18-2023</TableCell>
											<TableCell className="remark-col">
												Phone Call : Call back request : He will available for
												meeting on Tuesday
											</TableCell>
											<TableCell>05-22-2023</TableCell>
											<TableCell>Bhargav Rakholiya</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</DialogContent>
						<DialogActions>
							<Button variant="contained" onClick={dialogRemarkClose}>
								Close
							</Button>
						</DialogActions>
					</Dialog>
				</div>
			</div>
		</>
	);
};
export default Dashboard;
