import { IconButton } from "@mui/material";
import Header from "../../../components/layouts/header";
import Sidebar from "../../../components/layouts/sidebar";
import { Add } from "@mui/icons-material";

const Price: React.FC = () => {
	return (
		<div className="main-content">
			<Header />
			<div className="page-content">
				<Sidebar />
				<div className="page-inner">
					<div className="page-heading-wrapper">
						<h4 className="page-heading">Price</h4>
						<IconButton
							aria-label="add"
							color="primary"
							className="primary-icon"
						>
							<Add />
						</IconButton>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Price;
