import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { useState } from "react";
import { AddUpdatePropertyDeal } from "./add-update-dialog";
import { DeleteCommonRecord } from "../common/common-master-record/delete-common-record";
import { APIRoutes } from "../../utility/app-routes";
import { Delete, Edit } from "@mui/icons-material";
import { getDealTypeText } from "../../utility/enums/common/deal-type";

type ComponentProps = {
	data: any;
	getAllRecords: any;
};
export function PropertyDealRow(props: ComponentProps) {
	const { data, getAllRecords } = props;
	const [isUpdateRecordOpen, setIsUpdateRecordOpen] = useState(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

	return (
		<>
			<TableRow key={data.id}>
				<TableCell>{data.dealDate}</TableCell>
				<TableCell>{getDealTypeText(data.dealTypeId)}</TableCell>
				<TableCell>{data.buyerName}</TableCell>
				<TableCell>{data.propertyName}</TableCell>
				<TableCell>{data.flatOfficeNo}</TableCell>
				<TableCell>{data.employeeName}</TableCell>
				<TableCell className="action-col" align="center">
					<div className="button-wrapper">
						<Tooltip title="Edit Property Deal">
							<IconButton
								onClick={() => setIsUpdateRecordOpen(true)}
								aria-label="Edit"
								color="primary"
							>
								<Edit />
							</IconButton>
						</Tooltip>
						<Tooltip title="Delete Property Deal">
							<IconButton
								aria-label="Delete"
								onClick={() => setIsDeleteDialogOpen(true)}
								color="primary"
							>
								<Delete />
							</IconButton>
						</Tooltip>
					</div>
				</TableCell>
			</TableRow>
			{/* Update record  */}
			<AddUpdatePropertyDeal
				dialogOpen={isUpdateRecordOpen}
				handleOnClickClose={() => setIsUpdateRecordOpen(false)}
				data={data}
				getAllRecords={getAllRecords}
			/>
			<DeleteCommonRecord
				getAllRecords={getAllRecords}
				apiPath={`${APIRoutes.deletePropertyDeal}/${data.id}`}
				dialogOpen={isDeleteDialogOpen}
				handleOnClickClose={() => setIsDeleteDialogOpen(false)}
			/>
		</>
	);
}
