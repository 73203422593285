import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	TextField,
	Tooltip,
} from "@mui/material";
import { Add, Refresh } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { IDropDownFields, ISearchCriteria } from "../../../utility/interfaces";
import { getAllSegment } from "../../../services";
import { HttpStatusCodes } from "../../../utility/enums";
import { toast } from "react-toastify";
import { getComparator, stableSort } from "../../../utility/helpers/common";
import { CustomPagination, NoRecordFound } from "../../../components";
import { AddUpdateSegment } from "../../../components/common/common-master-record/add-update-segment";
import { SegmentRecordRow } from "../../../components/common/common-master-record/segment-row";
import { tableRowPerPage } from "../../../utility/constant";

const Segment: React.FC = () => {
	const [isAddNewRecordOpen, setIsAddNewRecordOpen] = useState(false);
	const [searchCriteria, setSearchCriteria] = useState<ISearchCriteria>({
		rowPerPage: tableRowPerPage,
		pageNumber: 1,
		sortBy: "name",
		sortDirection: "asc",
		text: "",
	});
	const [bindPageData, setBindPageData] = useState<any>([]);
	const [tableData, setTableData] = useState<any>([]);

	const handleOnChangeSearchCriteria = (value: any, name: string) => {
		setSearchCriteria({ ...searchCriteria, [name]: value });
	};
	const handleOnChangeSorting = (sortBy: string) => {
		setSearchCriteria({
			...searchCriteria,
			sortDirection: searchCriteria.sortDirection === "asc" ? "desc" : "asc",
			sortBy,
		});
	};
	const getAllRecords = async () => {
		const { data } = await getAllSegment();
		if (data && data.status === HttpStatusCodes.Ok) {
			setBindPageData(data.response);
		} else if (data && data.status === HttpStatusCodes.NotFound) {
			setBindPageData([]);
		} else {
			setBindPageData([]);
			toast.error(data.message);
		}
	};
	const handleOnChangeSearchText = (value: any) => {
		setSearchCriteria({
			...searchCriteria,
			text: value,
			pageNumber: 1,
			sortBy: "name",
			sortDirection: "asc",
		});
	};
	// useEffects
	useEffect(() => {
		getAllRecords();
	}, []);

	useEffect(() => {
		let filteredData: any = [];
		stableSort(
			searchCriteria.text.length > 0
				? bindPageData.filter((data: any) =>
						data.name
							?.toLowerCase()
							.includes(searchCriteria.text?.toLowerCase())
				  )
				: bindPageData,
			getComparator(searchCriteria.sortDirection, searchCriteria.sortBy)
		).map((data: any) => {
			filteredData.push({
				id: +data.id,
				isActive: data.isActive ? true : false,
				name: data.name,
				propertyType: data.propertyTypeName,
				propertyTypeId: +data.propertyTypeId,
			});
		});
		filteredData = filteredData.slice(
			(searchCriteria.pageNumber - 1) * searchCriteria.rowPerPage,
			(searchCriteria.pageNumber - 1) * searchCriteria.rowPerPage +
				searchCriteria.rowPerPage
		);
		setTableData(filteredData);
	}, [bindPageData, searchCriteria]);

	return (
		<>
			<div className="page-inner">
				<div className="page-heading-wrapper">
					<h4 className="page-heading">Segment</h4>
					<Tooltip title="Refresh">
						<IconButton
							aria-label="refresh"
							color="primary"
							className="primary-icon"
							onClick={getAllRecords}
						>
							<Refresh />
						</IconButton>
					</Tooltip>
					<Tooltip title="Add Segment">
						<IconButton
							aria-label="add"
							color="primary"
							className="primary-icon"
							onClick={() => setIsAddNewRecordOpen(true)}
						>
							<Add />
						</IconButton>
					</Tooltip>
				</div>
				<div className="white-paper">
					<div className="table-heading-block">
						<TextField
							autoFocus
							value={searchCriteria.text}
							onChange={(e) => handleOnChangeSearchText(e.target.value)}
							label="Search"
							variant="outlined"
						/>
					</div>
					<div className="table-negative-space">
						<TableContainer>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell sortDirection={searchCriteria.sortDirection}>
											<TableSortLabel
												active={searchCriteria.sortBy === "name"}
												direction={searchCriteria.sortDirection}
												onClick={() => handleOnChangeSorting("name")}
											>
												Name
											</TableSortLabel>
										</TableCell>
										<TableCell sortDirection={searchCriteria.sortDirection}>
											<TableSortLabel
												active={searchCriteria.sortBy === "propertyType"}
												direction={searchCriteria.sortDirection}
												onClick={() => handleOnChangeSorting("propertyType")}
											>
												Property Type
											</TableSortLabel>
										</TableCell>
										<TableCell>
											<TableSortLabel
												active={searchCriteria.sortBy === "isActive"}
												direction={searchCriteria.sortDirection}
												onClick={() => handleOnChangeSorting("isActive")}
											>
												Status
											</TableSortLabel>
										</TableCell>
										<TableCell className="action-col" align="center">
											Action
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{tableData.map((data: any) => {
										return (
											<SegmentRecordRow
												data={data}
												getAllRecords={getAllRecords}
												key={data.id}
											/>
										);
									})}
								</TableBody>
								{tableData?.length <= 0 && <NoRecordFound />}
							</Table>
						</TableContainer>
					</div>
					<CustomPagination
						handleOnChangeSearchCriteria={handleOnChangeSearchCriteria}
						pageNumber={searchCriteria.pageNumber}
						rowPerPage={searchCriteria.rowPerPage}
						totalRecords={bindPageData?.length}
					/>
				</div>
			</div>
			<AddUpdateSegment
				dialogOpen={isAddNewRecordOpen}
				handleOnClickClose={() => setIsAddNewRecordOpen(false)}
				data={undefined}
				getAllRecords={getAllRecords}
			/>
		</>
	);
};
export default Segment;
