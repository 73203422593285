import Header from "../../../components/layouts/header";
import Sidebar from "../../../components/layouts/sidebar";
import { APIRoutes } from "../../../utility/app-routes";
import { MasterCommonRecordPage } from "../../../components/common/common-master-record/master-common-record-page";

const Measurements: React.FC = () => {
	// Table Pagination

	return (
		<div className="page-inner">
			<>
				<MasterCommonRecordPage
					addUpdateApiUrl={APIRoutes.appUpdateMeasurement}
					deleteApiUrl={APIRoutes.deleteMeasurement}
					title="Measurements"
					getAllRecordApiUrl={APIRoutes.getAllMeasurements}
				/>
			</>
		</div>
	);
};
export default Measurements;
