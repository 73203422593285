import { createTheme } from "@mui/material/styles";

let customTheme = createTheme({
	palette: {
		primary: {
			main: "#191b4e",
		},
		secondary: {
			main: "#4B4B4B",
		},
		error: {
			main: "#E13636",
		},
	},
	typography: {
		fontFamily: "'Roboto', sans-serif",
	},
});

customTheme = createTheme(customTheme, {
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					fontSize: "16px",
					lineHeight: "20px",
					textTransform: "capitalize",
					transition: "all 0.3s",
					fontWeight: 400,
				},
				contained: { boxShadow: "none !important", padding: "13px 16px" },
				containedPrimary: {
					color: "#ffffff",
					borderRadius: "4px",
					minWidth: "84px",
				},
				outlinedPrimary: {
					padding: "12px 16px",
					borderColor: "#191b4e",
					"&:hover": { backgroundColor: "#ebebf9" },
					minWidth: "84px",
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					"&.primary-icon": {
						borderRadius: "4px",
						border: "1px solid #191b4e",
						padding: "7px",
						display: "flex",
						alignItems: "center",
					},
				},
			},
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					height: "auto",
					width: "auto",
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					"&.primary-link": {
						color: "#034F8B",
						textDecoration: "none",
					},
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					color: "#bababa",
					fontSize: "16px",
					zIndex: 1,
					lineHeight: "20px",
					"&.Mui-disabled": {
						color: "#bababa",
						opacity: "0.5",
					},
					"&.Mui-error": {
						color: "#FF3232",
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DEDEDE" },
					"&:hover": {
						"& .MuiOutlinedInput-notchedOutline": { borderColor: "#034F8B" },
					},
					"&.Mui-disabled": {
						"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DEDEDE" },
						"&:hover": {
							"& .MuiOutlinedInput-notchedOutline": { borderColor: "#DEDEDE" },
						},
					},
					padding: 0,
					".MuiInputAdornment-positionEnd": {
						marginLeft: 0,
						".MuiButtonBase-root": { marginRight: 0 },
					},
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					color: "#4B4B4B",
					display: "flex",
				},
				input: {
					"&.MuiOutlinedInput-input": {
						padding: "11px 14px",
						height: 46,
						boxSizing: "border-box",
					},
					fontSize: "16px",
					lineHeight: "20px",
					"&:focus": {
						backgroundColor: "#fff",
					},
				},
			},
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					"&.radio-control .MuiFormLabel-root": { color: "#767676" },
					display: "flex",
					"& .MuiFormLabel-root.MuiInputLabel-root": {
						top: "-2px",
						"&.MuiFormLabel-filled, &.Mui-focused": {
							transform: "translate(14px, -4px) scale(0.75)",
						},
					},
				},
			},
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					".MuiSvgIcon-root": {
						color: "#767676",
					},
					"&.Mui-checked": {
						".MuiSvgIcon-root": {
							color: "#191b4e",
						},
					},
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					".MuiSvgIcon-root": {
						color: "#767676",
					},
					"&.Mui-checked": {
						".MuiSvgIcon-root": {
							color: "#191b4e",
						},
					},
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					"&.MuiMenu-paper": {
						boxShadow: "0px 0px 7px 2px rgba(0, 0, 0, 0.2)",
						minWidth: "150px",
					},
					".MuiList-root": {
						".MuiMenuItem-root": {
							lineHeight: "22px",
							color: "#767676",
							minHeight: "auto",
							padding: "8px 16px",
							".list-icon": {
								marginRight: "10px",
							},
							".MuiLink-root": {
								display: "flex",
								alignItems: "center",
								textDecoration: "none",
								color: "#767676",
							},
						},
					},
					"&.MuiDialog-paper": {
						boxShadow: "0px 0px 7px 2px rgba(0, 0, 0, 0.2)",
						margin: "16px",
						width: "calc(100% - 32px)",
					},
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					".MuiFormControlLabel-label": { color: "#767676" },
				},
			},
		},
		MuiTable: {
			styleOverrides: {
				root: {
					".MuiTableCell-root.action-col": {
						width: "100px",
					},
					".MuiTableCell-root": {
						fontSize: "16px",
						lineHeight: "22px",
						whiteSpace: "nowrap",
						".MuiCheckbox-root, .MuiIconButton-root": { padding: "6px" },
						".MuiSvgIcon-root": { height: "22px", width: "22px" },
						".MuiFormControlLabel-root": { marginLeft: "-8px" },
					},
					".MuiTableHead-root": {
						backgroundColor: "#191b4e",
						".MuiTableCell-head": {
							fontWeight: "500",
							color: "#ffffff",
							padding: "6px 12px",
							border: "none",
							"&:first-of-type": { paddingLeft: "16px" },
							"&:last-child:not(.action-col)": { paddingRight: "16px" },
						},
						".sort-icon": {
							padding: "6px",
							".MuiSvgIcon-root": {
								color: "#ffffff",
								height: "20px",
								width: "20px",
							},
						},
					},
					".MuiTableBody-root": {
						".MuiTableRow-root": {
							"&:nth-of-type(even)": {
								backgroundColor: "#f0f0fa",
							},
							"&.green": {
								".MuiTableCell-body": { color: "green" },
							},
							"&.red": {
								".MuiTableCell-body": { color: "red" },
							},
						},
						".MuiTableCell-body": {
							color: "#767676",
							padding: "6px 12px",
							border: "none",
							"&.no-data": {
								padding: "12px 16px",
							},
							"&:first-of-type": { paddingLeft: "16px" },
							"&:last-child:not(.action-col)": { paddingRight: "16px" },
						},
					},
				},
			},
		},
		MuiTablePagination: {
			styleOverrides: {
				root: {
					color: "#767676",
					".MuiToolbar-root": { minHeight: "auto" },
					".MuiInputBase-root": {
						marginRight: "24px",
						".MuiSelect-select": { minHeight: "auto", paddingTop: "6px" },
					},
					".MuiTablePagination-actions": {
						".MuiIconButton-root": {
							padding: "4px",
						},
					},
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				root: {
					".MuiBackdrop-root": {
						backgroundColor: "rgba(0, 0, 0, 0.3)",
					},
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					padding: "16px",
				},
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					"&>:not(:first-of-type)": {
						marginLeft: "16px",
					},
					padding: "16px",
					".MuiButton-contained": {
						[customTheme.breakpoints.down("sm")]: {
							padding: "9px 16px",
						},
					},
					".MuiButton-outlined": {
						[customTheme.breakpoints.down("sm")]: {
							padding: "8px 16px",
						},
					},
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					backgroundColor: "#191b4e",
					color: "#ffffff",
					padding: "8px 16px",
					gap: "16px",
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					borderTopLeftRadius: "inherit",
					borderTopRightRadius: "inherit",
					fontSize: "18px",
					lineHeight: "24px",
					fontWeight: "400",
					"&.MuiTypography-root+.MuiDialogContent-root": { paddingTop: "16px" },
					".MuiIconButton-root": {
						padding: "6px",
						marginRight: "-6px",
						".MuiSvgIcon-root": {
							color: "#ffffff",
						},
					},
				},
			},
		},
		MuiDialogContentText: {
			styleOverrides: {
				root: {
					color: "#767676",
				},
			},
		},
	},
});
export default customTheme;
