import { Dayjs } from "dayjs";

export interface IHttpsResponse<T> {
	message: string;
	response: T;
	status: number;
	totalCount: number;
}

export class JWTDecodedToken {
	Id: string;
	FirstName: string;
	LastName: string;
	Email: string;
	PhoneNumber: string;
	ProfileImage: string;
	RoleId: string;
	Exp: number;
	TokenGenDate: Date;
	UserId: number;

	constructor(data?: any) {
		this.Id = data ? data.Id : null;
		this.FirstName = data ? data.FirstName : null;
		this.LastName = data ? data.LastName : null;
		this.Email = data ? data.Email : null;
		this.PhoneNumber = data ? data.PhoneNumber : null;
		this.ProfileImage = data ? data.ProfileImage : null;
		this.RoleId = data ? data.RoleId : null;
		this.Exp = data ? data.exp : null;
		this.TokenGenDate = data ? data.TokenGenDate : null;
		this.UserId = data ? data.UserId : 0;
	}
}
export interface ISearchCriteria {
	text: string;
	pageNumber: number;
	rowPerPage: number;
	sortDirection: "asc" | "desc";
	sortBy: string;
}
export interface IDropDownFields {
	id: number;
	text: string;
	isActive: boolean;
}

export interface ITeamActivitySearchCriteria extends ISearchCriteria {
	userId: number;
	pageName: string;
	fromDate: Dayjs | null;
	toDate: Dayjs | null;
}
export interface IDropDownFieldsWithBhkofficeId extends IDropDownFields {
	bhkOfficeId: number;
	for: number;
}
export interface IPropertySearchCriteria extends ISearchCriteria {
	propertyFor: number;
	bhkOfficeId: number;
	buildingId: number;
	propertyStatusId: number;
	furnitureStatusId: number;
	areaId: number;
	propertyMinPrice: number;
	propertyMaxPrice: number;
	availableFromDate: Dayjs | null;
	availableToDate: Dayjs | null;
	propertyName: string;
}

export interface IEnquirySearchCriteria extends ISearchCriteria {
	enquiryFor: number;
	bhkOfficeId: number;
	budgetId: number;
	sourceId: number;
	fromNfd: Dayjs | null;
	toNfd: Dayjs | null;
	enquiryStatusId: number;
	nonUseId: number;
	employeeId: number;
}

export interface IDashboardSearchCriteria extends ISearchCriteria {
	nfdType: number;
}
