import {
	Checkbox,
	FormControlLabel,
	IconButton,
	TableCell,
	TableRow,
	Tooltip,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { AddUpdateCommonRecord } from "./add-update-common-dialog";
import { useState } from "react";
import { DeleteCommonRecord } from "./delete-common-record";
type ComponentProps = {
	data: any;
	getAllRecords: any;
	addUpdateApiUrl: string;
	deleteApiUrl: string;
	title: string;
};
export function MasterCommonRecordRow(props: ComponentProps) {
	const { data, getAllRecords, title, addUpdateApiUrl, deleteApiUrl } = props;
	const [isUpdateRecordOpen, setIsUpdateRecordOpen] = useState(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

	return (
		<>
			<TableRow key={data.id}>
				<TableCell>{data.name}</TableCell>
				<TableCell>
					<FormControlLabel
						control={<Checkbox checked={data.isActive ? true : false} />}
						label={data.isActive ? "Active" : "Deactivate"}
					/>
				</TableCell>
				<TableCell className="action-col" align="center">
					<div className="button-wrapper">
						<Tooltip title="Edit">
							<IconButton
								onClick={() => setIsUpdateRecordOpen(true)}
								aria-label="Edit"
								color="primary"
							>
								<Edit />
							</IconButton>
						</Tooltip>
						<Tooltip title="Delete">
							<IconButton
								aria-label="Delete"
								onClick={() => setIsDeleteDialogOpen(true)}
								color="primary"
							>
								<Delete />
							</IconButton>
						</Tooltip>
					</div>
				</TableCell>
			</TableRow>
			{/* Update record  */}
			<AddUpdateCommonRecord
				dialogOpen={isUpdateRecordOpen}
				handleOnClickClose={() => setIsUpdateRecordOpen(false)}
				dialogTitle={title}
				apiPath={addUpdateApiUrl}
				data={data}
				getAllRecords={getAllRecords}
			/>
			<DeleteCommonRecord
				getAllRecords={getAllRecords}
				apiPath={`${deleteApiUrl}/${data.id}`}
				dialogOpen={isDeleteDialogOpen}
				handleOnClickClose={() => setIsDeleteDialogOpen(false)}
			/>
		</>
	);
}
