import { APIRoutes } from "../../../utility/app-routes";
import { MasterCommonRecordPage } from "../../../components/common/common-master-record/master-common-record-page";

const Area: React.FC = () => {
	// Table Pagination

	return (
		<div className="page-inner">
			<>
				<MasterCommonRecordPage
					addUpdateApiUrl={APIRoutes.appUpdateArea}
					deleteApiUrl={APIRoutes.deleteArea}
					title="Area"
					getAllRecordApiUrl={APIRoutes.getAllArea}
				/>
			</>
		</div>
	);
};
export default Area;
