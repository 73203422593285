import React, { useEffect } from "react";
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormHelperText,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import {
	addNewBhkOffice,
	getAllCommonRecords,
	updateBhkOffice,
} from "../../../services";
import { HttpStatusCodes } from "../../../utility/enums";
import { IDropDownFields } from "../../../utility/interfaces";
import { number, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { APIRoutes } from "../../../utility/app-routes";

type ComponentProps = {
	dialogOpen: boolean;
	handleOnClickClose: any;
	getAllRecords: any;
	data: any | undefined;
};
export function AddUpdateBhkOffice(props: ComponentProps) {
	const formValidationSchema = object({
		name: string().required("Name is required"),
		segment: number().min(1, "Please select segment"),
	});
	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		resolver: yupResolver(formValidationSchema),
	});

	const { dialogOpen, handleOnClickClose, data, getAllRecords } = props;
	const [name, setName] = React.useState("");
	const [isActive, setIsActive] = React.useState(true);
	const [segmentId, setSegmentId] = React.useState(0);
	const [id, setId] = React.useState(0);
	const [segmentList, setSegmentList] = React.useState<IDropDownFields[]>([]);

	const handleOnClickSubmit = async () => {
		let response = null;
		if (id === null || id === 0)
			response = await addNewBhkOffice(name, isActive, segmentId);
		else response = await updateBhkOffice(name, isActive, segmentId, id);
		if (response?.data?.status === HttpStatusCodes.Ok) {
			await getAllRecords();
			toast.success(response.data.message);
		} else toast.error(response.data.message);
		handleOnClickClose();
	};

	const getPageLoadData = async () => {
		const { data } = await getAllCommonRecords(APIRoutes.getBhkOfficePageLoad);
		if (data && data.status === HttpStatusCodes.Ok) {
			setSegmentList(data.response?.segmentList);
		} else {
			setSegmentList([]);
			toast.error(data.message);
		}
	};

	// useEffects
	useEffect(() => {
		reset();
		setName(data?.name);
		setIsActive(data ? data.isActive : true);
		setId(data?.id || 0);
		setSegmentId(data?.segment || 0);

		if (dialogOpen) getPageLoadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dialogOpen]);

	return (
		<Dialog
			open={dialogOpen}
			onClose={handleOnClickClose}
			maxWidth="xs"
			fullWidth
		>
			<DialogTitle>
				BHK/Office
				<IconButton aria-label="Close" onClick={handleOnClickClose}>
					<CloseOutlined />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<TextField
					{...register("name")}
					error={errors?.name?.message ? true : false}
					helperText={errors?.name?.message?.toString()}
					id="name"
					label="Name"
					type="text"
					value={name}
					fullWidth
					autoFocus
					variant="outlined"
					onChange={(e) => setName(e.target.value)}
					className="mb-20"
				/>
				<FormControl fullWidth className="mb-8">
					<InputLabel id="select-label">Segment</InputLabel>
					<Select
						labelId="select-label"
						{...register("segment")}
						error={errors?.segment?.message ? true : false}
						id="segment"
						value={segmentId}
						label="Segment"
						onChange={(e) => setSegmentId(+e.target.value)}
					>
						<MenuItem value="0">Select</MenuItem>
						{segmentList.map((record) => {
							return (
								<MenuItem disabled={!record.isActive} value={record.id}>
									{record.text}
								</MenuItem>
							);
						})}
					</Select>
					<FormHelperText error id="component-error-text">
						<>{errors?.segment?.message}</>
					</FormHelperText>
				</FormControl>
				<FormControlLabel
					className="mb-ng-8"
					control={
						<Checkbox
							checked={isActive}
							onClick={() => setIsActive(!isActive)}
						/>
					}
					label="Active"
				/>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={handleSubmit(handleOnClickSubmit)}>
					Submit
				</Button>
				<Button variant="outlined" onClick={handleOnClickClose}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}
