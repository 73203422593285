import { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { number, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { IDropDownFields } from "../../utility/interfaces";
import { PaymentOptionRow } from "./payment-option-row";
import { DealType } from "../../utility/enums/common/deal-type";
import { HttpStatusCodes } from "../../utility/enums";
import { toast } from "react-toastify";
import { addUpdatePropertyDeal, getAllCommonRecords } from "../../services";
import dayjs, { Dayjs } from "dayjs";
import { CustomDatePicker } from "../common/custom-date-picker";
import { phoneRegExp } from "../../utility/constant";
import { isInputOnlyNumber } from "../../utility/helpers/common";
import { APIRoutes } from "../../utility/app-routes";

type ComponentProps = {
	dialogOpen: boolean;
	handleOnClickClose: any;
	getAllRecords: any;
	data: any | undefined;
};
export function AddUpdatePropertyDeal(props: ComponentProps) {
	const { dialogOpen, handleOnClickClose, data, getAllRecords } = props;

	interface IPaymentRow {
		id: number;
		paymentOption: number;
		amount: string;
		date: Dayjs | null;
		remark: string;
	}
	const [propertySourceId, setPropertySourceId] = useState(0);
	const [buyerSourceId, setBuyerSourceId] = useState(0);
	const [dealType, setDealType] = useState(DealType.Buy);
	const [employeeId, setEmployeeId] = useState(0);
	const [bhkId, setBhkId] = useState(0);
	const [dealDate, setDealDate] = useState<Dayjs | null>(dayjs(new Date()));
	const [possessionDate, setPossessionDate] = useState<Dayjs | null>(
		dayjs(new Date())
	);
	const [propertyName, setPropertyName] = useState("");
	const [sqft, setSqft] = useState("");
	const [propertyOwnerName, setPropertyOwnerName] = useState("");
	const [propertyBuyerName, setPropertyBuyerName] = useState("");
	const [dealAmount, setDealAmount] = useState("");
	const [brokeragefromOwner, setBrokeragefromOwner] = useState("");
	const [brokeragefromClient, setBrokeragefromClient] = useState("");
	const [brokerageRemark, setBrokerageRemark] = useState("");
	const [paymentRowData, setPaymentRowData] = useState<IPaymentRow[]>([]);
	const [employeeList, setEmployeeList] = useState<IDropDownFields[]>([]);
	const [bhkOfficeList, setBhkOfficeList] = useState<IDropDownFields[]>([]);
	const [propertySourceList, setPropertySourceList] = useState<
		IDropDownFields[]
	>([]);
	const getPageLoadRecords = async () => {
		const { data } = await getAllCommonRecords(
			APIRoutes.propertyDealOnPageLoad
		);
		if (data && data.status === HttpStatusCodes.Ok) {
			setBhkOfficeList(data.response.bhkOfficeList);
			setPropertySourceList(data.response.sourceList);
			setEmployeeList(data.response.userList);
		} else {
			toast.error(data.message);
		}
	};

	// useEffects
	useEffect(() => {
		if (dialogOpen) getPageLoadRecords();
	}, [dialogOpen]);

	const onUpdatePaymentRowData = (rowIndex: any, data: any) => {
		if (rowIndex === -1) {
			setPaymentRowData([
				...paymentRowData,
				{
					id: 0,
					paymentOption: 1,
					amount: "0",
					date: dayjs(new Date()),
					remark: "",
				},
			]);
		} else {
			setPaymentRowData(
				paymentRowData?.map((row, index) => {
					if (index === rowIndex) {
						row = data;
					}
					return row;
				})
			);
			// paymentRowData[index] = data;
		}
	};
	const onRemovePaymentRowData = (rowIndex: any) => {
		setPaymentRowData(
			paymentRowData.filter((item, index) => index !== rowIndex)
		);
	};
	const [propertyBuyerContact, setPropertyBuyerContact] = useState("");
	const [propertyOwnerContact, setPropertyOwnerContact] = useState("");
	const [flatOfficeNo, setFlatOfficeNo] = useState("");
	const [id, setId] = useState(0);
	const [isPaymentRowValidate, setIsPaymentRowValidate] = useState(true);

	const paymentRowValidation = () => {
		let isValidate = true;
		if (paymentRowData.length > 0) {
			paymentRowData.forEach((element) => {
				if (
					element.amount === "" ||
					element.amount === "0" ||
					element.remark === ""
				)
					isValidate = false;
			});
		}
		setIsPaymentRowValidate(isValidate);
		return isValidate;
	};

	const handleOnClickSubmit = async () => {
		if (!paymentRowValidation()) {
			return null;
		}
		const response = await addUpdatePropertyDeal({
			id,
			dealTypeId: dealType,
			UserId: employeeId,
			dealDate,
			possessionDate,
			propertyName,
			flatOfficeNo,
			ownerName: propertyOwnerName,
			propertySourceId,
			buyerName: propertyBuyerName,
			buyerContactNo: propertyBuyerContact,
			ownerContactNo: propertyOwnerContact,
			buyerSourceId,
			bhkOfficeId: bhkId,
			squareFeet: sqft,
			dealAmount,
			ownerBrokerage: brokeragefromOwner,
			clientBrokerage: brokeragefromClient,
			remark: brokerageRemark,
			payments: paymentRowData,
		});
		if (response?.data?.status === HttpStatusCodes.Ok) {
			await getAllRecords();
			toast.success(response.data.message);
		} else toast.error(response.data.message);
		handleOnClickClose();
	};
	// useEffects
	useEffect(() => {
		if (dialogOpen) {
			setId(data?.id || 0);
			setDealType(data?.dealTypeId || DealType.Buy);
			setEmployeeId(data?.userId || 0);
			setDealDate(data?.dealDate ? data?.dealDate : dayjs(new Date()));
			setPossessionDate(
				data?.possessionDate ? data?.possessionDate : dayjs(new Date())
			);
			setPropertyName(data?.propertyName || "");
			setFlatOfficeNo(data?.flatOfficeNo || "");
			setPropertyOwnerName(data?.ownerName || "");
			setPropertySourceId(data?.propertySourceId || 0);
			setPropertyBuyerName(data?.buyerName || "");
			setPropertyBuyerContact(data?.buyerContactNo || "");
			setBuyerSourceId(data?.buyerSourceId || 0);
			setBhkId(data?.bhkOfficeId || 0);
			setSqft(data?.squareFeet || "");
			setDealAmount(data?.dealAmount || "");
			setBrokeragefromClient(data?.clientBrokerage || "");
			setBrokeragefromOwner(data?.ownerBrokerage || "");
			setBrokerageRemark(data?.remark || "");
			setPropertyOwnerContact(data?.ownerContactNo || "");
			setPaymentRowData(data?.payments || []);
			setIsPaymentRowValidate(true);
		}
		reset();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dialogOpen]);

	// Yup resolver
	const formValidationSchema = object({
		propertyType: number().min(1, "Please select property type."),
		employeeName: number().min(1, "Please select employee."),
		bhkOffice: number().min(1, "Please select bhkOffice."),
		propertySource: number().min(1, "Please select property source."),
		buyerSource: number().min(1, "Please select buyer source."),
		propertyName: string().required("Property name is required."),
		brokerageFromClient: string()
			.required("Brokerage from client is required.")
			.min(0, "Enter valid brokerage amount."),
		flatOfficeNo: string().required(
			"Flat/Office Number from client is required."
		),
		propertyOwnerName: string().required(
			"Property owner name from client is required."
		),
		propertyOwnerContactNo: string()
			.matches(phoneRegExp, "Contact number is not valid.")
			.min(10, "Contact number is not valid.")
			.max(10, "Contact number is not valid."),
		buyerName: string().required("Buyer name is required."),
		buyerContactNo: string()
			.matches(phoneRegExp, "Contact number is not valid.")
			.min(10, "Contact number is not valid.")
			.max(10, "Contact number is not valid."),
		sqft: string().required("Sqft is required.").min(1, "Enter valid sqft."),
		dealAmount: string()
			.required("Deal amount is required.")
			.min(1, "Enter valid deal amount."),
		brokerageFromOwner: string()
			.required("Brokerage from owner is required.")
			.min(0, "Enter valid brokerage amount."),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm({
		resolver: yupResolver(formValidationSchema),
	});

	useEffect(() => {
		setValue("employeeName", employeeId);
		setValue("bhkOffice", bhkId);
		setValue("propertySource", propertySourceId);
		setValue("buyerSource", buyerSourceId);
		setValue("propertyName", propertyName);
		setValue("brokerageFromClient", brokeragefromClient);
		setValue("flatOfficeNo", flatOfficeNo);
		setValue("propertyOwnerName", propertyOwnerName);
		setValue("propertyOwnerContactNo", propertyOwnerContact);
		setValue("buyerName", propertyBuyerName);
		setValue("buyerContactNo", propertyBuyerContact);
		setValue("sqft", sqft);
		setValue("dealAmount", dealAmount);
		setValue("brokerageFromOwner", brokeragefromClient);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [employeeId]);

	return (
		<Dialog
			open={dialogOpen}
			onClose={handleOnClickClose}
			maxWidth="lg"
			fullWidth
		>
			<DialogTitle>
				Add New Property Deal
				<IconButton aria-label="Close" onClick={handleOnClickClose}>
					<CloseOutlined />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2} className="mb-20">
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<FormControl fullWidth>
							<InputLabel id="select-label">Deal Type</InputLabel>
							<Select
								labelId="select-label"
								id="dealType"
								value={dealType}
								label="Deal Type"
								onChange={(e) => setDealType(+e.target.value)}
							>
								<MenuItem value={DealType.Rent}>Rent</MenuItem>
								<MenuItem value={DealType.Sell}>Sell</MenuItem>
								<MenuItem value={DealType.Buy}>Buy</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<FormControl fullWidth>
							<InputLabel id="employee-label">Employee Name</InputLabel>
							<Select
								labelId="select-label"
								{...register("employeeName")}
								error={errors?.employeeName?.message ? true : false}
								id="employeeName"
								value={employeeId}
								label="Employee Name"
								onChange={(e) => setEmployeeId(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{employeeList.map((record) => {
									return (
										<MenuItem
											key={record.id}
											disabled={!record.isActive}
											value={record.id}
										>
											{record.text}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.employeeName?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={4}
						lg={3}
						className="datepicker-wrappper"
					>
						<CustomDatePicker
							disabled={false}
							label="Deal date"
							onChangeValue={(newValue: any) => setDealDate(newValue)}
							value={dealDate}
							maxDate={new Date().setFullYear(new Date().getFullYear() + 200)}
							minDate={new Date().setFullYear(new Date().getFullYear() - 200)}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={4}
						lg={3}
						className="datepicker-wrappper"
					>
						<CustomDatePicker
							disabled={false}
							label="Possession date"
							onChangeValue={(newValue: any) => setPossessionDate(newValue)}
							value={possessionDate}
							maxDate={new Date().setFullYear(new Date().getFullYear() + 200)}
							minDate={new Date().setFullYear(new Date().getFullYear() - 200)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							{...register("propertyName")}
							id="propertyName"
							error={errors?.propertyName?.message ? true : false}
							helperText={errors?.propertyName?.message?.toString()}
							label="Property Name"
							value={propertyName}
							onChange={(e) => setPropertyName(e.target.value)}
							type="text"
							fullWidth
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							{...register("flatOfficeNo")}
							id="flatOfficeNo"
							error={errors?.flatOfficeNo?.message ? true : false}
							helperText={errors?.flatOfficeNo?.message?.toString()}
							label="Flat/Office No"
							type="text"
							fullWidth
							variant="outlined"
							value={flatOfficeNo}
							onChange={(e) => setFlatOfficeNo(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							{...register("propertyOwnerName")}
							id="propertyOwnerName"
							error={errors?.propertyOwnerName?.message ? true : false}
							helperText={errors?.propertyOwnerName?.message?.toString()}
							label="Property Owner Name"
							type="text"
							fullWidth
							variant="outlined"
							value={propertyOwnerName}
							onChange={(e) => setPropertyOwnerName(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							{...register("propertyOwnerContactNo")}
							id="propertyOwnerContactNo"
							error={errors?.propertyOwnerContactNo?.message ? true : false}
							helperText={errors?.propertyOwnerContactNo?.message?.toString()}
							label="Property Owner Contact No"
							type="text"
							fullWidth
							variant="outlined"
							value={propertyOwnerContact}
							onChange={(e) => {
								if (isInputOnlyNumber(e.target.value)) {
									setPropertyOwnerContact(e.target.value);
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<FormControl fullWidth className="mb-8">
							<InputLabel id="property-source-label">
								Property Source
							</InputLabel>
							<Select
								labelId="property-source-label"
								{...register("propertySource")}
								error={errors?.propertySource?.message ? true : false}
								id="propertySource"
								value={propertySourceId}
								label="Property Source"
								onChange={(e) => setPropertySourceId(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{propertySourceList.map((record) => {
									return (
										<MenuItem
											key={record.id}
											disabled={!record.isActive}
											value={record.id}
										>
											{record.text}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.propertySource?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							{...register("buyerName")}
							id="buyerName"
							error={errors?.buyerName?.message ? true : false}
							helperText={errors?.buyerName?.message?.toString()}
							label="Buyer Name"
							type="text"
							fullWidth
							variant="outlined"
							value={propertyBuyerName}
							onChange={(e) => setPropertyBuyerName(e.target.value)}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							{...register("buyerContactNo")}
							id="buyerName"
							error={errors?.buyerContactNo?.message ? true : false}
							helperText={errors?.buyerContactNo?.message?.toString()}
							label="Buyer Contact No"
							type="text"
							fullWidth
							variant="outlined"
							value={propertyBuyerContact}
							onChange={(e) => {
								if (isInputOnlyNumber(e.target.value)) {
									setPropertyBuyerContact(e.target.value);
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<FormControl fullWidth className="mb-8">
							<InputLabel id="buyer-source-label">Buyer Source</InputLabel>
							<Select
								{...register("buyerSource")}
								error={errors?.buyerSource?.message ? true : false}
								id="buyerSource"
								labelId="property-source-label"
								value={buyerSourceId}
								label="Buyer Source"
								onChange={(e) => setBuyerSourceId(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{propertySourceList.map((record) => {
									return (
										<MenuItem
											key={record.id}
											disabled={!record.isActive}
											value={record.id}
										>
											{record.text}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.buyerSource?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<FormControl fullWidth className="mb-8">
							<InputLabel id="property-source-label">BHK/Office</InputLabel>
							<Select
								{...register("bhkOffice")}
								error={errors?.bhkOffice?.message ? true : false}
								id="bhkOffice"
								labelId="property-source-label"
								value={bhkId}
								label="BHK/Office"
								onChange={(e) => setBhkId(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{bhkOfficeList.map((record) => {
									return (
										<MenuItem
											key={record.id}
											disabled={!record.isActive}
											value={record.id}
										>
											{record.text}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.bhkOffice?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							{...register("sqft")}
							id="sqft"
							error={errors?.sqft?.message ? true : false}
							helperText={errors?.sqft?.message?.toString()}
							value={sqft}
							onChange={(e) => {
								if (isInputOnlyNumber(e.target.value)) {
									setSqft(e.target.value);
								}
							}}
							label="SQFT"
							type="text"
							fullWidth
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							{...register("dealAmount")}
							id="dealAmount"
							error={errors?.dealAmount?.message ? true : false}
							helperText={errors?.dealAmount?.message?.toString()}
							label="Deal Amount"
							type="text"
							fullWidth
							variant="outlined"
							value={dealAmount}
							onChange={(e) => {
								if (isInputOnlyNumber(e.target.value)) {
									setDealAmount(e.target.value);
								}
							}}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							{...register("brokerageFromOwner")}
							id="brokerageFromOwner"
							error={errors?.brokerageFromOwner?.message ? true : false}
							helperText={errors?.brokerageFromOwner?.message?.toString()}
							label="Brokerage from Owner/builder"
							type="text"
							fullWidth
							variant="outlined"
							value={brokeragefromOwner}
							onChange={(e) => {
								setBrokeragefromOwner(e.target.value);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							{...register("brokerageFromClient")}
							id="brokerageFromClient"
							error={errors?.brokerageFromClient?.message ? true : false}
							helperText={errors?.brokerageFromClient?.message?.toString()}
							label="Brokerage from client"
							type="text"
							fullWidth
							variant="outlined"
							value={brokeragefromClient}
							onChange={(e) => {
								setBrokeragefromClient(e.target.value);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={9}>
						<TextField
							label="Remarks"
							type="text"
							fullWidth
							variant="outlined"
							value={brokerageRemark}
							onChange={(e) => setBrokerageRemark(e.target.value)}
						/>
					</Grid>
				</Grid>
				<Typography className="section-heading" variant="h6">
					Payment Option
				</Typography>

				<TableContainer className="payment-option">
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Option</TableCell>
								<TableCell>Amount</TableCell>
								<TableCell>Date</TableCell>
								<TableCell>Remark</TableCell>
								<TableCell>Action</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{paymentRowData?.map((data: any, index: any) => {
								return (
									<PaymentOptionRow
										index={index}
										data={data}
										onUpdateRowData={onUpdatePaymentRowData}
										onRemovePaymentRowData={onRemovePaymentRowData}
									/>
								);
							})}
						</TableBody>
						<TableBody>
							<TableRow>
								<TableCell
									colSpan={11}
									align="center"
									className="no-data"
									style={{ cursor: "pointer" }}
									onClick={() => onUpdatePaymentRowData(-1, [])}
								>
									+ Add New Payment Option
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
			{!isPaymentRowValidate ? (
				<p className="error MuiFormHelperText-root MuiFormHelperText-root.Mui-error">
					{"All field is required in payment method row."}
				</p>
			) : null}
			<DialogActions>
				<Button variant="contained" onClick={handleSubmit(handleOnClickSubmit)}>
					Submit
				</Button>
				<Button variant="outlined" onClick={handleOnClickClose}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}
