import React, { useContext } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Area from "../../pages/master/area";
import LoginPage from "../../pages/login-page";
import Building from "../../pages/master/building";
import DraftReasons from "../../pages/master/draft-reasons";
import EnquiryStatus from "../../pages/master/enquiry-status";
import FurnitureStatus from "../../pages/master/furniture-status";
import Measurements from "../../pages/master/measurements";
import NonUse from "../../pages/master/nonuse";
import PropertyStatus from "../../pages/master/property-status";
import PropertyType from "../../pages/master/property-type";
import Purpose from "../../pages/master/purpose";
import Role from "../../pages/master/role";
import Source from "../../pages/master/source";
import BHKOffice from "../../pages/master/bhk-office";
import Segment from "../../pages/master/segment";
import Budget from "../../pages/master/budget";
import MinMax from "../../pages/master/min-max";
import Price from "../../pages/master/price";
import Users from "../../pages/master/users";
import { appMenu } from "../enums/common/app-menu";
import Header from "../../components/layouts/header";
import Sidebar from "../../components/layouts/sidebar";
import { AppRoutes } from "../app-routes";
import { IUserDetails } from "../interfaces";
import { UserContext } from "../../contexts/user-context";
import { ProtectedRoute } from "./protected-route";
import { UnProtectedRoute } from "./unprotected-route";
import PropertyDeal from "../../pages/property-deal";
import Property from "../../pages/property";
import Enquiry from "../../pages/enquiry";
import ParentActivity from "../../pages/master/remark-master/parent";
import ChildActivity from "../../pages/master/remark-master/child";
import Dashboard from "../../pages/dashboard";

export const DeveloperRouterConfig: React.FC = () => {
	const currentUserDetails: IUserDetails = useContext(UserContext);
	const userPermission = currentUserDetails?.UserPermission;
	return (
		<Routes>
			<Route
				path={AppRoutes.login}
				element={<UnProtectedRoute element={<LoginPage />} />}
			/>
			<Route path="/*" element={<>No page Found 404</>} />
			<Route
				path="/"
				element={
					<div className="main-content">
						<Header />
						<div className="page-content">
							<Sidebar />
							<Outlet />
						</div>
						<footer>
							<p style={{ fontFamily: "𝒑", color: "white" }}>
								Developed by DualCore Technology
								<br />
								<a style={{ color: "white" }} href={`tel: +91 ${8128791896}`}>
									+91 8128791896 {"\u00A0"}|
								</a>
								{"\u00A0"}
								{"\u00A0"}
								<a style={{ color: "white" }} href={`tel: +91 ${9601859807}`}>
									+91 9601859807
								</a>
							</p>
						</footer>
					</div>
				}
			>
				<Route
					path={AppRoutes.dashboard}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<Dashboard />}
							menuId={appMenu.dashboard}
						/>
					}
				/>
				<Route
					path={AppRoutes.enquiry}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<Enquiry />}
							menuId={appMenu.enquiry}
						/>
					}
				/>
				{/* <Route
					path={AppRoutes.masterActivity}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<Activity />}
							menuId={appMenu.masterActivity}
						/>
					}
				/> */}
				<Route
					path={AppRoutes.masterArea}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<Area />}
							menuId={appMenu.masterArea}
						/>
					}
				/>
				<Route
					path={AppRoutes.masterBuilding}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<Building />}
							menuId={appMenu.masterBuilding}
						/>
					}
				/>
				{/* <Route
					path={AppRoutes.masterDraftReason}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<DraftReasons />}
							menuId={appMenu.masterDraftReason}
						/>
					}
				/> */}
				<Route
					path={AppRoutes.masterEnquiryStatus}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<EnquiryStatus />}
							menuId={appMenu.masterEnquiryStatus}
						/>
					}
				/>
				<Route
					path={AppRoutes.masterFurnitureStatus}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<FurnitureStatus />}
							menuId={appMenu.masterFurnitureStatus}
						/>
					}
				/>
				<Route
					path={AppRoutes.masterMeasurement}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<Measurements />}
							menuId={appMenu.masterMeasurement}
						/>
					}
				/>
				<Route
					path={AppRoutes.masterNonUse}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<NonUse />}
							menuId={appMenu.masterNonUse}
						/>
					}
				/>
				<Route
					path={AppRoutes.masterParentActivity}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<ParentActivity />}
							menuId={appMenu.masterParentActivity}
						/>
					}
				/>
				<Route
					path={AppRoutes.masterChildActivity}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<ChildActivity />}
							menuId={appMenu.masterChildActivity}
						/>
					}
				/>
				<Route
					path={AppRoutes.masterPropertyStatus}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<PropertyStatus />}
							menuId={appMenu.masterPropertyStatus}
						/>
					}
				/>
				<Route
					path={AppRoutes.masterPropertyType}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<PropertyType />}
							menuId={appMenu.masterPropertyType}
						/>
					}
				/>
				{/* <Route
					path={AppRoutes.masterPurpose}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<Purpose />}
							menuId={appMenu.masterPurpose}
						/>
					}
				/> */}
				<Route
					path={AppRoutes.masterRole}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<Role />}
							menuId={appMenu.masterRole}
						/>
					}
				/>
				<Route
					path={AppRoutes.masterSource}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<Source />}
							menuId={appMenu.masterSource}
						/>
					}
				/>
				<Route
					path={AppRoutes.masterBhkOffice}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<BHKOffice />}
							menuId={appMenu.masterBhkOffice}
						/>
					}
				/>
				<Route
					path={AppRoutes.masterSegment}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<Segment />}
							menuId={appMenu.masterSegment}
						/>
					}
				/>
				<Route
					path={AppRoutes.masterBudget}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<Budget />}
							menuId={appMenu.masterBudget}
						/>
					}
				/>
				{/* <Route
					path={AppRoutes.masterMinMax}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<MinMax />}
							menuId={appMenu.masterMinMax}
						/>
					}
				/> */}
				{/* <Route
					path={AppRoutes.masterPrice}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<Price />}
							menuId={appMenu.masterPrice}
						/>
					}
				/> */}
				<Route
					path={AppRoutes.masterUser}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<Users />}
							menuId={appMenu.masterUser}
						/>
					}
				/>
				<Route
					path={AppRoutes.propertyDeal}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<PropertyDeal />}
							menuId={appMenu.propertyDeal}
						/>
					}
				/>
				<Route
					path={AppRoutes.property}
					element={
						<ProtectedRoute
							userPermission={userPermission}
							element={<Property />}
							menuId={appMenu.property}
						/>
					}
				/>
			</Route>
		</Routes>
	);
};
