import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { NoRecordFound } from "./no-record-found";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAllCommonRecords, getAllCommonTableRecords } from "../../services";
import { APIRoutes } from "../../utility/app-routes";
import { HttpStatusCodes } from "../../utility/enums";
import { CustomPagination } from "./pagination";
import { CustomDatePicker } from "./custom-date-picker";
import {
	IDropDownFields,
	ITeamActivitySearchCriteria,
} from "../../utility/interfaces/common";
import { TeamActivityRow } from "./team-activity-row";
import dayjs from "dayjs";
import { tableRowPerPage } from "../../utility/constant";

type ComponentProps = {
	dialogOpen: boolean;
	handleOnClickClose: any;
};
export function TeamActivityDialog(props: ComponentProps) {
	const { dialogOpen, handleOnClickClose } = props;
	const [tableData, setTableData] = useState<any>([]);
	const [userList, setUserList] = useState<IDropDownFields[]>([]);
	const [pageList, setPageList] = useState<string[]>([]);
	const [totalRecordCount, setTotalRecordCount] = useState<any>(0);

	const [searchCriteria, setSearchCriteria] =
		useState<ITeamActivitySearchCriteria>({
			rowPerPage: tableRowPerPage,
			pageNumber: 1,
			sortBy: "",
			sortDirection: "asc",
			text: "",
			fromDate: dayjs(new Date().setDate(new Date().getDate() - 5)),
			toDate: dayjs(new Date()),
			pageName: "All",
			userId: 0,
		});
	const clearFilter = () => {
		setSearchCriteria({
			rowPerPage: tableRowPerPage,
			pageNumber: 1,
			sortBy: "",
			sortDirection: "asc",
			text: "",
			fromDate: dayjs(new Date().setDate(new Date().getDate() - 5)),
			toDate: dayjs(new Date()),
			pageName: "All",
			userId: 0,
		});
	};
	const getPageLoadRecords = async () => {
		const { data } = await getAllCommonTableRecords(
			APIRoutes.getTeamActivityReport,
			searchCriteria
		);
		if (data && data.status === HttpStatusCodes.Ok) {
			setTableData(data.response);
			setTotalRecordCount(data.totalCount);
		} else if (data && data.status === HttpStatusCodes.NotFound) {
			setTableData([]);
			setTotalRecordCount(0);
		} else {
			setTableData([]);
			toast.error(data.message);
			setTotalRecordCount(0);
		}
	};
	const getPageLoadData = async () => {
		const { data } = await getAllCommonRecords(
			APIRoutes.getTeamActivityReportPageLoad
		);
		if (data && data.status === HttpStatusCodes.Ok) {
			setUserList(data.response.userList);
			setPageList(data.response.pageList);
		} else {
			setUserList([]);
			setPageList([]);
			toast.error(data.message);
		}
	};

	const onChangeValue = (value: any, name: string) => {
		setSearchCriteria({ ...searchCriteria, [name]: value });
	};

	const handleOnClickSearch = () => {
		getPageLoadRecords();
	};
	useEffect(() => {
		if (dialogOpen) {
			getPageLoadData();
			getPageLoadRecords();
		}
	}, [dialogOpen]);

	useEffect(() => {
		if (dialogOpen) getPageLoadRecords();
	}, [searchCriteria.pageNumber | searchCriteria.rowPerPage]);

	// useEffects
	return (
		<Dialog
			open={dialogOpen}
			onClose={handleOnClickClose}
			maxWidth="md"
			fullWidth
		>
			<DialogTitle>
				Team's Activity
				<IconButton aria-label="Close" onClick={handleOnClickClose}>
					<CloseOutlined />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<div className="white-paper">
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<FormControl fullWidth>
								<InputLabel id="bhk-office-label">Users</InputLabel>
								<Select
									labelId="bhk-office-label"
									id="page"
									value={searchCriteria.userId}
									label="User"
									onChange={(e) => onChangeValue(+e.target.value, "userId")}
								>
									<MenuItem value="0">Select</MenuItem>
									{userList.map((record) => {
										return (
											<MenuItem key={record.id} value={record.id}>
												{record.text}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<FormControl fullWidth>
								<InputLabel id="bhk-office-label">page</InputLabel>
								<Select
									labelId="bhk-office-label"
									id="page"
									value={searchCriteria.pageName}
									label="Page"
									onChange={(e) => onChangeValue(e.target.value, "pageName")}
								>
									<MenuItem value="All">Select</MenuItem>
									{pageList.map((record) => {
										return (
											<MenuItem key={record} value={record}>
												{record}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={4}
							lg={3}
							className="datepicker-wrappper"
						>
							<CustomDatePicker
								disabled={false}
								label="From"
								onChangeValue={(newValue: any) =>
									onChangeValue(newValue, "fromDate")
								}
								value={searchCriteria.fromDate}
								maxDate={searchCriteria.toDate}
								minDate={new Date().setFullYear(new Date().getFullYear() - 100)}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={4}
							lg={3}
							className="datepicker-wrappper"
						>
							<CustomDatePicker
								disabled={false}
								label="To"
								onChangeValue={(newValue: any) =>
									onChangeValue(newValue, "toDate")
								}
								value={searchCriteria.toDate}
								maxDate={new Date().setFullYear(new Date().getFullYear() + 100)}
								minDate={searchCriteria.fromDate}
							/>
						</Grid>
						<Grid
							container
							spacing={2}
							alignItems="center"
							justifyContent="end"
							sx={{ marginTop: "2px", marginBottom: "5px" }}
						>
							<Grid item xs="auto">
								<Button
									style={{ marginRight: 10 }}
									variant="contained"
									onClick={handleOnClickSearch}
								>
									Search
								</Button>
								<Button variant="outlined" onClick={clearFilter}>
									Clear
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<div className="table-negative-space">
						<TableContainer>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>User Name</TableCell>
										<TableCell>Page Name</TableCell>
										<TableCell>Description</TableCell>
										<TableCell>Created On</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{tableData?.map((data: any) => {
										return <TeamActivityRow data={data} key={data.id} />;
									})}
								</TableBody>
								{tableData?.length <= 0 && <NoRecordFound />}
							</Table>
						</TableContainer>
					</div>
					<CustomPagination
						handleOnChangeSearchCriteria={onChangeValue}
						pageNumber={searchCriteria.pageNumber}
						rowPerPage={searchCriteria.rowPerPage}
						totalRecords={totalRecordCount}
					/>
				</div>
			</DialogContent>
		</Dialog>
	);
}
