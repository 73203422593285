import React, { useEffect, useState } from "react";
import {
	Avatar,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	TextField,
} from "@mui/material";
import { CloseOutlined, FileUploadOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import { getAllCommonRecords, updateMyProfile } from "../../services";
import { HttpStatusCodes } from "../../utility/enums";
import { APIRoutes } from "../../utility/app-routes";
import {
	isInputOnlyChar,
	resizeImageAndGetBase64,
} from "../../utility/helpers/common";
import { CustomDatePicker } from "./custom-date-picker";
import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

type ComponentProps = {
	dialogOpen: boolean;
	handleOnClickClose: any;
};
export function ViewMyProfileDialog(props: ComponentProps) {
	const { dialogOpen, handleOnClickClose } = props;
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [mobile, setMobile] = useState("");
	const [email, setEmail] = useState("");
	const [dateOfJoin, setDateOfJoin] = useState<Date | null>(null);
	const [isActive, setIsActive] = useState(true);
	const [role, setRole] = useState("");
	const [parent, setParent] = useState("");
	const [profileImage, setProfileImage] = useState<any>(null);

	const getPageLoadRecords = async () => {
		const { data } = await getAllCommonRecords(APIRoutes.getMyProfileDetails);
		if (data && data.status === HttpStatusCodes.Ok) {
			setFirstName(data.response.firstName);
			setDateOfJoin(data.response.dateOfJoin);
			setLastName(data.response.lastName);
			setMobile(data.response.mobile);
			setEmail(data.response.email);
			setIsActive(data.response.isActive);
			setRole(data.response.roleName);
			setParent(data.response.parentName);
			setProfileImage(data.response.profileImage);
		} else {
			toast.error(data.message);
		}
	};
	const handleOnClickSubmit = async () => {
		let response = await updateMyProfile({
			firstName,
			lastName,
			profileImage,
		});
		if (response?.data.status === HttpStatusCodes.Ok) {
			toast.success(response.data.message);
		} else toast.error(response.data.message);
	};
	const handlePropfilePhotoSelect = async (event: any) => {
		if (event.target.files.length > 0) {
			if (
				event.target.files[0].type !== "image/jpeg" &&
				event.target.files[0].type !== "image/png"
			) {
				toast.error("Selected file not allowed.");
				return;
			}
			const base64 = await resizeImageAndGetBase64(event.target.files[0]);
			setProfileImage(base64);
		}
	};
	// useEffects
	useEffect(() => {
		if (dialogOpen) {
			getPageLoadRecords();
		} else {
			setFirstName("");
			setLastName("");
			setMobile("");
			setEmail("");
			setIsActive(true);
			setRole("");
			setParent("");
			setProfileImage("");
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dialogOpen]);

	const formValidationSchema = object({
		fname: string().required("First name is required"),
		lname: string().required("Last name is required"),
	});
	useEffect(() => {
		setValue("fname", firstName);
		setValue("lname", lastName);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstName]);

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
	} = useForm({
		resolver: yupResolver(formValidationSchema),
	});
	useEffect(() => {
		reset();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<Dialog
			open={dialogOpen}
			onClose={handleOnClickClose}
			maxWidth="md"
			fullWidth
		>
			<DialogTitle>
				My Profile
				<IconButton aria-label="Close" onClick={handleOnClickClose}>
					<CloseOutlined />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2} alignItems="center" className="mb-20">
					<Grid item xs={12} sm={12} md={12}>
						<Avatar
							style={{ textAlign: "center", maxWidth: "50px", height: "50px" }}
							alt="Profile Image"
							src={profileImage}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<TextField
							id="fname"
							{...register("fname")}
							error={errors?.fname?.message ? true : false}
							helperText={errors?.fname?.message?.toString()}
							label="First Name"
							type="text"
							fullWidth
							variant="outlined"
							value={firstName}
							onChange={(e) => {
								if (isInputOnlyChar(e.target.value))
									setFirstName(e.target.value);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<TextField
							id="lname"
							{...register("lname")}
							error={errors?.lname?.message ? true : false}
							helperText={errors?.lname?.message?.toString()}
							label="Last Name"
							type="text"
							fullWidth
							variant="outlined"
							value={lastName}
							onChange={(e) => {
								if (isInputOnlyChar(e.target.value))
									setLastName(e.target.value);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<TextField
							id="roleName"
							label="Role"
							type="text"
							fullWidth
							disabled
							variant="outlined"
							value={role}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<TextField
							id="parent"
							label="Parent"
							disabled
							type="text"
							fullWidth
							variant="outlined"
							value={parent}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<TextField
							id="phone"
							disabled
							label="Phone Number"
							fullWidth
							variant="outlined"
							value={mobile}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<TextField
							id="email"
							disabled
							label="Email"
							type="email"
							fullWidth
							variant="outlined"
							value={email}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<label htmlFor="contained-button-file" className="upload">
							<input
								onChange={handlePropfilePhotoSelect}
								id="contained-button-file"
								type="file"
								accept="image/*"
							/>
							<Button
								variant="outlined"
								component="span"
								startIcon={<FileUploadOutlined />}
								className="w-full"
							>
								Upload Profile
							</Button>
						</label>
					</Grid>
					<Grid item xs={12} sm={6} md={4} className="datepicker-wrappper">
						<CustomDatePicker
							disabled={true}
							label="Date of join"
							onChangeValue={(newValue: any) => setDateOfJoin(newValue)}
							value={dateOfJoin}
							maxDate={new Date().setFullYear(new Date().getFullYear() + 200)}
							minDate={new Date().setFullYear(new Date().getFullYear() - 200)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={handleSubmit(handleOnClickSubmit)}>
					Submit
				</Button>
				<Button variant="outlined" onClick={handleOnClickClose}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}
