import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	TextField,
	Tooltip,
} from "@mui/material";
import { Add, Refresh } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { HttpStatusCodes } from "../../../utility/enums";
import { getAllCommonRecords, getRecordsOnPageLoad } from "../../../services";
import { APIRoutes } from "../../../utility/app-routes";
import { toast } from "react-toastify";
import { CustomPagination, NoRecordFound } from "../../../components";
import { getComparator, stableSort } from "../../../utility/helpers/common";
import { IDropDownFields, ISearchCriteria } from "../../../utility/interfaces";
import { UsersRecordRow } from "../../../components/common/common-master-record/user-record-row";
import { AddUpdateUserDialog } from "../../../components/common/common-master-record/add-update-user-dialog";
import { tableRowPerPage } from "../../../utility/constant";

const Users: React.FC = () => {
	// Table Pagination
	const handleOnChangeSearchText = (value: any) => {
		setSearchCriteria({
			...searchCriteria,
			text: value,
			pageNumber: 1,
			sortBy: "name",
			sortDirection: "asc",
		});
	};
	const [isAddNewRecordOpen, setIsAddNewRecordOpen] = useState(false);
	const [bindPageData, setBindPageData] = useState<any>([]);

	const [tableData, setTableData] = useState<any>([]);
	const [searchCriteria, setSearchCriteria] = useState<ISearchCriteria>({
		rowPerPage: tableRowPerPage,
		pageNumber: 1,
		sortBy: "",
		sortDirection: "asc",
		text: "",
	});
	const handleOnChangeSearchCriteria = (value: any, name: string) => {
		setSearchCriteria({ ...searchCriteria, [name]: value });
	};
	const handleOnChangeSorting = (sortBy: string) => {
		setSearchCriteria({
			...searchCriteria,
			sortDirection: searchCriteria.sortDirection === "asc" ? "desc" : "asc",
			sortBy,
		});
	};
	const getAllRecords = async () => {
		const { data } = await getAllCommonRecords(APIRoutes.getAllUsers);
		if (data && data.status === HttpStatusCodes.Ok) {
			setBindPageData(data.response);
		} else {
			setBindPageData([]);
			toast.error(data.message);
		}
	};

	useEffect(() => {
		getAllRecords();
	}, []);
	useEffect(() => {
		let filteredData: any = [];
		stableSort(
			searchCriteria.text.length > 0
				? bindPageData.filter((data: any) =>
						data.firstName
							?.toLowerCase()
							.includes(searchCriteria.text?.toLowerCase())
				  )
				: bindPageData,
			getComparator(searchCriteria.sortDirection, searchCriteria.sortBy)
		).map((data) => {
			filteredData.push({
				id: data.id,
				userId: data.userId,
				isActive: data.isActive ? true : false,
				firstName: data.firstName,
				lastName: data.lastName,
				email: data.email,
				mobile: data.mobile,
				password: data.password,
				role: +data.roleId,
				roleName: data.roleName,
				parent: +data.parentId,
				parentName: data.parentName,
				name: data.firstName + " " + data.lastName,
			});
		});
		filteredData = filteredData.slice(
			(searchCriteria.pageNumber - 1) * searchCriteria.rowPerPage,
			(searchCriteria.pageNumber - 1) * searchCriteria.rowPerPage +
				searchCriteria.rowPerPage
		);
		setTableData(filteredData);
	}, [bindPageData, searchCriteria]);

	return (
		<div className="page-inner">
			<>
				<div className="page-heading-wrapper">
					<h4 className="page-heading">Users</h4>
					<Tooltip title="Refresh">
						<IconButton
							aria-label="refresh"
							color="primary"
							className="primary-icon"
							onClick={getAllRecords}
						>
							<Refresh />
						</IconButton>
					</Tooltip>
					<Tooltip title="Add User">
						<IconButton
							aria-label="add"
							color="primary"
							className="primary-icon"
							onClick={() => setIsAddNewRecordOpen(true)}
						>
							<Add />
						</IconButton>
					</Tooltip>
				</div>
				<div className="white-paper">
					<div className="table-heading-block">
						<TextField
							value={searchCriteria.text}
							autoFocus
							onChange={(e) => {
								handleOnChangeSearchText(e.target.value);
							}}
							label="Search"
							variant="outlined"
						/>
					</div>
					<div className="table-negative-space">
						<TableContainer>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell sortDirection={searchCriteria.sortDirection}>
											<TableSortLabel
												active={searchCriteria.sortBy === "firstName"}
												direction={searchCriteria.sortDirection}
												onClick={() => handleOnChangeSorting("firstName")}
											>
												Name
											</TableSortLabel>
										</TableCell>
										<TableCell sortDirection={searchCriteria.sortDirection}>
											<TableSortLabel
												active={searchCriteria.sortBy === "email"}
												direction={searchCriteria.sortDirection}
												onClick={() => handleOnChangeSorting("email")}
											>
												Email
											</TableSortLabel>
										</TableCell>
										<TableCell sortDirection={searchCriteria.sortDirection}>
											<TableSortLabel
												active={searchCriteria.sortBy === "mobile"}
												direction={searchCriteria.sortDirection}
												onClick={() => handleOnChangeSorting("mobile")}
											>
												Mobile
											</TableSortLabel>
										</TableCell>
										<TableCell sortDirection={searchCriteria.sortDirection}>
											{/* <TableSortLabel
												active={searchCriteria.sortBy === "role"}
												direction={searchCriteria.sortDirection}
												onClick={() => handleOnChangeSorting("role")}
											> */}
											Role
											{/* </TableSortLabel> */}
										</TableCell>
										<TableCell sortDirection={searchCriteria.sortDirection}>
											{/* <TableSortLabel
												active={searchCriteria.sortBy === "parent"}
												direction={searchCriteria.sortDirection}
												onClick={() => handleOnChangeSorting("parent")}
											> */}
											Parent
											{/* </TableSortLabel> */}
										</TableCell>
										<TableCell>
											<TableSortLabel
												active={searchCriteria.sortBy === "isActive"}
												direction={searchCriteria.sortDirection}
												onClick={() => handleOnChangeSorting("isActive")}
											>
												Status
											</TableSortLabel>
										</TableCell>
										<TableCell className="action-col" align="center">
											Edit
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{tableData.map((data: any) => {
										return (
											<UsersRecordRow
												key={data.id}
												addUpdateApiUrl={APIRoutes.addUpdateUser}
												deleteApiUrl={APIRoutes.deleteUsers}
												title={"User"}
												getAllRecords={getAllRecords}
												data={data}
											/>
										);
									})}
								</TableBody>
								{tableData?.length <= 0 && <NoRecordFound />}
							</Table>
						</TableContainer>
					</div>
					<CustomPagination
						handleOnChangeSearchCriteria={handleOnChangeSearchCriteria}
						pageNumber={searchCriteria.pageNumber}
						rowPerPage={searchCriteria.rowPerPage}
						totalRecords={bindPageData?.length}
					/>
				</div>
				<>
					{/* Add record */}
					<AddUpdateUserDialog
						dialogOpen={isAddNewRecordOpen}
						handleOnClickClose={() => setIsAddNewRecordOpen(false)}
						getAllRecords={getAllRecords}
						recordId={0}
					/>
				</>
			</>
		</div>
	);
};
export default Users;
