import { CloseOutlined } from "@mui/icons-material";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Typography,
} from "@mui/material";
import { getDealTypeText } from "../../utility/enums/common/deal-type";

type ComponentProps = {
	dialogOpen: boolean;
	handleOnClickClose: any;
	data: any;
};
export function ViewEnquiry(props: ComponentProps) {
	const { dialogOpen, handleOnClickClose, data } = props;

	return (
		<Dialog
			open={dialogOpen}
			onClose={handleOnClickClose}
			maxWidth="md"
			fullWidth
		>
			<DialogTitle>
				Enquiry Details
				<IconButton aria-label="Close" onClick={handleOnClickClose}>
					<CloseOutlined />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Enquiry For
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{getDealTypeText(data?.enquiryFor)}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Client Name
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.nameOfClient}
						</Typography>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Mobile No
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.mobileNo}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Bhk/Office
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.bhkOfficeName}
						</Typography>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Source
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.sourceName}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Enquiry Status
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.enquiryStatusName}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Budget
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.budgetName}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Area
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.areaName}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Non Use
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.nonuseName}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Assign To
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.assignToName}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Assign By
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.assignByName}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Next Followup Date
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data.nfd}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Mobile No 1
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.mobile1}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Mobile No 2
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.mobile2}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Mobile No 3
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.mobile3}
						</Typography>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Created Date
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.createdOn}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<Typography variant="body2" className="text-light-grey">
							Email
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.email}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Typography variant="body2" className="text-light-grey">
							Last Remark
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.lastRemark}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Typography variant="body2" className="text-light-grey">
							Enquiry Remark
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.remark}
						</Typography>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={handleOnClickClose}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}
