import { APIRoutes } from "../../../utility/app-routes";
import { MasterCommonRecordPage } from "../../../components/common/common-master-record/master-common-record-page";

const EnquiryStatus: React.FC = () => {
	// Table Pagination

	return (
		<div className="page-inner">
			<>
				<MasterCommonRecordPage
					addUpdateApiUrl={APIRoutes.appUpdateEnquiryStatus}
					deleteApiUrl={APIRoutes.deleteEnquiryStatus}
					title="Enquiry Status"
					getAllRecordApiUrl={APIRoutes.getAllEnquiryStatus}
				/>
			</>
		</div>
	);
};
export default EnquiryStatus;
