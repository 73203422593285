import { IconButton, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import {
	Add,
	Close,
	FileDownload,
	FilterAltOutlined,
	Refresh,
} from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import { EnquiryFilter } from "../../components/enquiry/filter";
import {
	IDropDownFields,
	IDropDownFieldsWithBhkofficeId,
	IEnquirySearchCriteria,
} from "../../utility/interfaces/common";
import { HttpStatusCodes } from "../../utility/enums";
import { toast } from "react-toastify";
import { getAllCommonRecords, getAllEnquiry } from "../../services";
import { APIRoutes } from "../../utility/app-routes";
import { DashboardEnquiryTable } from "../../components";
import { AddUpdateEnquiry } from "../../components/enquiry/add-update-dialog";
import { IChildActivityDropDownFields } from "../../utility/interfaces/master";
import {
	DealType,
	getDealTypeText,
} from "../../utility/enums/common/deal-type";
import { tableRowPerPage } from "../../utility/constant";
import { downloadExcel } from "react-export-table-to-excel";
import { UserContext } from "../../contexts/user-context";
import { IUserDetails } from "../../utility/interfaces";

const Enquiry: React.FC = () => {
	const currentUserDetails: IUserDetails = useContext(UserContext);

	const [searchCriteria, setSearchCriteria] = useState<IEnquirySearchCriteria>({
		rowPerPage: tableRowPerPage,
		pageNumber: 1,
		sortBy: "dealDate",
		sortDirection: "asc",
		text: "",
		enquiryFor: DealType.All,
		bhkOfficeId: 0,
		budgetId: 0,
		sourceId: 0,
		fromNfd: dayjs(new Date().setFullYear(new Date().getFullYear() - 5)),
		toNfd: dayjs(new Date().setFullYear(new Date().getFullYear() + 5)),
		enquiryStatusId: 1,
		nonUseId: 0,
		employeeId: currentUserDetails.UserId,
	});
	const [bhkOfficeList, setBhkOfficeList] = useState<IDropDownFields[]>([]);
	const [budgetList, setBudgetList] = useState<
		IDropDownFieldsWithBhkofficeId[]
	>([]);
	const [sourceList, setSourceList] = useState<IDropDownFields[]>([]);
	const [enquiryStatusList, setEnquiryStatusList] = useState<IDropDownFields[]>(
		[]
	);
	const [nonUseList, setNonUseList] = useState<IDropDownFields[]>([]);
	const [employeeList, setEmployeeList] = useState<IDropDownFields[]>([]);
	const [areaList, setAreaList] = useState<IDropDownFields[]>([]);
	const [childActivityList, setChildActivityList] = useState<
		IChildActivityDropDownFields[]
	>([]);
	const [parentActivityList, setParentActivityList] = useState<
		IDropDownFields[]
	>([]);

	const [isFilterOpen, setIsFilterOpen] = useState(false);
	const [isAddNewRecordOpen, setIsAddNewRecordOpen] = useState(false);
	const [tableData, setTableData] = useState<any>([]);
	const [totalRecords, setTotalRecords] = useState(0);

	const getAllRecords = async () => {
		const { data } = await getAllEnquiry(searchCriteria);
		if (data && data.status === HttpStatusCodes.Ok) {
			if (data.response.length > 0) setTableData(data.response);
			else setTableData([]);
		} else if (data && data.status === HttpStatusCodes.NotFound) {
			setTableData([]);
		} else {
			setTableData([]);
			toast.error(data.message);
		}
		setTotalRecords(data.totalCount);
	};
	const handleOnChangeSearchCriteria = (value: any, name: string) => {
		setSearchCriteria({ ...searchCriteria, [name]: value });
	};

	const handleOnClickSearch = async () => {
		if (searchCriteria.pageNumber === 1) {
			await getAllRecords();
		} else setSearchCriteria({ ...searchCriteria, pageNumber: 1 });
	};

	const handleOnClearSearchCriteria = () => {
		setSearchCriteria({
			...searchCriteria,
			sortBy: "dealDate",
			sortDirection: "asc",
			enquiryFor: DealType.All,
			bhkOfficeId: 0,
			budgetId: 0,
			sourceId: 0,
			fromNfd: dayjs(new Date().setFullYear(new Date().getFullYear() - 5)),
			toNfd: dayjs(new Date().setFullYear(new Date().getFullYear() + 5)),
			enquiryStatusId: 1,
			nonUseId: 0,
			employeeId: 0,
		});
	};
	const getPageLoadRecords = async () => {
		const { data } = await getAllCommonRecords(APIRoutes.enquiryOnPageLoad);
		if (data && data.status === HttpStatusCodes.Ok) {
			setSourceList(data.response.sourceList);
			setBhkOfficeList(data.response.bhkOfficeList);
			setBudgetList(data.response.budgetList);
			// setNonUseList(data.response.nonuseList);
			setEmployeeList(data.response.userList);
			setAreaList(data.response.areaList);
			setEnquiryStatusList(data.response.enquiryStatusList);
			setChildActivityList(data.response.activityChildList);
			setParentActivityList(data.response.activityParentList);
		} else {
			toast.error(data.message);
		}
	};

	const exportToExcel = async () => {
		if (tableData.length > 0) {
			const header = [
				"Client Name",
				"Mobile Number",
				"Email",
				"Enquiry For",
				"Source Name",
				"Bhk Office",
				"Segment",
				"Property Type",
				"Enquiry Status",
				"Budget",
				"Non Use",
				"Remark",
				"Last Remark",
				"Assign To",
				"Assign By",
				"NFD",
				"Is Closed?",
				"Mobile1",
				"Mobile2",
				"Mobile3",
				"Created On",
			];
			const body: any = [];

			tableData.forEach((element: any) => {
				body.push([
					element.nameOfClient,
					element.mobileNo,
					element.email,
					getDealTypeText(element.enquiryFor),
					element.sourceName,
					element.bhkOfficeName,
					element.segmentName,
					element.propertyTypeName,
					element.enquiryStatusName,
					element.budgetName,
					element.nonuseName,
					element.remark,
					element.lastRemark,
					element.assignToName,
					element.assignByName,
					element.nfd,
					element.isClosed === true ? "Yes" : "No",
					element.mobile1,
					element.mobile2,
					element.mobile3,
					element.createdOn,
				]);
			});
			downloadExcel({
				fileName: `${Date.now()}_Enquiry_Export`,
				sheet: "Enquiry",
				tablePayload: {
					header,
					body,
				},
			});
		} else {
			toast.error("No data found in below table.");
		}
	};

	useEffect(() => {
		getAllRecords();
	}, [searchCriteria.rowPerPage | searchCriteria.pageNumber]);

	useEffect(() => {
		if (searchCriteria.text.length >= 0) getAllRecords();
	}, [searchCriteria.text]);

	return (
		<>
			<div className="page-inner">
				<div className="page-heading-wrapper">
					<h4 className="page-heading">Enquiry</h4>
					<Tooltip title="Refresh">
						<IconButton
							aria-label="refresh"
							color="primary"
							className="primary-icon"
							onClick={getAllRecords}
						>
							<Refresh />
						</IconButton>
					</Tooltip>
					<Tooltip title="Add Enquiry">
						<IconButton
							aria-label="add"
							color="primary"
							className="primary-icon"
							onClick={() => setIsAddNewRecordOpen(true)}
						>
							<Add />
						</IconButton>
					</Tooltip>
					<Tooltip title="Filter Data">
						<IconButton
							aria-label="filter"
							color="primary"
							className="primary-icon"
							onClick={() => setIsFilterOpen(!isFilterOpen)}
						>
							{isFilterOpen ? <Close /> : <FilterAltOutlined />}
						</IconButton>
					</Tooltip>
					<Tooltip title="Export To Excel">
						<IconButton
							aria-label="export"
							color="primary"
							className="primary-icon"
							onClick={exportToExcel}
						>
							<FileDownload />
						</IconButton>
					</Tooltip>
				</div>
				<EnquiryFilter
					bhkOfficeList={bhkOfficeList}
					budgetList={budgetList}
					sourceList={sourceList}
					enquiryStatusList={enquiryStatusList}
					nonUseList={nonUseList}
					employeeList={employeeList}
					isOpen={isFilterOpen}
					values={searchCriteria}
					onChangeValue={handleOnChangeSearchCriteria}
					handleOnClearSearchCriteria={handleOnClearSearchCriteria}
					handleOnClickSearch={handleOnClickSearch}
					getPageLoadData={getPageLoadRecords}
				/>
				<DashboardEnquiryTable
					tableData={tableData}
					getAllRecords={getAllRecords}
					bhkOfficeList={bhkOfficeList}
					budgetList={budgetList}
					sourceList={sourceList}
					enquiryStatusList={enquiryStatusList}
					nonUseList={nonUseList}
					employeeList={employeeList}
					areaList={areaList}
					childActivityList={childActivityList}
					handleOnChangeSearchCriteria={handleOnChangeSearchCriteria}
					parentActivityList={parentActivityList}
					searchCriteria={searchCriteria}
					totalRecords={totalRecords}
					getPageLoadData={getPageLoadRecords}
				/>
			</div>
			<AddUpdateEnquiry
				dialogOpen={isAddNewRecordOpen}
				handleOnClickClose={() => setIsAddNewRecordOpen(false)}
				data={undefined}
				getPageLoadData={getPageLoadRecords}
				getAllRecords={getAllRecords}
				bhkOfficeList={bhkOfficeList}
				budgetList={budgetList}
				sourceList={sourceList}
				enquiryStatusList={enquiryStatusList}
				nonUseList={nonUseList}
				employeeList={employeeList}
				areaList={areaList}
			/>
		</>
	);
};
export default Enquiry;
