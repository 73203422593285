import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../../pages/design/login";
import Dashboard from "../../pages/design/dashboard";
import Activity from "../../pages/design/activity";
import Area from "../../pages/design/area";
import BhkOffice from "../../pages/design/bhkOffice";
import Budget from "../../pages/design/budget";
import Building from "../../pages/design/building";
import DraftReason from "../../pages/design/draftReason";
import EnquiryStatus from "../../pages/design/enquiryStatus";
import Furniture from "../../pages/design/furniture";
import Measurement from "../../pages/design/measurement";
import Menu from "../../pages/design/menu";
import BudgetMinMax from "../../pages/design/budgetMinMax";
import NonUse from "../../pages/design/nonUse";
import Price from "../../pages/design/price";
import PropertyStatus from "../../pages/design/propertyStatus";
import PropertyType from "../../pages/design/propertyType";
import Purpose from "../../pages/design/purpose";
import Role from "../../pages/design/role";
import Segment from "../../pages/design/segment";
import Source from "../../pages/design/source";
import User from "../../pages/design/user";
import Enquiry from "../../pages/design/enquiry";
import Property from "../../pages/design/property";
import PropertyDeal from "../../pages/design/propertyDeal";

export const DesignerRouterConfig: React.FC = () => {
	return (
		<Routes>
			<Route path="/*" element={<>No page Found 404</>} />
			<Route path="/" element={<Login />} />
			<Route path="/dashboard" element={<Dashboard />} />
			<Route path="/activity" element={<Activity />} />
			<Route path="/area" element={<Area />} />
			<Route path="/bhk-office" element={<BhkOffice />} />
			<Route path="/budget" element={<Budget />} />
			<Route path="/building" element={<Building />} />
			<Route path="/draft-reason" element={<DraftReason />} />
			<Route path="/enquiry-status" element={<EnquiryStatus />} />
			<Route path="/furniture" element={<Furniture />} />
			<Route path="/measurement" element={<Measurement />} />
			<Route path="/menu" element={<Menu />} />
			<Route path="/min-max" element={<BudgetMinMax />} />
			<Route path="/non-use" element={<NonUse />} />
			<Route path="/price" element={<Price />} />
			<Route path="/property-status" element={<PropertyStatus />} />
			<Route path="/property-type" element={<PropertyType />} />
			<Route path="/purpose" element={<Purpose />} />
			<Route path="/role" element={<Role />} />
			<Route path="/segment" element={<Segment />} />
			<Route path="/source" element={<Source />} />
			<Route path="/user" element={<User />} />
			<Route path="/enquiry" element={<Enquiry />} />
			<Route path="/property" element={<Property />} />
			<Route path="/property-deal" element={<PropertyDeal />} />
		</Routes>
	);
};
