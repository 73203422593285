import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { APPLICATION, APPLICATION_TYPE, BASE_API_URL } from "../config";
import { HttpStatusCodes } from "../utility/enums";
import { getToken } from "../utility/auth-guard";
import { hideLoader, showLoader } from "../utility/helpers/common";
import { clearSession } from "../utility/auth-guard/token-manager";

axios.interceptors.request.use(
	async (config: AxiosRequestConfig | any) => {
		showLoader();
		config.mode = "no-cors";
		config.headers["Access-Control-Allow-Origin"] = "*";
		config.headers["Content-Type"] = "application/json";
		config.headers["Access-Control-Allow-Headers"] =
			"Origin, X-Requested-With, Content-Type, Accept";
		config.headers["Access-Control-Allow-Methods"] =
			"GET, PUT, POST, DELETE, OPTIONS, PATCH";
		if (config.url) {
			config.url = BASE_API_URL + config.url;
		}
		config.headers.Authorization = getToken();
		config.headers.Application = APPLICATION;
		config.headers.ApplicationType = APPLICATION_TYPE;
		return config;
	},
	(error: AxiosError) => {
		console.log("Request Error:", error.response);
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	function (response) {
		hideLoader();
		return response;
	},
	function (error: AxiosError) {
		hideLoader();
		switch (error.response?.status) {
			case HttpStatusCodes.Unauthorized: {
				clearSession();
				const errorResponse: any = {
					data: {
						message: "Unauthorized, Try to Login Again.",
						status: 401,
						response: null,
						totalCount: 0,
					},
					status: 401,
					statusText: "",
				};
				return errorResponse;
			}
			default: {
				clearSession();
				const errorResponse: any = {
					data: {
						message: "Some thing went wrong, please try again.",
						status: 500,
						response: null,
						totalCount: 0,
					},
					status: 500,
					statusText: "",
				};
				return errorResponse;
			}
		}
	}
);

export default {
	get: axios.get,
	post: axios.post,
	put: axios.put,
	delete: axios.delete,
	patch: axios.patch,
	CancelToken: axios.CancelToken,
};
