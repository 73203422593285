// Manage 2 places : my-app/src/utility/app-routes/index.ts and here
export enum appMenu {
	dashboard = 1,
	enquiry = 2,
	property = 3,
	masterActivity = 4,
	masterArea = 5,
	masterBhkOffice = 6,
	masterBudget = 7,
	masterBuilding = 8,
	masterDraftReason = 9,
	masterEnquiryStatus = 10,
	masterFurnitureStatus = 11,
	masterMeasurement = 12,
	masterPropertyType = 13,
	masterMinMax = 15,
	masterNonUse = 16,
	masterPrice = 17,
	masterPropertyStatus = 18,
	masterPurpose = 19,
	masterRole = 20,
	masterSegment = 21,
	masterSource = 22,
	masterUser = 23,
	propertyDeal = 24,
	masterParentActivity = 25,
	masterChildActivity = 26,
}
