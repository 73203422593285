import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
} from "@mui/material";
import { Add, Close, FilterAltOutlined, Refresh } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { PropertyFilter } from "../../components/property/filter";
import { AddUpdateProperty } from "../../components/property/add-update-dialog";
import { toast } from "react-toastify";
import { HttpStatusCodes } from "../../utility/enums";
import { CustomPagination, NoRecordFound } from "../../components";
import { PropertyRow } from "../../components/property/property-row";
import { IPropertySearchCriteria } from "../../utility/interfaces/common";
import { DealType } from "../../utility/enums/common/deal-type";
import dayjs from "dayjs";
import { tableRowPerPage } from "../../utility/constant";
import { getAllProperties } from "../../services";

const Property: React.FC = () => {
	const [isAddNewRecordOpen, setIsAddNewRecordOpen] = useState(false);
	const [isFilterOpen, setIsFilterOpen] = useState(false);
	const [searchCriteria, setSearchCriteria] = useState<IPropertySearchCriteria>(
		{
			rowPerPage: tableRowPerPage,
			pageNumber: 1,
			sortBy: "dealDate",
			sortDirection: "asc",
			text: "",
			areaId: 0,
			availableFromDate: dayjs(new Date().setDate(new Date().getDate() - 30)),
			availableToDate: dayjs(new Date()),
			bhkOfficeId: 0,
			buildingId: 0,
			furnitureStatusId: 0,
			propertyMinPrice: 0,
			propertyMaxPrice: 0,
			propertyFor: DealType.All,
			propertyStatusId: 0,
			propertyName: "",
		}
	);
	const [tableData, setTableData] = useState<any>([]);

	const [totalCount, setTotalCount] = useState(0);

	const handleOnChangeSearchCriteria = (value: any, name: string) => {
		setSearchCriteria({ ...searchCriteria, [name]: value });
	};
	const handleOnClearSearchCriteria = async () => {
		setSearchCriteria({
			...searchCriteria,
			sortBy: "dealDate",
			sortDirection: "asc",
			text: "",
			areaId: 0,
			availableFromDate: dayjs(new Date().setDate(new Date().getDate() - 30)),
			availableToDate: dayjs(new Date()),
			bhkOfficeId: 0,
			buildingId: 0,
			furnitureStatusId: 0,
			propertyMaxPrice: 0,
			propertyMinPrice: 0,
			propertyFor: DealType.All,
			propertyStatusId: 0,
			propertyName: "",
		});
	};

	const getAllRecords = async () => {
		const { data } = await getAllProperties(searchCriteria);
		if (data && data.status === HttpStatusCodes.Ok) {
			if (data.response.length > 0) setTableData(data.response);
			else setTableData([]);
		} else if (data && data.status === HttpStatusCodes.NotFound) {
			setTableData([]);
		} else {
			setTableData([]);
			toast.error(data.message);
		}
		setTotalCount(data.totalCount);
	};

	const handleOnClickSearch = async () => {
		if (searchCriteria.pageNumber === 1) await getAllRecords();
		else setSearchCriteria({ ...searchCriteria, pageNumber: 1 });
	};

	useEffect(() => {
		getAllRecords();
	}, [searchCriteria.rowPerPage | searchCriteria.pageNumber]);
	// }, [searchCriteria]);

	return (
		<>
			<div className="page-inner">
				<div className="page-heading-wrapper">
					<h4 className="page-heading">Property</h4>
					<Tooltip title="Refresh">
						<IconButton
							aria-label="refresh"
							color="primary"
							className="primary-icon"
							onClick={getAllRecords}
						>
							<Refresh />
						</IconButton>
					</Tooltip>
					<Tooltip title="Add  Property">
						<IconButton
							aria-label="add"
							color="primary"
							className="primary-icon"
							onClick={() => setIsAddNewRecordOpen(true)}
						>
							<Add />
						</IconButton>
					</Tooltip>
					<Tooltip title="Filter Data">
						<IconButton
							aria-label="filter"
							color="primary"
							className="primary-icon"
							onClick={() => setIsFilterOpen(!isFilterOpen)}
						>
							{isFilterOpen ? <Close /> : <FilterAltOutlined />}
						</IconButton>
					</Tooltip>
				</div>
				<PropertyFilter
					isOpen={isFilterOpen}
					values={searchCriteria}
					onChangeValue={handleOnChangeSearchCriteria}
					handleOnClickSearch={handleOnClickSearch}
					handleOnClearSearchCriteria={handleOnClearSearchCriteria}
				/>
				<div className="white-paper">
					<div className="table-heading-block">
						<TextField
							value={searchCriteria.text}
							onChange={(e) => {
								handleOnChangeSearchCriteria(e.target.value, "text");
							}}
							label="Search"
							variant="outlined"
						/>
					</div>
					<div className="table-negative-space">
						<TableContainer>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>For</TableCell>
										<TableCell>Building name</TableCell>
										<TableCell>Measurement</TableCell>
										<TableCell>Price</TableCell>
										<TableCell>Owner name</TableCell>
										<TableCell>Number</TableCell>
										<TableCell>Status</TableCell>
										<TableCell>Action</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{tableData.map((data: any) => {
										return (
											<PropertyRow
												data={data}
												getAllRecords={getAllRecords}
												key={data.id}
											/>
										);
									})}
								</TableBody>
								{tableData?.length <= 0 && <NoRecordFound />}
							</Table>
						</TableContainer>
						<CustomPagination
							handleOnChangeSearchCriteria={handleOnChangeSearchCriteria}
							pageNumber={searchCriteria.pageNumber}
							rowPerPage={searchCriteria.rowPerPage}
							totalRecords={totalCount}
						/>
					</div>
				</div>
			</div>
			<AddUpdateProperty
				dialogOpen={isAddNewRecordOpen}
				handleOnClickClose={() => setIsAddNewRecordOpen(false)}
				data={undefined}
				getAllRecords={getAllRecords}
			/>
		</>
	);
};
export default Property;
