import React, { useEffect, useState } from "react";
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { getRolePermission, updateRolePermission } from "../../../services";
import { HttpStatusCodes } from "../../../utility/enums";
import { toast } from "react-toastify";
import { appMenu } from "../../../utility/enums/common/app-menu";

type ComponentProps = {
	dialogOpen: boolean;
	handleOnClickClose: any;
	roleId: number;
};
export function ManageRoleDialog(props: ComponentProps) {
	interface IMenuType {
		menuId: number;
		name: string;
		isSelected: boolean;
	}

	const { dialogOpen, handleOnClickClose, roleId } = props;
	const [isSelectAll, setIsSelectAll] = useState(false);
	const [menuList, setMenuList] = useState<IMenuType[]>([
		{
			name: "Dashboard",
			isSelected: false,
			menuId: appMenu.dashboard,
		},
		{
			name: "Enquiry",
			isSelected: false,
			menuId: appMenu.enquiry,
		},
		{
			name: "Property",
			isSelected: false,
			menuId: appMenu.property,
		},
		{
			name: "Master -> Area",
			isSelected: false,
			menuId: appMenu.masterArea,
		},
		{
			name: "Master -> Property Type",
			isSelected: false,
			menuId: appMenu.masterPropertyType,
		},
		{
			name: "Master -> Bhk/Office",
			isSelected: false,
			menuId: appMenu.masterBhkOffice,
		},
		{
			name: "Master -> Building",
			isSelected: false,
			menuId: appMenu.masterBuilding,
		},
		{
			name: "Master -> Budget",
			isSelected: false,
			menuId: appMenu.masterBudget,
		},
		{
			name: "Master -> Enquiry Status",
			isSelected: false,
			menuId: appMenu.masterEnquiryStatus,
		},
		{
			name: "Master -> Furniture Status",
			isSelected: false,
			menuId: appMenu.masterFurnitureStatus,
		},
		{
			name: "Master -> Measurements",
			isSelected: false,
			menuId: appMenu.masterMeasurement,
		},
		{
			name: "Master -> Non-Use",
			isSelected: false,
			menuId: appMenu.masterNonUse,
		},
		{
			name: "Master -> Property Status",
			isSelected: false,
			menuId: appMenu.masterPropertyStatus,
		},
		{
			name: "Master -> Role",
			isSelected: false,
			menuId: appMenu.masterRole,
		},
		{
			name: "Master -> Segment",
			isSelected: false,
			menuId: appMenu.masterSegment,
		},
		{
			name: "Master -> Source",
			isSelected: false,
			menuId: appMenu.masterSource,
		},
		{
			name: "Master -> User",
			isSelected: false,
			menuId: appMenu.masterUser,
		},
		{
			name: "Master -> Parent activity",
			isSelected: false,
			menuId: appMenu.masterParentActivity,
		},
		{
			name: "Master -> Child activity",
			isSelected: false,
			menuId: appMenu.masterChildActivity,
		},
		{
			name: "Property Deal",
			isSelected: false,
			menuId: appMenu.propertyDeal,
		},
	]);

	const handleOnClickSubmit = async () => {
		const response = await updateRolePermission({
			roleId,
			menuIds: menuList.filter((x) => x.isSelected).map((y) => y.menuId),
		});
		if (response?.data?.status === HttpStatusCodes.Ok) {
			toast.success(response.data.message);
			handleOnClickClose();
		} else toast.error(response.data.message);
	};
	const setMenuPermission = (menuIds: number[]) => {
		if (menuIds.length > 0) {
			setMenuList(
				menuList.map((item) => {
					if (menuIds.includes(item.menuId)) item.isSelected = true;
					else item.isSelected = false;
					return item;
				})
			);
		} else {
			setMenuList(
				menuList.map((item) => {
					item.isSelected = false;
					return item;
				})
			);
		}
	};

	const getRoleMenuPermission = async () => {
		const { data } = await getRolePermission(roleId);
		if (data && data.status === HttpStatusCodes.Ok) {
			setMenuPermission(data.response.menuIds);
			return;
		} else if (data && data.status === HttpStatusCodes.NotFound) {
		} else toast.error(data.message);
		setMenuPermission([]);
	};

	const onSelectNewPermission = (menuId: number) => {
		setMenuList(
			menuList.map((item) => {
				if (item.menuId === menuId) item.isSelected = !item.isSelected;
				return item;
			})
		);
	};

	useEffect(() => {
		if (dialogOpen) getRoleMenuPermission();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dialogOpen]);

	useEffect(() => {
		if (dialogOpen) {
			const notSelectedMenu = menuList.filter((x) => !x.isSelected).length;
			if (notSelectedMenu > 0) {
				setIsSelectAll(false);
			} else {
				setIsSelectAll(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menuList]);

	return (
		<Dialog
			open={dialogOpen}
			onClose={handleOnClickClose}
			maxWidth="xs"
			fullWidth
		>
			<DialogTitle>
				Menu Permissions
				<IconButton aria-label="Close" onClick={handleOnClickClose}>
					<CloseOutlined />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<TableContainer>
					<Table aria-label="message table">
						<TableHead>
							<TableRow>
								<TableCell>Menu</TableCell>
								<TableCell>Permission</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>Select All</TableCell>
								<TableCell>
									<Checkbox
										value={0}
										checked={isSelectAll}
										onClick={() => {
											setIsSelectAll(!isSelectAll);
											menuList.map((item) => {
												item.isSelected = !isSelectAll;
												return item;
											});
										}}
									/>
								</TableCell>
							</TableRow>
							{menuList?.map((item: IMenuType) => {
								return (
									<TableRow>
										<TableCell>{item.name}</TableCell>
										<TableCell>
											<Checkbox
												value={item.menuId}
												checked={
													menuList.find((x) => x.menuId === item.menuId)
														?.isSelected
												}
												onClick={() => onSelectNewPermission(item.menuId)}
											/>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={handleOnClickSubmit}>
					Submit
				</Button>
				<Button variant="outlined" onClick={handleOnClickClose}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}
