import {
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
} from "@mui/material";
import { CustomDatePicker } from "../common/custom-date-picker";
import {
	IDropDownFields,
	IPropertySearchCriteria,
} from "../../utility/interfaces/common";
import {
	DealType,
	getDealTypeText,
} from "../../utility/enums/common/deal-type";
import { isInputOnlyNumber } from "../../utility/helpers/common";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { getAllCommonRecords } from "../../services";
import { APIRoutes } from "../../utility/app-routes";
import { HttpStatusCodes } from "../../utility/enums";

type ComponentProps = {
	isOpen: boolean;
	values: IPropertySearchCriteria;
	onChangeValue: any;
	handleOnClearSearchCriteria: any;
	handleOnClickSearch: any;
};
export function PropertyFilter(props: ComponentProps) {
	const {
		isOpen,
		values,
		onChangeValue,
		handleOnClearSearchCriteria,
		handleOnClickSearch,
	} = props;

	const [bhkOfficeList, setBhkOfficeList] = useState<IDropDownFields[]>([]);
	const [propertyTypeList, setPropertyTypeList] = useState<IDropDownFields[]>(
		[]
	);
	const [propertySourceList, setPropertySourceList] = useState<
		IDropDownFields[]
	>([]);
	const [propertyStatusList, setPropertyStatusList] = useState<
		IDropDownFields[]
	>([]);

	const [buildingList, setBuildingList] = useState<IDropDownFields[]>([]);
	const [areaList, setAreaList] = useState<IDropDownFields[]>([]);
	const [measurementList, setMeasurementList] = useState<IDropDownFields[]>([]);
	const [furnitureStatusList, setFurnitureStatusList] = useState<
		IDropDownFields[]
	>([]);
	const [segmentList, setSegmentList] = useState<IDropDownFields[]>([]);

	const getPageLoadRecords = async () => {
		const { data } = await getAllCommonRecords(APIRoutes.propertyOnPageLoad);
		if (data && data.status === HttpStatusCodes.Ok) {
			setPropertyTypeList(data.response.propertyTypeList);
			setSegmentList(data.response.segmentList);
			setBhkOfficeList(data.response.bhkOfficeList);
			setBuildingList(data.response.buildingList);
			setAreaList(data.response.areaList);
			setMeasurementList(data.response.measurementList);
			setFurnitureStatusList(data.response.furnitureStatusList);
			setPropertySourceList(data.response.sourceList);
			setPropertyStatusList(data.response.propertyStatusList);
		} else {
			toast.error(data.message);
		}
	};

	useEffect(() => {
		if (isOpen) {
			getPageLoadRecords();
		}
	}, [isOpen]);

	return (
		<>
			{isOpen ? (
				<div className="white-paper mb-20">
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<FormControl fullWidth>
								<InputLabel id="bhk-office-label">Bhk/Office</InputLabel>
								<Select
									labelId="bhk-office-label"
									id="bhkOffice"
									value={values.bhkOfficeId}
									label="Bhk/Office"
									onChange={(e) =>
										onChangeValue(+e.target.value, "bhkOfficeId")
									}
								>
									<MenuItem value="0">Select</MenuItem>
									{bhkOfficeList.map((record) => {
										return (
											<MenuItem
												key={record.id}
												disabled={!record.isActive}
												value={record.id}
											>
												{record.text}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<FormControl fullWidth>
								<InputLabel id="bhk-office-label">Building</InputLabel>
								<Select
									labelId="bhk-office-label"
									id="building"
									value={values.buildingId}
									label="Building"
									onChange={(e) => onChangeValue(+e.target.value, "buildingId")}
								>
									<MenuItem value="0">Select</MenuItem>
									{buildingList.map((record) => {
										return (
											<MenuItem
												key={record.id}
												disabled={!record.isActive}
												value={record.id}
											>
												{record.text}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<FormControl fullWidth>
								<InputLabel id="status-label">Status</InputLabel>
								<Select
									labelId="status-label"
									id="status"
									value={values.propertyStatusId}
									label="Status"
									onChange={(e) =>
										onChangeValue(+e.target.value, "propertyStatusId")
									}
								>
									<MenuItem value="0">Select</MenuItem>
									{propertyStatusList.map((record) => {
										return (
											<MenuItem
												key={record.id}
												disabled={!record.isActive}
												value={record.id}
											>
												{record.text}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<FormControl fullWidth>
								<InputLabel id="furnitureStatus-label">
									Furniture Status
								</InputLabel>
								<Select
									labelId="furnitureStatus-label"
									id="furnitureStatus-select"
									value={values.furnitureStatusId}
									label="Furniture Status"
									onChange={(e) =>
										onChangeValue(+e.target.value, "furnitureStatusId")
									}
								>
									<MenuItem value="0">Select</MenuItem>
									{furnitureStatusList.map((record) => {
										return (
											<MenuItem
												key={record.id}
												disabled={!record.isActive}
												value={record.id}
											>
												{record.text}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<FormControl fullWidth>
								<InputLabel id="bhk-office-label">Area</InputLabel>
								<Select
									labelId="bhk-office-label"
									id="area"
									value={values.areaId}
									label="Area"
									onChange={(e) => onChangeValue(+e.target.value, "areaId")}
								>
									<MenuItem value="0">Select</MenuItem>
									{areaList.map((record) => {
										return (
											<MenuItem
												key={record.id}
												disabled={!record.isActive}
												value={record.id}
											>
												{record.text}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<TextField
								id="min"
								label="Min Price"
								type="number"
								value={values.propertyMinPrice}
								fullWidth
								onChange={(e) => {
									if (isInputOnlyNumber(e.target.value)) {
										onChangeValue(+e.target.value, "propertyMinPrice");
									}
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<TextField
								id="max"
								label="Max Price"
								type="number"
								value={values.propertyMaxPrice}
								fullWidth
								onChange={(e) => {
									if (isInputOnlyNumber(e.target.value)) {
										onChangeValue(+e.target.value, "propertyMaxPrice");
									}
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={4}
							lg={3}
							className="datepicker-wrappper"
						>
							<CustomDatePicker
								disabled={false}
								label="Available from"
								onChangeValue={(newValue: any) =>
									onChangeValue(newValue, "availableFromDate")
								}
								value={values.availableFromDate}
								maxDate={values.availableToDate}
								minDate={new Date().setFullYear(new Date().getFullYear() - 100)}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={4}
							lg={3}
							className="datepicker-wrappper"
						>
							<CustomDatePicker
								disabled={false}
								label="Available to"
								onChangeValue={(newValue: any) =>
									onChangeValue(newValue, "availableToDate")
								}
								value={values.availableToDate}
								maxDate={new Date().setFullYear(new Date().getFullYear() + 100)}
								minDate={values.availableFromDate}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<FormControl className="grid-radio radio-control">
								<FormLabel id="demo-row-radio-buttons-group-label">
									For
								</FormLabel>
								<RadioGroup
									row
									aria-labelledby="demo-row-radio-buttons-group-label"
									name="row-radio-buttons-group"
									value={values.propertyFor}
								>
									<FormControlLabel
										value={DealType.All}
										control={<Radio />}
										label={getDealTypeText(DealType.All)}
										onClick={() => onChangeValue(DealType.All, "propertyFor")}
									/>
									<FormControlLabel
										value={DealType.Rent}
										control={<Radio />}
										label={getDealTypeText(DealType.Rent)}
										onClick={() => onChangeValue(DealType.Rent, "propertyFor")}
									/>
									<FormControlLabel
										value={DealType.Sell}
										control={<Radio />}
										label={getDealTypeText(DealType.Sell)}
										onClick={() => onChangeValue(DealType.Sell, "propertyFor")}
									/>
									<FormControlLabel
										value={DealType.Buy}
										control={<Radio />}
										label={getDealTypeText(DealType.Buy)}
										onClick={() => onChangeValue(DealType.Buy, "propertyFor")}
									/>
								</RadioGroup>
							</FormControl>
						</Grid>
					</Grid>
					<Grid
						container
						spacing={2}
						alignItems="center"
						justifyContent="end"
						sx={{ pt: { xs: "20px", sm: 0 } }}
					>
						<Grid item xs="auto">
							<Button
								style={{ marginRight: 10 }}
								variant="contained"
								onClick={handleOnClickSearch}
							>
								Search
							</Button>
							<Button variant="outlined" onClick={handleOnClearSearchCriteria}>
								Clear
							</Button>
						</Grid>
					</Grid>
				</div>
			) : null}
		</>
	);
}
