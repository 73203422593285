import { AxiosResponse } from "axios";
import httpClient from "./base-service";
import { IHttpsResponse } from "../utility/interfaces";
import { APIRoutes } from "../utility/app-routes";

export const getAllPropertyDeal = async (): Promise<
	AxiosResponse<IHttpsResponse<any>>
> => httpClient.get<IHttpsResponse<any>>(APIRoutes.getAllPropertyDeal);

export const addUpdatePropertyDeal = async (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		APIRoutes.addUpdatePropertyDeal,
		request
	);
