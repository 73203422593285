import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	Input,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Header from "../../../components/layouts/header";
import Sidebar from "../../../components/layouts/sidebar";
import {
	Add,
	AddCircleOutline,
	ArrowDownwardOutlined,
	ArrowUpwardOutlined,
	CloseOutlined,
	Delete,
	Edit,
	FileUploadOutlined,
	RemoveCircleOutline,
} from "@mui/icons-material";
import React from "react";

const User: React.FC = () => {
	// Table Pagination
	const [page, setPage] = React.useState(2);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// Dialog
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const dialogHandleClickOpen = () => {
		setDialogOpen(true);
	};
	const dialogHandleClose = () => {
		setDialogOpen(false);
	};

	const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
	const deleteDialogHandleClickOpen = () => {
		setDeleteDialogOpen(true);
	};
	const deleteDialogHandleClose = () => {
		setDeleteDialogOpen(false);
	};

	// Select
	const [selectActivity, setSelectActivity] = React.useState("");
	const selectHandleChange = (event: SelectChangeEvent) => {
		setSelectActivity(event.target.value as string);
	};

	// DatePicker
	const [value, setValue] = React.useState<Dayjs | null>(null);

	return (
		<div className="main-content">
			<Header />
			<div className="page-content">
				<Sidebar />
				<div className="page-inner">
					<div className="page-heading-wrapper">
						<h4 className="page-heading">User</h4>
						<IconButton
							aria-label="add"
							color="primary"
							className="primary-icon"
							onClick={dialogHandleClickOpen}
						>
							<Add />
						</IconButton>
					</div>
					<div className="white-paper">
						<div className="table-heading-block">
							<TextField label="Search" variant="outlined" />
						</div>
						<div className="table-negative-space">
							<TableContainer>
								<Table aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>
												Name
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowDownwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Email
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Mobile
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowDownwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Role
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowDownwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Actives
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowDownwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell className="action-col" align="center">
												Action
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell>Bhargav Rakholiya</TableCell>
											<TableCell>realviewrealty@gmail.com</TableCell>
											<TableCell>7600175255</TableCell>
											<TableCell>Admin</TableCell>
											<TableCell>
												<FormControlLabel
													control={<Checkbox defaultChecked />}
													label="Active"
												/>
											</TableCell>
											<TableCell className="action-col" align="center">
												<div className="button-wrapper">
													<IconButton
														aria-label="Edit"
														color="primary"
														onClick={dialogHandleClickOpen}
													>
														<Edit />
													</IconButton>
													<IconButton
														aria-label="Delete"
														color="primary"
														onClick={deleteDialogHandleClickOpen}
													>
														<Delete />
													</IconButton>
												</div>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Ravina Rakholiya</TableCell>
											<TableCell>ravinasudani@gmail.com</TableCell>
											<TableCell>7600185255</TableCell>
											<TableCell>Back Office</TableCell>
											<TableCell>
												<FormControlLabel
													control={<Checkbox defaultChecked />}
													label="Active"
												/>
											</TableCell>
											<TableCell className="action-col" align="center">
												<div className="button-wrapper">
													<IconButton
														aria-label="Edit"
														color="primary"
														onClick={dialogHandleClickOpen}
													>
														<Edit />
													</IconButton>
													<IconButton
														aria-label="Delete"
														color="primary"
														onClick={deleteDialogHandleClickOpen}
													>
														<Delete />
													</IconButton>
												</div>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Ravina Rakholiya</TableCell>
											<TableCell>ravinasudani@gmail.com</TableCell>
											<TableCell>7600185255</TableCell>
											<TableCell>Sales Team</TableCell>
											<TableCell>
												<FormControlLabel
													control={<Checkbox defaultChecked />}
													label="Active"
												/>
											</TableCell>
											<TableCell className="action-col" align="center">
												<div className="button-wrapper">
													<IconButton
														aria-label="Edit"
														color="primary"
														onClick={dialogHandleClickOpen}
													>
														<Edit />
													</IconButton>
													<IconButton
														aria-label="Delete"
														color="primary"
														onClick={deleteDialogHandleClickOpen}
													>
														<Delete />
													</IconButton>
												</div>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Ravina Rakholiya</TableCell>
											<TableCell>ravinasudani@gmail.com</TableCell>
											<TableCell>7600185255</TableCell>
											<TableCell>Admin</TableCell>
											<TableCell>
												<FormControlLabel
													control={<Checkbox defaultChecked />}
													label="Active"
												/>
											</TableCell>
											<TableCell className="action-col" align="center">
												<div className="button-wrapper">
													<IconButton
														aria-label="Edit"
														color="primary"
														onClick={dialogHandleClickOpen}
													>
														<Edit />
													</IconButton>
													<IconButton
														aria-label="Delete"
														color="primary"
														onClick={deleteDialogHandleClickOpen}
													>
														<Delete />
													</IconButton>
												</div>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</div>
						<div className="pagination-wrapper">
							<TablePagination
								component="div"
								count={100}
								page={page}
								onPageChange={handleChangePage}
								rowsPerPage={rowsPerPage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</div>
					</div>
				</div>

				{/* Add New Dialog */}
				<Dialog
					open={dialogOpen}
					onClose={dialogHandleClose}
					maxWidth="md"
					fullWidth
				>
					<DialogTitle>
						Add New User
						<IconButton aria-label="Close" onClick={dialogHandleClose}>
							<CloseOutlined />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<Grid container spacing={2} alignItems="center" className="mb-20">
							<Grid item xs={12} sm={6} md={4}>
								<TextField
									id="fname"
									label="First Name"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<TextField
									id="lname"
									label="Last Name"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<FormControl fullWidth>
									<InputLabel id="select-label">Role</InputLabel>
									<Select
										labelId="select-label"
										id="simple-select"
										value={selectActivity}
										label="Role"
										onChange={selectHandleChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<TextField
									id="mobile"
									label="Mobile"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<TextField
									id="email"
									label="Email"
									type="email"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<TextField
									id="password"
									label="Password"
									type="password"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<label htmlFor="contained-button-file" className="upload">
									<Input id="contained-button-file" type="file" />
									<Button
										variant="outlined"
										component="span"
										startIcon={<FileUploadOutlined />}
										className="w-full"
									>
										Upload Profile
									</Button>
								</label>
							</Grid>
							<Grid item xs={12} sm={6} md={4} className="datepicker-wrappper">
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DemoContainer components={["DatePicker"]}>
										<DatePicker
											value={value}
											className="w-full"
											label="Date Of Join"
											onChange={(newValue) => setValue(newValue)}
										/>
									</DemoContainer>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<FormControlLabel
									control={<Checkbox defaultChecked />}
									label="Active"
								/>
							</Grid>
						</Grid>
						<Typography className="section-heading" variant="h6">
							Other Credential
						</Typography>
						<TableContainer className="add-delete-row-table">
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Title</TableCell>
										<TableCell>User name</TableCell>
										<TableCell>Password</TableCell>
										<TableCell>
											<IconButton>
												<AddCircleOutline />
											</IconButton>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell>
											<TextField
												id="title"
												label="Title"
												type="text"
												fullWidth
												variant="outlined"
											/>
										</TableCell>
										<TableCell>
											<TextField
												id="username"
												label="Username"
												type="text"
												fullWidth
												variant="outlined"
											/>
										</TableCell>
										<TableCell>
											<TextField
												id="password"
												label="Password"
												type="text"
												fullWidth
												variant="outlined"
											/>
										</TableCell>
										<TableCell>
											<IconButton>
												<RemoveCircleOutline />
											</IconButton>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<TextField
												id="title"
												label="Title"
												type="text"
												fullWidth
												variant="outlined"
											/>
										</TableCell>
										<TableCell>
											<TextField
												id="username"
												label="Username"
												type="text"
												fullWidth
												variant="outlined"
											/>
										</TableCell>
										<TableCell>
											<TextField
												id="password"
												label="Password"
												type="text"
												fullWidth
												variant="outlined"
											/>
										</TableCell>
										<TableCell>
											<IconButton>
												<RemoveCircleOutline />
											</IconButton>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<TextField
												id="title"
												label="Title"
												type="text"
												fullWidth
												variant="outlined"
											/>
										</TableCell>
										<TableCell>
											<TextField
												id="username"
												label="Username"
												type="text"
												fullWidth
												variant="outlined"
											/>
										</TableCell>
										<TableCell>
											<TextField
												id="password"
												label="Password"
												type="text"
												fullWidth
												variant="outlined"
											/>
										</TableCell>
										<TableCell>
											<IconButton>
												<RemoveCircleOutline />
											</IconButton>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<TextField
												id="title"
												label="Title"
												type="text"
												fullWidth
												variant="outlined"
											/>
										</TableCell>
										<TableCell>
											<TextField
												id="username"
												label="Username"
												type="text"
												fullWidth
												variant="outlined"
											/>
										</TableCell>
										<TableCell>
											<TextField
												id="password"
												label="Password"
												type="text"
												fullWidth
												variant="outlined"
											/>
										</TableCell>
										<TableCell>
											<IconButton>
												<RemoveCircleOutline />
											</IconButton>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" onClick={dialogHandleClose}>
							Submit
						</Button>
						<Button variant="outlined" onClick={dialogHandleClose}>
							Cancel
						</Button>
					</DialogActions>
				</Dialog>

				{/* Delete Dialog */}
				<Dialog
					open={deleteDialogOpen}
					onClose={deleteDialogHandleClose}
					maxWidth="xs"
					fullWidth
				>
					<DialogTitle>
						Delete Confirmation
						<IconButton aria-label="Close" onClick={deleteDialogHandleClose}>
							<CloseOutlined />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure want to delete this recored?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="error"
							onClick={deleteDialogHandleClose}
						>
							Delete
						</Button>
						<Button variant="outlined" onClick={deleteDialogHandleClose}>
							Cancel
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		</div>
	);
};
export default User;
