import {
	Checkbox,
	FormControlLabel,
	IconButton,
	TableCell,
	TableRow,
	Tooltip,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useState } from "react";
import { DeleteCommonRecord } from "./delete-common-record";
import { APIRoutes } from "../../../utility/app-routes";
import { AddUpdateChildActivity } from "./add-update-child-activity";
type ComponentProps = {
	data: any;
	getAllRecords: any;
};
export function ChildActivityRow(props: ComponentProps) {
	const { data, getAllRecords } = props;
	const [isUpdateRecordOpen, setIsUpdateRecordOpen] = useState(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

	return (
		<>
			<TableRow key={data.id}>
				<TableCell>{data.name}</TableCell>
				<TableCell>{data.parentName}</TableCell>
				<TableCell>
					<FormControlLabel
						control={<Checkbox checked={data.isActive ? true : false} />}
						label={data.isActive ? "Active" : "Deactivate"}
					/>
				</TableCell>
				<TableCell className="action-col" align="center">
					<div className="button-wrapper">
						<Tooltip title="Edit Child Activity">
							<IconButton
								onClick={() => setIsUpdateRecordOpen(true)}
								aria-label="Edit"
								color="primary"
							>
								<Edit />
							</IconButton>
						</Tooltip>
						<Tooltip title="Delete Child Activity">
							<IconButton
								aria-label="Delete"
								onClick={() => setIsDeleteDialogOpen(true)}
								color="primary"
							>
								<Delete />
							</IconButton>
						</Tooltip>
					</div>
				</TableCell>
			</TableRow>
			{/* Update record  */}
			<AddUpdateChildActivity
				dialogOpen={isUpdateRecordOpen}
				handleOnClickClose={() => setIsUpdateRecordOpen(false)}
				data={data}
				getAllRecords={getAllRecords}
			/>
			<DeleteCommonRecord
				getAllRecords={getAllRecords}
				apiPath={`${APIRoutes.deleteChildActivity}/${data.id}`}
				dialogOpen={isDeleteDialogOpen}
				handleOnClickClose={() => setIsDeleteDialogOpen(false)}
			/>
		</>
	);
}
