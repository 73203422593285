import { MasterCommonRecordPage } from "../../../../components/common/common-master-record/master-common-record-page";
import { APIRoutes } from "../../../../utility/app-routes";

const ParentActivity: React.FC = () => {
	// Table Pagination

	return (
		<div className="page-inner">
			<>
				<MasterCommonRecordPage
					addUpdateApiUrl={APIRoutes.addUpdateParentActivity}
					deleteApiUrl={APIRoutes.deleteParentActivity}
					title="Parent Activity"
					getAllRecordApiUrl={APIRoutes.getAllParentActivities}
				/>
			</>
		</div>
	);
};
export default ParentActivity;
