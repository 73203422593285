import { CloseOutlined } from "@mui/icons-material";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { IDropDownFields } from "../../utility/interfaces";
import { CustomDatePicker } from "../common/custom-date-picker";
import dayjs from "dayjs";
import { number, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { HttpStatusCodes } from "../../utility/enums";
import { toast } from "react-toastify";
import { addEnquiryRemark } from "../../services";
import { IChildActivityDropDownFields } from "../../utility/interfaces/master";

type ComponentProps = {
	dialogOpen: boolean;
	handleOnClickClose: any;
	enquiryStatusList: IDropDownFields[];
	parentActivityList: IDropDownFields[];
	childActivityList: IChildActivityDropDownFields[];
	data: any;
	getAllRecords: any;
	getPageLoadData: any;
};
export function AddNewRemark(props: ComponentProps) {
	const {
		dialogOpen,
		handleOnClickClose,
		enquiryStatusList,
		parentActivityList,
		childActivityList,
		data,
		getAllRecords,
		getPageLoadData,
	} = props;

	const handleOnClickSubmit = async () => {
		const response = await addEnquiryRemark({
			enquiryId: data.id,
			activityChildId: childActivityId,
			nfd,
			enquiryStatusId,
			remark,
		});

		if (response?.data?.status === HttpStatusCodes.Ok) {
			toast.success(response.data.message);
			getAllRecords();
			handleOnClickClose();
		} else toast.error(response.data.message);
	};

	// Yup resolver
	const formValidationSchema = object({
		remark: string().required("Remark is required."),
		child: number().min(1, "Please select child activity."),
		status: number().min(1, "Please select enquiry status."),
		parent: number().min(1, "Please select parent activity."),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		resolver: yupResolver(formValidationSchema),
	});

	const [parentActivityId, setParentActivityId] = useState(0);
	const [childActivityId, setChildActivityId] = useState(0);
	const [enquiryStatusId, setEnquiryStatusId] = useState(0);
	const [remark, setRemark] = useState("");
	const [nfd, setNFD] = useState(dayjs(new Date()));
	useEffect(() => {
		if (dialogOpen) {
			getPageLoadData();
			setEnquiryStatusId(0);
			setRemark("");
			setParentActivityId(0);
			setChildActivityId(0);
			setNFD(dayjs(new Date()));
		}
		reset();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dialogOpen]);
	return (
		<Dialog
			open={dialogOpen}
			onClose={handleOnClickClose}
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle>
				<Typography variant="body1" className="d-flex align-item-center">
					<span className="d-block mx-4">{data.nameOfClient}</span>
					<span className="d-block mx-4" style={{ color: "white" }}>
						<a style={{ color: "white" }} href={`tel: +91 ${data.mobileNo}`}>
							{`(${data.mobileNo})`}
						</a>
					</span>
				</Typography>
				<IconButton aria-label="Close" onClick={handleOnClickClose}>
					<CloseOutlined />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<FormControl fullWidth>
							<InputLabel id="bhk-office-label">Parent Activity</InputLabel>
							<Select
								{...register("parent")}
								error={errors?.parent?.message ? true : false}
								labelId="bhk-office-label"
								id="parent"
								value={parentActivityId}
								label="Parent Activity"
								onChange={(e) => setParentActivityId(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{parentActivityList.map((record) => {
									return (
										<MenuItem
											key={record.id}
											disabled={!record.isActive}
											value={record.id}
										>
											{record.text}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.parent?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<FormControl fullWidth>
							<InputLabel id="bhk-office-label">Child Activity</InputLabel>
							<Select
								{...register("child")}
								error={errors?.child?.message ? true : false}
								labelId="bhk-office-label"
								id="child"
								value={childActivityId}
								label="Child Activity"
								onChange={(e) => setChildActivityId(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{childActivityList
									.filter((item) => item.activityParentId === parentActivityId)
									.map((record) => {
										return (
											<MenuItem
												key={record.id}
												disabled={!record.isActive}
												value={record.id}
											>
												{record.text}
											</MenuItem>
										);
									})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.child?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<FormControl fullWidth>
							<InputLabel id="bhk-office-label">Enquiry Status</InputLabel>
							<Select
								{...register("status")}
								error={errors?.status?.message ? true : false}
								labelId="bhk-office-label"
								id="status"
								value={enquiryStatusId}
								label="Enquiry Status"
								onChange={(e) => setEnquiryStatusId(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{enquiryStatusList.map((record) => {
									return (
										<MenuItem
											key={record.id}
											disabled={!record.isActive}
											value={record.id}
										>
											{record.text}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.status?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						lg={6}
						className="datepicker-wrappper"
					>
						<CustomDatePicker
							disabled={false}
							label="Next followup date"
							onChangeValue={(newValue: any) => setNFD(newValue)}
							value={nfd}
							maxDate={new Date().setFullYear(new Date().getFullYear() + 200)}
							minDate={new Date().setFullYear(new Date().getFullYear() - 200)}
						/>
					</Grid>
					<Grid item xs={12} lg={12}>
						<TextField
							{...register("remark")}
							error={errors?.remark?.message ? true : false}
							helperText={errors?.remark?.message?.toString()}
							id="remark"
							label="Remark"
							type="text"
							fullWidth
							multiline
							minRows={4}
							variant="outlined"
							value={remark}
							onChange={(e) => setRemark(e.target.value)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={handleSubmit(handleOnClickSubmit)}>
					Submit
				</Button>
				<Button variant="outlined" onClick={handleOnClickClose}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}
