import React, { useEffect } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import { deleteCommonRecord } from "../../../services";
import { HttpStatusCodes } from "../../../utility/enums";

type ComponentProps = {
	dialogOpen: boolean;
	handleOnClickClose: any;
	apiPath: string;
	getAllRecords: any;
};
export function DeleteCommonRecord(props: ComponentProps) {
	const { dialogOpen, handleOnClickClose, apiPath, getAllRecords } = props;
	const [currentApiPath, setCurrentApiPathApiPath] = React.useState("");

	const handleOnClickDelete = async () => {
		const response = await deleteCommonRecord(currentApiPath);
		if (response.data.status === HttpStatusCodes.Ok) {
			await getAllRecords();
			toast.success(response.data.message);
		} else toast.error(response.data.message);
		handleOnClickClose();
	};

	useEffect(() => {
		setCurrentApiPathApiPath(apiPath);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dialogOpen]);

	return (
		<Dialog
			open={dialogOpen}
			onClose={handleOnClickClose}
			maxWidth="xs"
			fullWidth
		>
			<DialogTitle>
				Delete Confirmation
				<IconButton aria-label="Close" onClick={handleOnClickClose}>
					<CloseOutlined />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Are you sure want to delete this record?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="error" onClick={handleOnClickDelete}>
					Delete
				</Button>
				<Button variant="outlined" onClick={handleOnClickClose}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}
