import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	SelectChangeEvent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
} from "@mui/material";
import { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Header from "../../../components/layouts/header";
import Sidebar from "../../../components/layouts/sidebar";
import {
	Add,
	ArrowDownwardOutlined,
	ArrowUpwardOutlined,
	CloseOutlined,
	FilterAltOutlined,
} from "@mui/icons-material";
import React, { useState } from "react";

const Property: React.FC = () => {
	// Table Pagination
	const [page, setPage] = React.useState(2);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// filterState
	const [filter, setFilter] = useState(false);

	// DatePicker
	const [value, setValue] = React.useState<Dayjs | null>(null);

	// Dialog
	const [dialogAddOpen, setDialogAddOpen] = React.useState(false);
	const dialogAddClickOpen = () => {
		setDialogAddOpen(true);
	};
	const dialogAddClose = () => {
		setDialogAddOpen(false);
	};

	// Select
	const [selectSource, setSelectSource] = React.useState("");
	const selectSourceChange = (event: SelectChangeEvent) => {
		setSelectSource(event.target.value as string);
	};

	const [selectPropertyType, setSelectPropertyType] = React.useState("");
	const selectPropertyTypeChange = (event: SelectChangeEvent) => {
		setSelectPropertyType(event.target.value as string);
	};

	const [selectSegment, setSelectSegment] = React.useState("");
	const selectSegmentChange = (event: SelectChangeEvent) => {
		setSelectSegment(event.target.value as string);
	};

	const [selectBhkOffice, setSelectBhkOffice] = React.useState("");
	const selectBhkOfficeChange = (event: SelectChangeEvent) => {
		setSelectBhkOffice(event.target.value as string);
	};

	const [selectMeasurement, setSelectMeasurement] = React.useState("");
	const selectMeasurementChange = (event: SelectChangeEvent) => {
		setSelectMeasurement(event.target.value as string);
	};

	const [selectFurnitureStatus, setSelectFurnitureStatus] = React.useState("");
	const selectFurnitureStatusChange = (event: SelectChangeEvent) => {
		setSelectFurnitureStatus(event.target.value as string);
	};

	const [selectEmployee, setSelectEmployee] = React.useState("");
	const selectEmployeeChange = (event: SelectChangeEvent) => {
		setSelectEmployee(event.target.value as string);
	};

	const [selectNonUse, setSelectNonUse] = React.useState("");
	const selectNonUseChange = (event: SelectChangeEvent) => {
		setSelectNonUse(event.target.value as string);
	};

	const [selectCrore, setSelectCrore] = React.useState("");
	const selectCroreChange = (event: SelectChangeEvent) => {
		setSelectCrore(event.target.value as string);
	};

	const [selectLakhs, setSelectLakhs] = React.useState("");
	const selectLakhsChange = (event: SelectChangeEvent) => {
		setSelectLakhs(event.target.value as string);
	};

	const [selectThousand, setSelectThousand] = React.useState("");
	const selectThousandChange = (event: SelectChangeEvent) => {
		setSelectThousand(event.target.value as string);
	};

	const [selectHundreds, setSelectHundreds] = React.useState("");
	const selectHundredsChange = (event: SelectChangeEvent) => {
		setSelectHundreds(event.target.value as string);
	};

	const [selectStatus, setSelectStatus] = React.useState("");
	const selectStatusChange = (event: SelectChangeEvent) => {
		setSelectStatus(event.target.value as string);
	};

	const [selectFurniture, setSelectFurniture] = React.useState("");
	const selectFurnitureChange = (event: SelectChangeEvent) => {
		setSelectFurniture(event.target.value as string);
	};

	const [selectBuilding, setSelectBuilding] = React.useState("");
	const selectBuildingChange = (event: SelectChangeEvent) => {
		setSelectBuilding(event.target.value as string);
	};
	return (
		<div className="main-content">
			<Header />
			<div className="page-content">
				<Sidebar />
				<div className="page-inner">
					<div className="page-heading-wrapper">
						<h4 className="page-heading">Property</h4>
						<IconButton
							aria-label="add"
							color="primary"
							className="primary-icon"
							onClick={dialogAddClickOpen}
						>
							<Add />
						</IconButton>
						<IconButton
							aria-label="filter"
							color="primary"
							className="primary-icon"
							onClick={() => setFilter(!filter)}
						>
							<FilterAltOutlined />
						</IconButton>
					</div>
					{filter && (
						<div className="white-paper mb-20">
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<FormControl className="grid-radio radio-control">
										<FormLabel id="radio-label">For</FormLabel>
										<RadioGroup
											row
											aria-labelledby="radio-label"
											name="row-radio-buttons-group"
										>
											<FormControlLabel
												value="rent"
												control={<Radio />}
												label="Rent"
											/>
											<FormControlLabel
												value="buy"
												control={<Radio />}
												label="Buy"
											/>
										</RadioGroup>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<FormControl fullWidth>
										<InputLabel id="select-label">Property Type</InputLabel>
										<Select
											labelId="select-label"
											id="simple-select"
											value={selectPropertyType}
											label="Property Type"
											onChange={selectPropertyTypeChange}
										>
											<MenuItem value="PhoneCall">Phone Call</MenuItem>
											<MenuItem value="visit">Visit</MenuItem>
											<MenuItem value="meeting">Meeting</MenuItem>
											<MenuItem value="deal">Deal</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<FormControl fullWidth>
										<InputLabel id="segment-label">Segment</InputLabel>
										<Select
											labelId="segment-label"
											id="segment-select"
											value={selectSegment}
											label="Segment"
											onChange={selectSegmentChange}
										>
											<MenuItem value="PhoneCall">Phone Call</MenuItem>
											<MenuItem value="visit">Visit</MenuItem>
											<MenuItem value="meeting">Meeting</MenuItem>
											<MenuItem value="deal">Deal</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<FormControl fullWidth>
										<InputLabel id="bhk-office-label">Bhk/Office</InputLabel>
										<Select
											labelId="bhk-office-label"
											id="bhk-office-select"
											value={selectBhkOffice}
											label="Bhk/Office"
											onChange={selectBhkOfficeChange}
										>
											<MenuItem value="PhoneCall">Phone Call</MenuItem>
											<MenuItem value="visit">Visit</MenuItem>
											<MenuItem value="meeting">Meeting</MenuItem>
											<MenuItem value="deal">Deal</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<FormControl fullWidth>
										<InputLabel id="building-label">Budget</InputLabel>
										<Select
											labelId="building-label"
											id="building-select"
											value={selectBuilding}
											label="Building"
											onChange={selectBuildingChange}
										>
											<MenuItem value="PhoneCall">Phone Call</MenuItem>
											<MenuItem value="visit">Visit</MenuItem>
											<MenuItem value="meeting">Meeting</MenuItem>
											<MenuItem value="deal">Deal</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<FormControl fullWidth className="mb-8">
										<InputLabel id="source-label">Source</InputLabel>
										<Select
											labelId="source-label"
											id="source-select"
											value={selectSource}
											label="Source"
											onChange={selectSourceChange}
										>
											<MenuItem value="PhoneCall">Phone Call</MenuItem>
											<MenuItem value="visit">Visit</MenuItem>
											<MenuItem value="meeting">Meeting</MenuItem>
											<MenuItem value="deal">Deal</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextField
										id="min"
										label="Min"
										type="text"
										fullWidth
										variant="outlined"
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextField
										id="max"
										label="Max"
										type="text"
										fullWidth
										variant="outlined"
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={4}
									lg={3}
									className="datepicker-wrappper"
								>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DemoContainer components={["DatePicker"]}>
											<DatePicker
												value={value}
												className="w-full"
												label="Start Date"
												onChange={(newValue) => setValue(newValue)}
											/>
										</DemoContainer>
									</LocalizationProvider>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={4}
									lg={3}
									className="datepicker-wrappper"
								>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DemoContainer components={["DatePicker"]}>
											<DatePicker
												value={value}
												className="w-full"
												label="End Date"
												onChange={(newValue) => setValue(newValue)}
											/>
										</DemoContainer>
									</LocalizationProvider>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<FormControl fullWidth>
										<InputLabel id="status-label">Status</InputLabel>
										<Select
											labelId="status-label"
											id="status-select"
											value={selectStatus}
											label="Status"
											onChange={selectStatusChange}
										>
											<MenuItem value="PhoneCall">Phone Call</MenuItem>
											<MenuItem value="visit">Visit</MenuItem>
											<MenuItem value="meeting">Meeting</MenuItem>
											<MenuItem value="deal">Deal</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<FormControl fullWidth>
										<InputLabel id="non-use-label">Non Use</InputLabel>
										<Select
											labelId="non-use-label"
											id="non-use-select"
											value={selectNonUse}
											label="Non Use"
											onChange={selectNonUseChange}
										>
											<MenuItem value="PhoneCall">Phone Call</MenuItem>
											<MenuItem value="visit">Visit</MenuItem>
											<MenuItem value="meeting">Meeting</MenuItem>
											<MenuItem value="deal">Deal</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<FormControl fullWidth>
										<InputLabel id="employee-label">Employee</InputLabel>
										<Select
											labelId="employee-label"
											id="employee-select"
											value={selectEmployee}
											label="Employee"
											onChange={selectEmployeeChange}
										>
											<MenuItem value="PhoneCall">Phone Call</MenuItem>
											<MenuItem value="visit">Visit</MenuItem>
											<MenuItem value="meeting">Meeting</MenuItem>
											<MenuItem value="deal">Deal</MenuItem>
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							<Grid
								container
								spacing={2}
								alignItems="center"
								justifyContent="end"
								sx={{ pt: { xs: "20px", sm: 0 } }}
							>
								<Grid item xs="auto">
									<Button variant="contained" onClick={() => setFilter(false)}>
										Filter
									</Button>
								</Grid>
								<Grid item xs="auto">
									<Button variant="outlined" onClick={() => setFilter(false)}>
										Cancel
									</Button>
								</Grid>
							</Grid>
						</div>
					)}
					<div className="white-paper">
						<div className="table-heading-block">
							<TextField label="Search" variant="outlined" />
						</div>
						<div className="table-negative-space">
							<TableContainer>
								<Table aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>
												Sr.No.
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowDownwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												For
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Building name
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Segment
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Bhk
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Mesurement
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Price
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Name
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Number
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Satuts
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												V/D
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell
												colSpan={11}
												align="center"
												className="no-data"
											>
												No Data found
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
							<div className="pagination-wrapper">
								<TablePagination
									component="div"
									count={100}
									page={page}
									onPageChange={handleChangePage}
									rowsPerPage={rowsPerPage}
									onRowsPerPageChange={handleChangeRowsPerPage}
								/>
							</div>
						</div>
					</div>
				</div>
				{/* Add New Dialog */}
				<Dialog
					open={dialogAddOpen}
					onClose={dialogAddClose}
					maxWidth="lg"
					fullWidth
				>
					<DialogTitle>
						Add New Property
						<IconButton aria-label="Close" onClick={dialogAddClose}>
							<CloseOutlined />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl className="grid-radio radio-control">
									<FormLabel id="demo-row-radio-buttons-group-label">
										For
									</FormLabel>
									<RadioGroup
										row
										aria-labelledby="demo-row-radio-buttons-group-label"
										name="row-radio-buttons-group"
									>
										<FormControlLabel
											value="rent"
											control={<Radio />}
											label="Rent"
										/>
										<FormControlLabel
											value="buy"
											control={<Radio />}
											label="Buy"
										/>
									</RadioGroup>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth>
									<InputLabel id="select-label">Property Type</InputLabel>
									<Select
										labelId="select-label"
										id="simple-select"
										value={selectPropertyType}
										label="Property Type"
										onChange={selectPropertyTypeChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth>
									<InputLabel id="segment-label">Segment</InputLabel>
									<Select
										labelId="segment-label"
										id="segment-select"
										value={selectSegment}
										label="Segment"
										onChange={selectSegmentChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth>
									<InputLabel id="bhk-office-label">Bhk/Office</InputLabel>
									<Select
										labelId="bhk-office-label"
										id="bhk-office-select"
										value={selectBhkOffice}
										label="Bhk/Office"
										onChange={selectBhkOfficeChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="building"
									label="Building"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="area"
									label="Area"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} md={8} lg={6}>
								<TextField
									id="address"
									label="Address"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="block"
									label="Block"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="faltNumber"
									label="Flat Number"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth className="mb-8">
									<InputLabel id="measurement-label">Measurement</InputLabel>
									<Select
										labelId="measurement-label"
										id="measurement-select"
										value={selectMeasurement}
										label="Measurement"
										onChange={selectMeasurementChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="size"
									label="Size"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="carpetArea"
									label="Carpet Area"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="builtup"
									label="Builtup / Construction"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth>
									<InputLabel id="furnitureStatus-label">
										Furniture Status
									</InputLabel>
									<Select
										labelId="furnitureStatus-label"
										id="furnitureStatus-select"
										value={selectFurnitureStatus}
										label="Furniture Status"
										onChange={selectFurnitureStatusChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="parking"
									label="Parking"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} md={8} lg={6}>
								<TextField
									id="keyStatus"
									label="Key Status"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth className="mb-8">
									<InputLabel id="crore-label">Crore</InputLabel>
									<Select
										labelId="crore-label"
										id="crore-select"
										value={selectCrore}
										label="Crore"
										onChange={selectCroreChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth className="mb-8">
									<InputLabel id="lakhs-label">Lakhs</InputLabel>
									<Select
										labelId="lakhs-label"
										id="lakhs-select"
										value={selectLakhs}
										label="Lakhs"
										onChange={selectLakhsChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth className="mb-8">
									<InputLabel id="thousand-label">Thousand</InputLabel>
									<Select
										labelId="thousand-label"
										id="thousand-select"
										value={selectThousand}
										label="Thousand"
										onChange={selectThousandChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth className="mb-8">
									<InputLabel id="hundreds-label">Hundreds</InputLabel>
									<Select
										labelId="hundreds-label"
										id="hundreds-select"
										value={selectHundreds}
										label="Hundreds"
										onChange={selectHundredsChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="name"
									label="Name"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="mobileNo"
									label="Mobile no"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth className="mb-8">
									<InputLabel id="source-label">Source</InputLabel>
									<Select
										labelId="source-label"
										id="source-select"
										value={selectSource}
										label="Source"
										onChange={selectSourceChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth>
									<InputLabel id="status-label">Status</InputLabel>
									<Select
										labelId="status-label"
										id="status-select"
										value={selectStatus}
										label="Status"
										onChange={selectStatusChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} lg={6}>
								<TextField
									id="comission"
									label="Comission"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} lg={6}>
								<TextField
									id="remark"
									label="Remark"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="phone-1"
									label="Phone Number 1"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="phone-2"
									label="Phone Number 2"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="phone-3"
									label="Phone Number 3"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="phone-4"
									label="Phone Number 4"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" onClick={dialogAddClose}>
							Submit
						</Button>
						<Button variant="outlined" onClick={dialogAddClose}>
							Cancel
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		</div>
	);
};
export default Property;
