import "../common/loader-style.css";
import { logo } from "../../assets/images";

export function Loader() {
	return (
		<div className="loader-wrapper">
			<div className="loader-inner">
				<div className="lds-spinner">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				{/* <p className="lds-message">Loading...</p> */}
			</div>
		</div>
	);
}

export default Loader;
