import React, { useEffect } from "react";
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import { HttpStatusCodes } from "../../../utility/enums";
import { IDropDownFields } from "../../../utility/interfaces";
import {
	addNewBudget,
	getAllCommonRecords,
	updateBudget,
} from "../../../services";
import { DealType } from "../../../utility/enums/common/deal-type";
import { number, object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { isInputOnlyNumber } from "../../../utility/helpers/common";
import { APIRoutes } from "../../../utility/app-routes";

type ComponentProps = {
	dialogOpen: boolean;
	handleOnClickClose: any;
	getAllRecords: any;
	data: any | undefined;
};
export function AddUpdateBudget(props: ComponentProps) {
	const { dialogOpen, handleOnClickClose, data, getAllRecords } = props;
	const [id, setId] = React.useState(0);
	const [isActive, setIsActive] = React.useState(true);
	const [name, setName] = React.useState("");
	const [from, setFrom] = React.useState(0);
	const [to, setTo] = React.useState(0);
	const [forValue, setForValue] = React.useState(DealType.Rent);
	const [segmentId, setSegmentId] = React.useState(0);
	const [bhkOfficeId, setBhkOfficeId] = React.useState(0);
	const [hePropertyTypeId, setHePropertyTypeId] = React.useState(0);
	const [bhkOfficeList, setBhkOfficeList] = React.useState<IDropDownFields[]>(
		[]
	);

	const formValidationSchema = object({
		name: string().required("Name is required"),
		fromValue: number().min(1, "From value must be grater then 0."),
		toValue: number().min(1, "To value must be grater then 0 and from value."),
		bhkOffice: number().min(1, "Please select bhk office."),
	});

	useEffect(() => {
		setValue("name", name);
		setValue("fromValue", from);
		setValue("toValue", to);
		setValue("bhkOffice", bhkOfficeId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name]);

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm({
		resolver: yupResolver(formValidationSchema),
	});

	const handleOnClickSubmit = async () => {
		let response = null;
		if (id === null || id === 0)
			response = await addNewBudget(
				isActive,
				name,
				from,
				to,
				forValue,
				segmentId,
				bhkOfficeId,
				hePropertyTypeId
			);
		else
			response = await updateBudget(
				isActive,
				name,
				from,
				to,
				forValue,
				segmentId,
				bhkOfficeId,
				hePropertyTypeId,
				id
			);
		if (response?.data?.status === HttpStatusCodes.Ok) {
			await getAllRecords();
			toast.success(response.data.message);
			handleOnClickClose();
		} else toast.error(response.data.message);
	};

	const getPageLoadData = async () => {
		const { data } = await getAllCommonRecords(APIRoutes.getBudgetPageLoad);
		if (data && data.status === HttpStatusCodes.Ok) {
			setBhkOfficeList(data.response?.bhkOfficeList);
		} else {
			setBhkOfficeList([]);
			toast.error(data.message);
		}
	};

	// useEffects
	useEffect(() => {
		reset();
		if (dialogOpen) {
			getPageLoadData();
			setName(data?.name || "");
			setIsActive(data ? data.isActive : true);
			setId(data?.id || 0);
			setFrom(data?.from || 0);
			setTo(data?.to || 0);
			setForValue(data?.forValue || DealType.Rent);
			setSegmentId(data?.segmentId || 0);
			setBhkOfficeId(data?.bhkOfficeId || 0);
			setHePropertyTypeId(data?.hePropertyTypeId || 0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dialogOpen]);

	return (
		<Dialog
			open={dialogOpen}
			onClose={handleOnClickClose}
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle>
				Budget
				<IconButton aria-label="Close" onClick={handleOnClickClose}>
					<CloseOutlined />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={12} sm={6}>
						<FormControl className="grid-radio radio-control">
							<FormLabel id="demo-row-radio-buttons-group-label">For</FormLabel>
							<RadioGroup
								row
								aria-labelledby="demo-row-radio-buttons-group-label"
								name="row-radio-buttons-group"
								value={forValue}
							>
								<FormControlLabel
									value={DealType.Rent}
									control={<Radio />}
									label="Rent"
									onClick={() => setForValue(DealType.Rent)}
								/>
								<FormControlLabel
									onClick={() => setForValue(DealType.Buy)}
									value={DealType.Buy}
									control={<Radio />}
									label="Buy"
								/>
								<FormControlLabel
									onClick={() => setForValue(DealType.Sell)}
									value={DealType.Sell}
									control={<Radio />}
									label="Sell"
								/>
							</RadioGroup>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="name"
							{...register("name")}
							error={errors?.name?.message ? true : false}
							helperText={errors?.name?.message?.toString()}
							label="Name"
							type="text"
							value={name}
							onChange={(e) => {
								setName(e.target.value);
							}}
							fullWidth
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="from"
							label="From"
							type="text"
							{...register("fromValue")}
							error={errors?.fromValue?.message ? true : false}
							helperText={errors?.fromValue?.message?.toString()}
							fullWidth
							value={from}
							onChange={(e) => {
								if (isInputOnlyNumber(e.target.value)) setFrom(+e.target.value);
							}}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="to"
							{...register("toValue")}
							error={errors?.toValue?.message ? true : false}
							helperText={errors?.toValue?.message?.toString()}
							label="To"
							type="text"
							value={to}
							onChange={(e) => {
								if (isInputOnlyNumber(e.target.value)) setTo(+e.target.value);
							}}
							fullWidth
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControl fullWidth>
							<InputLabel id="bhk-office-label">Bhk/Office:</InputLabel>
							<Select
								{...register("bhkOffice")}
								error={errors?.bhkOffice?.message ? true : false}
								labelId="bhk-office-label"
								id="bhk-office-select"
								value={bhkOfficeId}
								label="Bhk/Office:"
								onChange={(e) => setBhkOfficeId(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{bhkOfficeList?.map((record, index) => {
									return (
										<MenuItem
											key={index}
											disabled={!record.isActive}
											value={record.id}
										>
											{record.text}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.bhkOffice?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormControlLabel
							control={
								<Checkbox
									checked={isActive}
									onClick={() => setIsActive(!isActive)}
								/>
							}
							label="Active"
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={handleSubmit(handleOnClickSubmit)}>
					Submit
				</Button>
				<Button variant="outlined" onClick={handleOnClickClose}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}
