import {
	DashboardOutlined,
	FormatListBulletedOutlined,
	HandshakeOutlined,
	HouseOutlined,
	ListAltOutlined,
} from "@mui/icons-material";
import { Typography, Link } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { appMenu } from "../../../utility/enums/common/app-menu";
import {
	getToken,
	getUserDetails,
} from "../../../utility/auth-guard/token-manager";
import { AppRoutes } from "../../../utility/app-routes";
import { toast } from "react-toastify";
import { getUserProfile } from "../../../services";
import { HttpStatusCodes } from "../../../utility/enums";
import { UserDispatchContext } from "../../../contexts/user-context";

const Sidebar: React.FC = () => {
	const [isMasterOpen, setIsMasterOpen] = React.useState(false);
	const [menuPermissions, setMenuPermissions] = React.useState<number[]>([]);

	const navigate = useNavigate();
	const handleOnClickMenu = (url: string) => {
		navigate(url);
		document.body.classList.remove("show-mobile-menu");
	};
	const setUserContext = useContext(UserDispatchContext);
	const isActiveMenu = (menuName: string) => {
		const currentUrl = window.location.pathname;
		return currentUrl === menuName;
	};
	const getUserProfileDetails = async () => {
		const { data } = await getUserProfile();
		if (data && data.status === HttpStatusCodes.Ok) {
			setMenuPermissions(data.response.menuIds);
			const userDetails = getUserDetails();
			if (userDetails) {
				userDetails.UserPermission = data.response.menuIds;
				userDetails.ProfileImage = data.response.profileImage;
				setUserContext(userDetails);
			}
		} else {
			setMenuPermissions([]);
			toast.error(data.message);
		}
	};
	const checkPermission = (menuId: number) => {
		return menuPermissions.includes(menuId);
	};
	useEffect(() => {
		if (getToken()) getUserProfileDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ul className="sidebar">
			{checkPermission(appMenu.dashboard) && (
				<li>
					<Link
						onClick={() => {
							handleOnClickMenu(AppRoutes.dashboard);
						}}
						title="Dashboard"
						style={{ cursor: "pointer" }}
						className={isActiveMenu(AppRoutes.dashboard) ? "active" : ""}
					>
						<DashboardOutlined className="icon" />
						<Typography variant="body1">Dashboard</Typography>
					</Link>
				</li>
			)}
			{checkPermission(appMenu.masterArea) ||
			checkPermission(appMenu.masterBhkOffice) ||
			checkPermission(appMenu.masterBudget) ||
			checkPermission(appMenu.masterBuilding) ||
			// checkPermission(appMenu.masterDraftReason) ||
			checkPermission(appMenu.masterEnquiryStatus) ||
			checkPermission(appMenu.masterFurnitureStatus) ||
			checkPermission(appMenu.masterMeasurement) ||
			// checkPermission(appMenu.masterMinMax) ||
			checkPermission(appMenu.masterNonUse) ||
			// checkPermission(appMenu.masterPrice) ||
			checkPermission(appMenu.masterPropertyStatus) ||
			// checkPermission(appMenu.masterPurpose) ||
			checkPermission(appMenu.masterRole) ||
			checkPermission(appMenu.masterSegment) ||
			checkPermission(appMenu.masterSource) ||
			checkPermission(appMenu.masterUser) ||
			checkPermission(appMenu.masterParentActivity) ||
			checkPermission(appMenu.masterChildActivity) ||
			checkPermission(appMenu.masterPropertyType) ? (
				<li className={"has-menu " + (isMasterOpen ? "open " : "")}>
					<Link
						style={{ cursor: "pointer" }}
						title="Master"
						onClick={(e) => {
							e.preventDefault();
							setIsMasterOpen(!isMasterOpen);
						}}
						className={
							window.location.pathname.includes("master") ? "active" : ""
						}
					>
						<ListAltOutlined className="icon" />
						<Typography variant="body1">Master</Typography>
					</Link>
					<ul>
						<li>
							{checkPermission(appMenu.masterArea) && (
								<Link
									onClick={() => {
										handleOnClickMenu(AppRoutes.masterArea);
									}}
									style={{ cursor: "pointer" }}
									className={isActiveMenu(AppRoutes.masterArea) ? "active" : ""}
									title="Area"
								>
									Area
								</Link>
							)}
							{checkPermission(appMenu.masterPropertyType) && (
								<Link
									onClick={() => {
										handleOnClickMenu(AppRoutes.masterPropertyType);
									}}
									style={{ cursor: "pointer" }}
									className={
										isActiveMenu(AppRoutes.masterPropertyType) ? "active" : ""
									}
									title="Property Type"
								>
									Property Type
								</Link>
							)}
							{checkPermission(appMenu.masterSegment) && (
								<Link
									onClick={() => {
										handleOnClickMenu(AppRoutes.masterSegment);
									}}
									style={{ cursor: "pointer" }}
									className={
										isActiveMenu(AppRoutes.masterSegment) ? "active" : ""
									}
									title="Segment"
								>
									Segment
								</Link>
							)}
							{checkPermission(appMenu.masterBhkOffice) && (
								<Link
									onClick={() => {
										handleOnClickMenu(AppRoutes.masterBhkOffice);
									}}
									style={{ cursor: "pointer" }}
									className={
										isActiveMenu(AppRoutes.masterBhkOffice) ? "active" : ""
									}
									title="Bhk/Office"
								>
									Bhk/Office
								</Link>
							)}
							{checkPermission(appMenu.masterBudget) && (
								<Link
									onClick={() => {
										handleOnClickMenu(AppRoutes.masterBudget);
									}}
									style={{ cursor: "pointer" }}
									className={
										isActiveMenu(AppRoutes.masterBudget) ? "active" : ""
									}
									title="Budget"
								>
									Budget
								</Link>
							)}
							{checkPermission(appMenu.masterBuilding) && (
								<Link
									onClick={() => {
										handleOnClickMenu(AppRoutes.masterBuilding);
									}}
									style={{ cursor: "pointer" }}
									className={
										isActiveMenu(AppRoutes.masterBuilding) ? "active" : ""
									}
									title="Building"
								>
									Building
								</Link>
							)}
							{/* {checkPermission(appMenu.masterDraftReason) && (
								<Link
									onClick={() => {
										handleOnClickMenu(AppRoutes.masterDraftReason);
									}}
									style={{ cursor: "pointer" }}
									className={
										isActiveMenu(AppRoutes.masterDraftReason) ? "active" : ""
									}
									title="Draft Reason"
								>
									Draft Reason
								</Link>
							)} */}
							{checkPermission(appMenu.masterEnquiryStatus) && (
								<Link
									onClick={() => {
										handleOnClickMenu(AppRoutes.masterEnquiryStatus);
									}}
									style={{ cursor: "pointer" }}
									className={
										isActiveMenu(AppRoutes.masterEnquiryStatus) ? "active" : ""
									}
									title="Enquiry Status"
								>
									Enquiry Status
								</Link>
							)}
							{checkPermission(appMenu.masterParentActivity) && (
								<Link
									onClick={() => {
										handleOnClickMenu(AppRoutes.masterParentActivity);
									}}
									style={{ cursor: "pointer" }}
									className={
										isActiveMenu(AppRoutes.masterParentActivity) ? "active" : ""
									}
									title="Parent Activity"
								>
									Parent Activity
								</Link>
							)}
							{checkPermission(appMenu.masterChildActivity) && (
								<Link
									onClick={() => {
										handleOnClickMenu(AppRoutes.masterChildActivity);
									}}
									style={{ cursor: "pointer" }}
									className={
										isActiveMenu(AppRoutes.masterChildActivity) ? "active" : ""
									}
									title="Child Activity"
								>
									Child Activity
								</Link>
							)}
							{checkPermission(appMenu.masterFurnitureStatus) && (
								<Link
									onClick={() => {
										handleOnClickMenu(AppRoutes.masterFurnitureStatus);
									}}
									style={{ cursor: "pointer" }}
									className={
										isActiveMenu(AppRoutes.masterFurnitureStatus)
											? "active"
											: ""
									}
									title="Furniture Status"
								>
									Furniture Status
								</Link>
							)}
							{checkPermission(appMenu.masterMeasurement) && (
								<Link
									onClick={() => {
										handleOnClickMenu(AppRoutes.masterMeasurement);
									}}
									style={{ cursor: "pointer" }}
									className={
										isActiveMenu(AppRoutes.masterMeasurement) ? "active" : ""
									}
									title="Measurement"
								>
									Measurement
								</Link>
							)}
							{/* {checkPermission(appMenu.masterMinMax) && (
								<Link
									onClick={() => {
										handleOnClickMenu(AppRoutes.masterMinMax);
									}}
									style={{ cursor: "pointer" }}
									className={
										isActiveMenu(AppRoutes.masterMinMax) ? "active" : ""
									}
									title="Min-Max"
								>
									Min-Max
								</Link>
							)} */}
							{checkPermission(appMenu.masterNonUse) && (
								<Link
									onClick={() => {
										handleOnClickMenu(AppRoutes.masterNonUse);
									}}
									style={{ cursor: "pointer" }}
									className={
										isActiveMenu(AppRoutes.masterNonUse) ? "active" : ""
									}
									title="Non-Use"
								>
									Non-Use
								</Link>
							)}
							{/* {checkPermission(appMenu.masterPrice) && (
								<Link
									onClick={() => {
										handleOnClickMenu(AppRoutes.masterPrice);
									}}
									style={{ cursor: "pointer" }}
									className={
										isActiveMenu(AppRoutes.masterPrice) ? "active" : ""
									}
									title="Price"
								>
									Price
								</Link>
							)} */}
							{checkPermission(appMenu.masterPropertyStatus) && (
								<Link
									onClick={() => {
										handleOnClickMenu(AppRoutes.masterPropertyStatus);
									}}
									style={{ cursor: "pointer" }}
									className={
										isActiveMenu(AppRoutes.masterPropertyStatus) ? "active" : ""
									}
									title="Property Status"
								>
									Property Status
								</Link>
							)}
							{/* {checkPermission(appMenu.masterPurpose) && (
								<Link
									onClick={() => {
										handleOnClickMenu(AppRoutes.masterPurpose);
									}}
									style={{ cursor: "pointer" }}
									className={
										isActiveMenu(AppRoutes.masterPurpose) ? "active" : ""
									}
									title="Purpose"
								>
									Purpose
								</Link>
							)} */}
							{checkPermission(appMenu.masterRole) && (
								<Link
									onClick={() => {
										handleOnClickMenu(AppRoutes.masterRole);
									}}
									style={{ cursor: "pointer" }}
									className={isActiveMenu(AppRoutes.masterRole) ? "active" : ""}
									title="Role"
								>
									Role
								</Link>
							)}

							{checkPermission(appMenu.masterSource) && (
								<Link
									onClick={() => {
										handleOnClickMenu(AppRoutes.masterSource);
									}}
									style={{ cursor: "pointer" }}
									className={
										isActiveMenu(AppRoutes.masterSource) ? "active" : ""
									}
									title="Source"
								>
									Source
								</Link>
							)}
							{checkPermission(appMenu.masterUser) && (
								<Link
									onClick={() => {
										handleOnClickMenu(AppRoutes.masterUser);
									}}
									style={{ cursor: "pointer" }}
									className={isActiveMenu(AppRoutes.masterUser) ? "active" : ""}
									title="User"
								>
									User
								</Link>
							)}
						</li>
					</ul>
				</li>
			) : null}
			{checkPermission(appMenu.enquiry) && (
				<li>
					<Link
						onClick={() => {
							handleOnClickMenu(AppRoutes.enquiry);
						}}
						title="Enquiry"
						style={{ cursor: "pointer" }}
						className={isActiveMenu(AppRoutes.enquiry) ? "active" : ""}
					>
						<FormatListBulletedOutlined className="icon" />
						<Typography variant="body1">Enquiry</Typography>
					</Link>
				</li>
			)}
			{checkPermission(appMenu.property) && (
				<li>
					<Link
						onClick={() => {
							handleOnClickMenu(AppRoutes.property);
						}}
						style={{ cursor: "pointer" }}
						className={isActiveMenu(AppRoutes.property) ? "active" : ""}
						title="Property"
					>
						<HouseOutlined className="icon" />
						<Typography variant="body1">Property</Typography>
					</Link>
				</li>
			)}
			{checkPermission(appMenu.propertyDeal) && (
				<li>
					<Link
						onClick={() => {
							handleOnClickMenu(AppRoutes.propertyDeal);
						}}
						style={{ cursor: "pointer" }}
						className={isActiveMenu(AppRoutes.propertyDeal) ? "active" : ""}
						title="Property Deal"
					>
						<HandshakeOutlined className="icon" />
						<Typography variant="body1">Property Deal</Typography>
					</Link>
				</li>
			)}
		</ul>
	);
};
export default Sidebar;
