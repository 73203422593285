import {
	Avatar,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Menu,
	MenuItem,
	Typography,
} from "@mui/material";
import { profileDownIcon } from "../../../assets/images";
import React, { useContext } from "react";
import {
	CloseOutlined,
	FormatListBulletedOutlined,
	Group,
	Logout,
	MenuOutlined,
	Password,
	PersonOutline,
} from "@mui/icons-material";
import { clearSession } from "../../../utility/auth-guard/token-manager";
import { IUserDetails } from "../../../utility/interfaces";
import { useNavigate } from "react-router-dom";
import { ViewMyProfileDialog } from "../../common/view-profile-dialog";
import { AppRoutes } from "../../../utility/app-routes";
import { TeamLoginDialog } from "../../common/team-login-dialog";
import { TeamActivityDialog } from "../../common/team-activity-dialog";
import { PasswordChangeDialog } from "../../common/password-change-dialog";
import { UserContext } from "../../../contexts/user-context";

const Header: React.FC = () => {
	const navigate = useNavigate();
	var currentUserDetails: IUserDetails = useContext(UserContext);

	// Dialog
	const [logoutDialogOpen, setLogoutDialogOpen] = React.useState(false);
	const [isMyProfileOpen, setIsMyProfileOpen] = React.useState(false);
	const [isTeamLoginOpen, setIsTeamLoginOpen] = React.useState(false);
	const [isTeamActivityOpen, setIsTeamActivityOpen] = React.useState(false);
	const [isChangePasswordOpen, setIsChangePasswordOpen] = React.useState(false);
	const teamLoginOpen = () => {
		profileHandleClose();
		setIsTeamLoginOpen(true);
	};
	const teamLoginClose = () => {
		setIsTeamLoginOpen(false);
	};
	const logoutDialogHandleClickOpen = () => {
		profileHandleClose();
		setLogoutDialogOpen(true);
	};
	const logoutDialogHandleClose = () => {
		profileHandleClose();
		setLogoutDialogOpen(false);
	};
	const onClickLogout = () => {
		navigate(AppRoutes.login);
		clearSession();
		profileHandleClose();
		setLogoutDialogOpen(false);
	};
	// Profile Menu
	const [profileAnchorEl, setProfileAnchorEl] =
		React.useState<null | HTMLElement>(null);
	const profileOpen = Boolean(profileAnchorEl);
	const profileHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setProfileAnchorEl(event.currentTarget);
	};
	const profileHandleClose = () => {
		setProfileAnchorEl(null);
	};

	const onClickProfile = () => {
		profileHandleClose();
		setIsMyProfileOpen(true);
	};
	const teamActivityOpen = () => {
		profileHandleClose();
		setIsTeamActivityOpen(true);
	};
	const changePasswordOpen = () => {
		profileHandleClose();
		setIsChangePasswordOpen(true);
	};

	const toggleSidebar = () => {
		if (window.innerWidth >= 900) {
			if (document.body.classList.contains("hide-label")) {
				document.body.classList.remove("hide-label");
			} else {
				document.body.classList.add("hide-label");
			}
		} else {
			if (document.body.classList.contains("show-mobile-menu")) {
				document.body.classList.remove("show-mobile-menu");
			} else {
				document.body.classList.add("show-mobile-menu");
			}
		}
	};

	return (
		<>
			<header className="header">
				<IconButton aria-label="hamburger" className="hamburger-btn">
					<MenuOutlined
						className="icon-primary-color"
						onClick={toggleSidebar}
					/>
				</IconButton>
				<Button
					id="profile-button"
					aria-controls={profileOpen ? "prfoile-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={profileOpen ? "true" : undefined}
					onClick={profileHandleClick}
					className="profile-btn"
				>
					<Avatar
						style={{ width: "30px", height: "30px", borderRadius: "50%" }}
						src={currentUserDetails?.ProfileImage}
						variant="square"
						alt="Profile Photo"
					/>
					<Typography variant="body1">{`${currentUserDetails?.FirstName} ${currentUserDetails?.LastName}`}</Typography>
					<Avatar src={profileDownIcon} variant="square" alt="Down Arrow" />
				</Button>
			</header>
			<Dialog
				open={logoutDialogOpen}
				onClose={logoutDialogHandleClose}
				maxWidth="xs"
				fullWidth
			>
				<DialogTitle>
					Logout Confirmation
					<IconButton aria-label="Close" onClick={logoutDialogHandleClose}>
						<CloseOutlined />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>Are you sure want to logout?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" color="error" onClick={onClickLogout}>
						Logout
					</Button>
				</DialogActions>
			</Dialog>
			<Menu
				id="prfoile-menu"
				anchorEl={profileAnchorEl}
				open={profileOpen}
				onClose={profileHandleClose}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
				MenuListProps={{
					"aria-labelledby": "profile-button",
				}}
			>
				<MenuItem onClick={onClickProfile}>
					<PersonOutline className="list-icon" />
					Profile
				</MenuItem>
				<MenuItem onClick={teamActivityOpen}>
					<FormatListBulletedOutlined className="list-icon" /> Log Reports
				</MenuItem>
				<MenuItem onClick={teamLoginOpen}>
					<Group className="list-icon" /> Team's Login
				</MenuItem>
				<MenuItem onClick={changePasswordOpen}>
					<Password className="list-icon" /> Change Password
				</MenuItem>
				<MenuItem onClick={logoutDialogHandleClickOpen}>
					<Logout className="list-icon" /> Logout
				</MenuItem>
			</Menu>
			<ViewMyProfileDialog
				dialogOpen={isMyProfileOpen}
				handleOnClickClose={() => setIsMyProfileOpen(false)}
			/>
			<TeamLoginDialog
				dialogOpen={isTeamLoginOpen}
				handleOnClickClose={teamLoginClose}
			/>
			<TeamActivityDialog
				dialogOpen={isTeamActivityOpen}
				handleOnClickClose={() => {
					setIsTeamActivityOpen(false);
				}}
			/>
			<PasswordChangeDialog
				dialogOpen={isChangePasswordOpen}
				handleOnClickClose={() => {
					setIsChangePasswordOpen(false);
				}}
			/>
		</>
	);
};
export default Header;
