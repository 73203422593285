import { AxiosResponse } from "axios";
import httpClient from "./base-service";
import {
	IHttpsResponse,
	ILoginRequest,
	ILoginResponse,
} from "../utility/interfaces";
import { APIRoutes } from "../utility/app-routes";

export const login = async (
	request: ILoginRequest
): Promise<AxiosResponse<IHttpsResponse<ILoginResponse>>> =>
	httpClient.post<IHttpsResponse<ILoginResponse>>(APIRoutes.login, request);

export const addUser = async (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<ILoginResponse>>(APIRoutes.addUser, request);

export const updateUser = async (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<ILoginResponse>>(
		APIRoutes.updateUser,
		request
	);

export const updateMyProfile = async (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.put<IHttpsResponse<ILoginResponse>>(
		APIRoutes.updateMyProfile,
		request
	);

export const getUserProfile = async (): Promise<
	AxiosResponse<IHttpsResponse<any>>
> => httpClient.get<IHttpsResponse<ILoginResponse>>(APIRoutes.getUserProfile);

export const sendOtp = async (
	emailId: string
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.get<IHttpsResponse<ILoginResponse>>(
		`${APIRoutes.sendOtp}/${emailId}`
	);

export const forgotPassword = async (
	userId: number,
	otp: number,
	password: string
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<ILoginResponse>>(
		`${APIRoutes.forgotPassword}`,
		{ userId, otp, password }
	);
