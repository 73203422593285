import { useCallback, useEffect, useMemo, useState } from "react";
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	IconButton,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Radio,
	RadioGroup,
	Select,
	SelectChangeEvent,
	TextField,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { array, number, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { IDropDownFields } from "../../utility/interfaces";
import { phoneRegExp } from "../../utility/constant";
import {
	DealType,
	getDealTypeText,
} from "../../utility/enums/common/deal-type";
import { CustomDatePicker } from "../common/custom-date-picker";
import { toast } from "react-toastify";
import { HttpStatusCodes } from "../../utility/enums";
import dayjs from "dayjs";
import { addUpdateEnquiry, checkMobileNumber } from "../../services";
import { isInputOnlyNumber } from "../../utility/helpers/common";
import { IDropDownFieldsWithBhkofficeId } from "../../utility/interfaces/common";

type ComponentProps = {
	dialogOpen: boolean;
	handleOnClickClose: any;
	getAllRecords: any;
	data: any | undefined;
	getPageLoadData: any;
	bhkOfficeList: IDropDownFields[];
	budgetList: IDropDownFieldsWithBhkofficeId[];
	sourceList: IDropDownFields[];
	enquiryStatusList: IDropDownFields[];
	nonUseList: IDropDownFields[];
	employeeList: IDropDownFields[];
	areaList: IDropDownFields[];
};
export function AddUpdateEnquiry(props: ComponentProps) {
	const {
		dialogOpen,
		handleOnClickClose,
		data,
		getAllRecords,
		bhkOfficeList,
		budgetList,
		sourceList,
		enquiryStatusList,
		areaList,
		nonUseList,
		employeeList,
		getPageLoadData,
	} = props;

	const [id, setId] = useState(0);
	const [enquiryFor, setEnquiryFor] = useState(DealType.Rent);
	const [clientName, setClientName] = useState("");
	const [mobile, setMobile] = useState("");
	const [email, setEmail] = useState("");
	const [bhkOfficeId, setBhkOfficeId] = useState(0);
	const [sourceId, setSourceId] = useState(0);
	const [enquiryStatusId, setEnquiryStatusId] = useState(0);
	const [budgetId, setBudgetId] = useState(0);
	const [areaId, setAreaId] = useState<number[]>([]);
	// const [nonUseId, setNonUseId] = useState(0);
	const [assignTo, setAssignTo] = useState(0);
	const [nfd, setNFD] = useState(dayjs(new Date()));
	const [mobile1, setMobile1] = useState("");
	const [mobile2, setMobile2] = useState("");
	const [mobile3, setMobile3] = useState("");
	const [remark, setRemark] = useState("");

	const handleOnClickSubmit = async () => {
		const response = await addUpdateEnquiry({
			id,
			nameOfClient: clientName,
			mobileNo: mobile,
			email,
			enquiryFor,
			sourceId,
			bhkOfficeId,
			enquiryStatusId,
			budgetId,
			// nonuseId: nonUseId === 0 ? null : nonUseId,
			areaId: areaId.filter((x) => +x !== 0).toString(),
			remark,
			assignTo,
			nfd,
			mobile1,
			mobile2,
			mobile3,
		});
		if (response?.data?.status === HttpStatusCodes.Ok) {
			await getAllRecords();
			toast.success(response.data.message);
			handleOnClickClose();
		} else toast.error(response.data.message);
	};

	// Yup resolver
	const formValidationSchema = object({
		clientName: string().required("Client name is required."),
		mobileNo: string()
			.matches(phoneRegExp, "Mobile number is not valid.")
			.min(10, "Mobile number is not valid.")
			.max(10, "Mobile number is not valid."),
		bhkOffice: number().min(1, "Please select bhk Office."),
		source: number().min(1, "Please select source."),
		status: number().min(1, "Please select status."),
		budget: number().min(1, "Please select budget."),
		area: array().required("Please select at least one area."),
		assignTo: number().min(1, "Please select assign to."),
	});
	useEffect(() => {
		if (dialogOpen) {
			getPageLoadData();
			setId(data?.id || 0);
			setEnquiryFor(data?.enquiryFor || DealType.Rent);
			setClientName(data?.nameOfClient || "");
			setMobile(data?.mobileNo || "");
			setEmail(data?.email || "");
			setBhkOfficeId(data?.bhkOfficeId || 0);
			setSourceId(data?.sourceId || 0);
			setEnquiryStatusId(data?.enquiryStatusId || 0);
			setBudgetId(data?.budgetId || 0);
			setAreaId(
				data?.areaId === ""
					? []
					: data?.areaId?.split(",")?.map((x: any) => +x) || []
			);
			// setNonUseId(data?.nonuseId || 0);
			setNFD(data?.nfd || dayjs(new Date()));
			setAssignTo(data?.assignTo || 0);
			setMobile1(data?.mobile1 || "");
			setMobile2(data?.mobile2 || "");
			setMobile3(data?.mobile3 || "");
			setRemark(data?.remark || "");
		} else {
			setId(0);
			setEnquiryFor(DealType.Rent);
			setClientName("");
			setMobile("");
			setEmail("");
			setBhkOfficeId(0);
			setSourceId(0);
			setEnquiryStatusId(0);
			setBudgetId(0);
			setAreaId([]);
			// setNonUseId(0);
			setNFD(dayjs(new Date()));
			setAssignTo(0);
			setMobile1("");
			setMobile2("");
			setMobile3("");
			setRemark("");
		}
		reset();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dialogOpen]);

	const checkMobileNumberAlreadyExistInOtherEnquiry = async (
		mobileNo: string
	) => {
		const response = await checkMobileNumber({ id: data?.id || 0, mobileNo });
		if (response?.data?.status === HttpStatusCodes.Ok) {
			toast.success(response.data.message);
		} else if (response?.data?.status === HttpStatusCodes.ConflictError) {
			toast.warning(response.data.message);
		} else toast.error(response.data.message);
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm({
		resolver: yupResolver(formValidationSchema),
	});

	useEffect(() => {
		setValue("assignTo", assignTo);
		setValue("bhkOffice", bhkOfficeId);
		setValue("source", sourceId);
		setValue("status", enquiryStatusId);
		setValue("budget", budgetId);
		setValue(
			"area",
			data?.areaId === ""
				? []
				: data?.areaId?.split(",")?.map((x: any) => +x) || []
		);
		setValue("clientName", clientName);
		setValue("mobileNo", mobile);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assignTo]);

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
	};

	return (
		<Dialog
			open={dialogOpen}
			onClose={handleOnClickClose}
			maxWidth="lg"
			fullWidth
		>
			<DialogTitle>
				{data?.id === 0 || data == null || !data
					? "Add New Enquiry"
					: "Edit Enquiry"}
				<IconButton aria-label="Close" onClick={handleOnClickClose}>
					<CloseOutlined />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<FormControl className="grid-radio radio-control">
							<FormLabel id="demo-row-radio-buttons-group-label">
								Enquiry For
							</FormLabel>
							<RadioGroup
								row
								aria-labelledby="demo-row-radio-buttons-group-label"
								name="row-radio-buttons-group"
								value={enquiryFor}
							>
								<FormControlLabel
									value={DealType.Buy}
									control={<Radio />}
									label={getDealTypeText(DealType.Buy)}
									onClick={() => setEnquiryFor(DealType.Buy)}
								/>
								<FormControlLabel
									value={DealType.Sell}
									control={<Radio />}
									label={getDealTypeText(DealType.Sell)}
									onClick={() => setEnquiryFor(DealType.Sell)}
								/>
								<FormControlLabel
									value={DealType.Rent}
									control={<Radio />}
									label={getDealTypeText(DealType.Rent)}
									onClick={() => setEnquiryFor(DealType.Rent)}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							{...register("clientName")}
							error={errors?.clientName?.message ? true : false}
							helperText={errors?.clientName?.message?.toString()}
							id="clientName"
							label="Client Name*"
							type="text"
							fullWidth
							variant="outlined"
							value={clientName}
							onChange={(e) => {
								setClientName(e.target.value);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							id="mobileNo"
							{...register("mobileNo")}
							error={errors?.mobileNo?.message ? true : false}
							helperText={errors?.mobileNo?.message?.toString()}
							label="Primary Mobile Number*"
							type="text"
							inputProps={{ maxLength: 10 }}
							fullWidth
							variant="outlined"
							value={mobile}
							onChange={(e) => {
								if (isInputOnlyNumber(e.target.value)) {
									setMobile(e.target.value);
									if (e.target.value.length === 10)
										checkMobileNumberAlreadyExistInOtherEnquiry(e.target.value);
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							id="email"
							label="Email (Optional)"
							type="text"
							fullWidth
							variant="outlined"
							value={email}
							onChange={(e) => {
								setEmail(e.target.value);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<FormControl fullWidth>
							<InputLabel id="bhk-office-label">Bhk/Office*</InputLabel>
							<Select
								{...register("bhkOffice")}
								error={errors?.bhkOffice?.message ? true : false}
								labelId="bhk-office-label"
								id="bhkOffice"
								value={bhkOfficeId}
								label="Bhk/Office*"
								onChange={(e) => setBhkOfficeId(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{bhkOfficeList.map((record) => {
									return (
										<MenuItem
											key={record.id}
											disabled={!record.isActive}
											value={record.id}
										>
											{record.text}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.bhkOffice?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<FormControl fullWidth>
							<InputLabel id="bhk-office-label">Budget*</InputLabel>
							<Select
								{...register("budget")}
								error={errors?.budget?.message ? true : false}
								labelId="bhk-office-label"
								id="budget"
								value={budgetId}
								label="Budget*"
								onChange={(e) => setBudgetId(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{budgetList
									.filter(
										(x) => x.bhkOfficeId === bhkOfficeId || bhkOfficeId === 0
									)
									.filter((x) => x.for === enquiryFor)
									.map((record) => {
										return (
											<MenuItem
												key={record.id}
												disabled={!record.isActive}
												value={record.id}
											>
												{record.text}
											</MenuItem>
										);
									})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.budget?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<FormControl fullWidth>
							<InputLabel id="bhk-office-label">Source*</InputLabel>
							<Select
								{...register("source")}
								error={errors?.source?.message ? true : false}
								labelId="bhk-office-label"
								id="source"
								value={sourceId}
								label="Source*"
								onChange={(e) => setSourceId(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{sourceList.map((record) => {
									return (
										<MenuItem
											key={record.id}
											disabled={!record.isActive}
											value={record.id}
										>
											{record.text}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.source?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<FormControl fullWidth>
							<InputLabel id="status-label">Status*</InputLabel>
							<Select
								{...register("status")}
								error={errors?.status?.message ? true : false}
								labelId="status-label"
								id="status"
								value={enquiryStatusId}
								label="Status*"
								onChange={(e) => setEnquiryStatusId(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{enquiryStatusList.map((record) => {
									return (
										<MenuItem
											key={record.id}
											disabled={!record.isActive}
											value={record.id}
										>
											{record.text}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.status?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<FormControl fullWidth>
							<InputLabel id="bhk-office-label">Area*</InputLabel>
							<Select
								{...register("area")}
								error={errors?.area?.message ? true : false}
								labelId="bhk-office-label"
								id="area"
								multiple
								input={<OutlinedInput label="Tag" />}
								MenuProps={MenuProps}
								value={areaId
									.filter((x: any) => +x !== 0)
									.map((x: any) => {
										return +x;
									})}
								renderValue={(selected) =>
									selected
										.filter((x: any) => +x !== 0)
										.map((x: any) => {
											return areaList.find((y) => y.id === x)?.text;
										})
										.join(",")
								}
								label="Area*"
								onChange={(event: SelectChangeEvent<typeof areaId>) => {
									const {
										target: { value },
									} = event;
									setAreaId(
										// On autofill we get a stringified value.
										typeof value === "string"
											? value.split(",").map((x) => +x)
											: value
									);
								}}
							>
								{areaList.map((record) => {
									return (
										<MenuItem
											key={record.id}
											disabled={!record.isActive}
											value={record.id}
										>
											<Checkbox
												checked={
													areaId.filter((x) => +x === +record.id).length > 0
												}
											/>
											<ListItemText primary={record.text} />
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.area?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>
					{/* <Grid item xs={12} sm={6} md={4} lg={3}>
						<FormControl fullWidth>
							<InputLabel id="bhk-office-label">Non Use (Optional)</InputLabel>
							<Select
								labelId="bhk-office-label"
								id="nonUse"
								value={nonUseId}
								label="Non Use (Optional)"
								onChange={(e) => setNonUseId(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{nonUseList.map((record) => {
									return (
										<MenuItem
											key={record.id}
											disabled={!record.isActive}
											value={record.id}
										>
											{record.text}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Grid> */}
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<FormControl fullWidth>
							<InputLabel id="bhk-office-label">Assign To*</InputLabel>
							<Select
								{...register("assignTo")}
								error={errors?.assignTo?.message ? true : false}
								labelId="bhk-office-label"
								id="assignTo"
								value={assignTo}
								label="Assign To*"
								onChange={(e) => setAssignTo(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{employeeList.map((record) => {
									return (
										<MenuItem
											key={record.id}
											disabled={!record.isActive}
											value={record.id}
										>
											{record.text}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.assignTo?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={4}
						lg={3}
						className="datepicker-wrappper"
					>
						<CustomDatePicker
							disabled={false}
							label="Next Followup Date*"
							onChangeValue={(newValue: any) => setNFD(newValue)}
							value={nfd}
							maxDate={new Date().setFullYear(new Date().getFullYear() + 200)}
							minDate={new Date().setFullYear(new Date().getFullYear() - 200)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							id="mobileNo1"
							label="Mobile No 1 (Optional)"
							type="text"
							inputProps={{ maxLength: 10 }}
							fullWidth
							variant="outlined"
							value={mobile1}
							onChange={(e) => {
								if (isInputOnlyNumber(e.target.value)) {
									setMobile1(e.target.value);
									if (e.target.value.length === 10)
										checkMobileNumberAlreadyExistInOtherEnquiry(e.target.value);
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							id="mobileNo2"
							inputProps={{ maxLength: 10 }}
							label="Mobile No 2 (Optional)"
							type="text"
							fullWidth
							variant="outlined"
							value={mobile2}
							onChange={(e) => {
								if (isInputOnlyNumber(e.target.value)) {
									setMobile2(e.target.value);
									if (e.target.value.length === 10)
										checkMobileNumberAlreadyExistInOtherEnquiry(e.target.value);
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							id="mobileNo3"
							label="Mobile No 3 (Optional)"
							type="text"
							fullWidth
							variant="outlined"
							value={mobile3}
							onChange={(e) => {
								if (isInputOnlyNumber(e.target.value)) {
									setMobile3(e.target.value);
									if (e.target.value.length === 10)
										checkMobileNumberAlreadyExistInOtherEnquiry(e.target.value);
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} lg={12}>
						<TextField
							id="remark"
							label="Remark (Optional)"
							type="text"
							fullWidth
							multiline
							minRows={4}
							variant="outlined"
							value={remark}
							onChange={(e) => setRemark(e.target.value)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={handleSubmit(handleOnClickSubmit)}>
					Submit
				</Button>
				<Button variant="outlined" onClick={handleOnClickClose}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}
