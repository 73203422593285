import {
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import Header from "../../../components/layouts/header";
import Sidebar from "../../../components/layouts/sidebar";
import { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Add, CloseOutlined } from "@mui/icons-material";
import React from "react";

const PropertyDeal: React.FC = () => {
	// DatePicker
	const [value, setValue] = React.useState<Dayjs | null>(null);

	// Dialog
	const [dialogAddOpen, setDialogAddOpen] = React.useState(false);
	const dialogAddClickOpen = () => {
		setDialogAddOpen(true);
	};
	const dialogAddClose = () => {
		setDialogAddOpen(false);
	};

	// Select
	const [selectPropertyType, setSelectPropertyType] = React.useState("");
	const selectPropertyTypeChange = (event: SelectChangeEvent) => {
		setSelectPropertyType(event.target.value as string);
	};

	const [selectPropertySource, setSelectPropertySource] = React.useState("");
	const selectPropertySourceChange = (event: SelectChangeEvent) => {
		setSelectPropertySource(event.target.value as string);
	};

	const [selectBuyerSource, setSelectBuyerSource] = React.useState("");
	const selectBuyerSourceChange = (event: SelectChangeEvent) => {
		setSelectBuyerSource(event.target.value as string);
	};

	const [selectDealType, setSelectDealType] = React.useState("");
	const selectDealTypeChange = (event: SelectChangeEvent) => {
		setSelectDealType(event.target.value as string);
	};

	const [selectEmployeeName, setSelectEmployeeName] = React.useState("");
	const selectEmployeeNameChange = (event: SelectChangeEvent) => {
		setSelectEmployeeName(event.target.value as string);
	};

	const [selectPaymentOption, setSelectPaymentOption] = React.useState("");
	const selectPaymentOptionChange = (event: SelectChangeEvent) => {
		setSelectPaymentOption(event.target.value as string);
	};
	return (
		<div className="main-content">
			<Header />
			<div className="page-content">
				<Sidebar />
				<div className="page-inner">
					<div className="page-heading-wrapper">
						<h4 className="page-heading">Property Deal</h4>
						<IconButton
							aria-label="add"
							color="primary"
							className="primary-icon"
							onClick={dialogAddClickOpen}
						>
							<Add />
						</IconButton>
					</div>
					<div className="white-paper">
						<div className="table-heading-block">
							<TextField label="Search" variant="outlined" />
						</div>
						<div className="table-negative-space">
							<TableContainer>
								<Table aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Sr.No</TableCell>
											<TableCell>Deal Date</TableCell>
											<TableCell>Type</TableCell>
											<TableCell>Client Name</TableCell>
											<TableCell>Property Name</TableCell>
											<TableCell>Flat No</TableCell>
											<TableCell>Employee</TableCell>
											<TableCell>Action</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell>Item 1</TableCell>
											<TableCell>Item 2</TableCell>
											<TableCell>Item 3</TableCell>
											<TableCell>Item 4</TableCell>
											<TableCell>Item 5</TableCell>
											<TableCell>Item 6</TableCell>
											<TableCell>Item 7</TableCell>
											<TableCell>Item 8</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</div>
					</div>
				</div>
				{/* Add New Dialog */}
				<Dialog
					open={dialogAddOpen}
					onClose={dialogAddClose}
					maxWidth="lg"
					fullWidth
				>
					<DialogTitle>
						Add New Property Deal
						<IconButton aria-label="Close" onClick={dialogAddClose}>
							<CloseOutlined />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<Grid container spacing={2} className="mb-20">
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth>
									<InputLabel id="select-label">Property Type</InputLabel>
									<Select
										labelId="select-label"
										id="simple-select"
										value={selectPropertyType}
										label="Property Type"
										onChange={selectPropertyTypeChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth>
									<InputLabel id="select-label">Deal Type</InputLabel>
									<Select
										labelId="select-label"
										id="simple-select"
										value={selectDealType}
										label="Deal Type"
										onChange={selectDealTypeChange}
									>
										<MenuItem value="Rent">Rent</MenuItem>
										<MenuItem value="Sell">Sell</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth>
									<InputLabel id="employee-label">Employee Name</InputLabel>
									<Select
										labelId="employee-label"
										id="employee-select"
										value={selectEmployeeName}
										label="Employee Name"
										onChange={selectEmployeeNameChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								md={4}
								lg={3}
								className="datepicker-wrappper"
							>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DemoContainer components={["DatePicker"]}>
										<DatePicker
											value={value}
											className="w-full"
											label="Deal Date"
											onChange={(newValue) => setValue(newValue)}
										/>
									</DemoContainer>
								</LocalizationProvider>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								md={4}
								lg={3}
								className="datepicker-wrappper"
							>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DemoContainer components={["DatePicker"]}>
										<DatePicker
											value={value}
											className="w-full"
											label="Prossession Date"
											onChange={(newValue) => setValue(newValue)}
										/>
									</DemoContainer>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									label="Property Name"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									label="Flat/Office No"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									label="Property Owner Name"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									label="Property Owner Contact No"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth className="mb-8">
									<InputLabel id="property-source-label">
										Property Source
									</InputLabel>
									<Select
										labelId="property-source-label"
										id="property-source-select"
										value={selectPropertySource}
										label="Property Source"
										onChange={selectPropertySourceChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									label="Buyer Name"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									label="Buyer Contact No"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth className="mb-8">
									<InputLabel id="buyer-source-label">Buyer Source</InputLabel>
									<Select
										labelId="buyer-source-label"
										id="buyer-source-select"
										value={selectBuyerSource}
										label="Buyer Source"
										onChange={selectBuyerSourceChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									label="BHK"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									label="SQFT"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									label="Deal Amount"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									label="Brokerage from Owner/builder"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									label="Brokerage from client"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									label="Remarks"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
						</Grid>
						<Typography className="section-heading" variant="h6">
							Payment Option
						</Typography>
						<TableContainer className="payment-option">
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Option</TableCell>
										<TableCell>Amount</TableCell>
										<TableCell>Date</TableCell>
										<TableCell>Remark</TableCell>
										<TableCell>Bank/Cheque/UPI Details</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell>
											<FormControl fullWidth>
												<InputLabel id="Select-Payment-Option-label">
													Select Payment Option
												</InputLabel>
												<Select
													labelId="Select-Payment-Option-label"
													id="simple-select"
													value={selectPaymentOption}
													label="Select Payment Option"
													onChange={selectPaymentOptionChange}
												>
													<MenuItem value="PhoneCall">Phone Call</MenuItem>
													<MenuItem value="visit">Visit</MenuItem>
													<MenuItem value="meeting">Meeting</MenuItem>
													<MenuItem value="deal">Deal</MenuItem>
												</Select>
											</FormControl>
										</TableCell>
										<TableCell className="datepicker-wrappper">
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DemoContainer components={["DatePicker"]}>
													<DatePicker
														value={value}
														className="w-full"
														label="Date"
														onChange={(newValue) => setValue(newValue)}
													/>
												</DemoContainer>
											</LocalizationProvider>
										</TableCell>
										<TableCell>
											<TextField
												label="Amount"
												type="text"
												fullWidth
												variant="outlined"
											/>
										</TableCell>
										<TableCell>
											<TextField
												label="Remark"
												type="text"
												fullWidth
												variant="outlined"
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<FormControl fullWidth>
												<InputLabel id="Select-Payment-Option-label">
													Select Payment Option
												</InputLabel>
												<Select
													labelId="Select-Payment-Option-label"
													id="simple-select"
													value={selectPaymentOption}
													label="Select Payment Option"
													onChange={selectPaymentOptionChange}
												>
													<MenuItem value="PhoneCall">Phone Call</MenuItem>
													<MenuItem value="visit">Visit</MenuItem>
													<MenuItem value="meeting">Meeting</MenuItem>
													<MenuItem value="deal">Deal</MenuItem>
												</Select>
											</FormControl>
										</TableCell>
										<TableCell className="datepicker-wrappper">
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DemoContainer components={["DatePicker"]}>
													<DatePicker
														value={value}
														className="w-full"
														label="Date"
														onChange={(newValue) => setValue(newValue)}
													/>
												</DemoContainer>
											</LocalizationProvider>
										</TableCell>
										<TableCell>
											<TextField
												label="Amount"
												type="text"
												fullWidth
												variant="outlined"
											/>
										</TableCell>
										<TableCell>
											<TextField
												label="Remark"
												type="text"
												fullWidth
												variant="outlined"
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<FormControl fullWidth>
												<InputLabel id="Select-Payment-Option-label">
													Select Payment Option
												</InputLabel>
												<Select
													labelId="Select-Payment-Option-label"
													id="simple-select"
													value={selectPaymentOption}
													label="Select Payment Option"
													onChange={selectPaymentOptionChange}
												>
													<MenuItem value="PhoneCall">Phone Call</MenuItem>
													<MenuItem value="visit">Visit</MenuItem>
													<MenuItem value="meeting">Meeting</MenuItem>
													<MenuItem value="deal">Deal</MenuItem>
												</Select>
											</FormControl>
										</TableCell>
										<TableCell className="datepicker-wrappper">
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DemoContainer components={["DatePicker"]}>
													<DatePicker
														value={value}
														className="w-full"
														label="Date"
														onChange={(newValue) => setValue(newValue)}
													/>
												</DemoContainer>
											</LocalizationProvider>
										</TableCell>
										<TableCell>
											<TextField
												label="Amount"
												type="text"
												fullWidth
												variant="outlined"
											/>
										</TableCell>
										<TableCell>
											<TextField
												label="Remark"
												type="text"
												fullWidth
												variant="outlined"
											/>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</DialogContent>
				</Dialog>
			</div>
		</div>
	);
};

export default PropertyDeal;
