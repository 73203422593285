import { AxiosResponse } from "axios";
import httpClient from "./base-service";
import { IHttpsResponse } from "../utility/interfaces";
import { APIRoutes } from "../utility/app-routes";

export const getAllCommonRecords = async (
	url: string
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.get<IHttpsResponse<any>>(url);

export const getAllCommonTableRecords = async (
	url: string,
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(url, request);

export const addNewCommonRecord = async (
	name: string,
	isActive: boolean,
	url: string
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(url, { name, isActive });

export const deleteCommonRecord = async (
	url: string
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.delete<IHttpsResponse<any>>(url);

export const updateCommonRecord = async (
	name: string,
	isActive: boolean,
	id: number,
	url: string
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(url, { name, isActive, id });

export const getAllParentActivities = async (): Promise<
	AxiosResponse<IHttpsResponse<any>>
> => httpClient.get<IHttpsResponse<any>>(APIRoutes.getAllParentActivities);

export const getAllBhkOffice = async (): Promise<
	AxiosResponse<IHttpsResponse<any>>
> => httpClient.get<IHttpsResponse<any>>(APIRoutes.getAllBhkOffice);

export const addNewBhkOffice = async (
	name: string,
	isActive: boolean,
	segmentId: number
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(APIRoutes.addUpdateBhkOffice, {
		name,
		isActive,
		segmentId,
	});

export const updateBhkOffice = async (
	name: string,
	isActive: boolean,
	segmentId: number,
	id: number
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(APIRoutes.addUpdateBhkOffice, {
		name,
		isActive,
		id,
		segmentId,
	});

export const getAllSegment = async (): Promise<
	AxiosResponse<IHttpsResponse<any>>
> => httpClient.get<IHttpsResponse<any>>(APIRoutes.getAllSegment);

export const addNewSegment = async (
	name: string,
	isActive: boolean,
	propertyTypeId: number
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(APIRoutes.addUpdateSegment, {
		name,
		isActive,
		propertyTypeId,
	});

export const updateSegment = async (
	name: string,
	isActive: boolean,
	propertyTypeId: number,
	id: number
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(APIRoutes.addUpdateSegment, {
		name,
		isActive,
		id,
		propertyTypeId,
	});

export const getAllBudget = async (): Promise<
	AxiosResponse<IHttpsResponse<any>>
> => httpClient.get<IHttpsResponse<any>>(APIRoutes.getAllBudget);

export const addNewBudget = async (
	isActive: boolean,
	name: string,
	from: number,
	to: number,
	forValue: number,
	segmentId: number,
	bhkOfficeId: number,
	hePropertyTypeId: number
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(APIRoutes.addUpdateBudget, {
		isActive,
		name,
		from,
		to,
		forValue,
		bhkOfficeId,
	});

export const updateBudget = async (
	isActive: boolean,
	name: string,
	from: number,
	to: number,
	forValue: number,
	segmentId: number,
	bhkOfficeId: number,
	hePropertyTypeId: number,
	id: number
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(APIRoutes.addUpdateBudget, {
		isActive,
		name,
		from,
		to,
		forValue,
		bhkOfficeId,
		id,
	});

export const getAllMinMax = async (): Promise<
	AxiosResponse<IHttpsResponse<any>>
> => httpClient.get<IHttpsResponse<any>>(APIRoutes.getAllMinMax);

export const addNewMinMax = async (
	isActive: boolean,
	forValue: number,
	minTitle: string,
	maxTitle: string,
	minValue: number,
	maxValue: number
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(APIRoutes.addUpdateMinMax, {
		isActive,
		for: forValue,
		minTitle,
		maxTitle,
		min: minValue,
		max: maxValue,
	});

export const updateMinMax = async (
	isActive: boolean,
	forValue: number,
	minTitle: string,
	maxTitle: string,
	minValue: number,
	maxValue: number,
	id: number
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(APIRoutes.addUpdateMinMax, {
		isActive,
		for: forValue,
		minTitle,
		maxTitle,
		min: minValue,
		max: maxValue,
		id,
	});

export const getRolePermission = async (
	roleId: number
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.get<IHttpsResponse<any>>(
		APIRoutes.getRolePermission + `/${roleId}`
	);

export const updateRolePermission = async (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(APIRoutes.updateRolePermission, request);

export const updateUserPassword = async (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(APIRoutes.updateUserPassword, request);

export const changeUserPassword = async (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(APIRoutes.changeUserPassword, request);

export const getAllChildActivities = async (): Promise<
	AxiosResponse<IHttpsResponse<any>>
> => httpClient.get<IHttpsResponse<any>>(APIRoutes.getAllChildActivities);

export const addUpdateChildActivity = async (
	name: string,
	isActive: boolean,
	parentId: number,
	id: number
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(APIRoutes.addUpdateChildActivity, {
		name,
		isActive,
		activityParentId: parentId,
		id,
	});
