import { TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";

type ComponentProps = {
	data: any;
};
export function TeamLoginRow(props: ComponentProps) {
	const { data } = props;

	return (
		<>
			<TableRow className={data.loginToday === true ? "green" : "red"}>
				<TableCell>{data.userName}</TableCell>
				<TableCell>
					{data.lastLoginTime
						? dayjs(data.lastLoginTime).format("DD-MM-YYYY hh:mm:ss A")
						: ""}
				</TableCell>
				<TableCell>{data.lastLoginIp}</TableCell>
			</TableRow>
		</>
	);
}
