import {
	Avatar,
	Button,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	Link,
	OutlinedInput,
	TextField,
} from "@mui/material";
import React from "react";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { logo } from "../../../assets/images";

const Login: React.FC = () => {
	const [showPassword, setShowPassword] = React.useState(false);
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};

	return (
		<div className="login-wrapper">
			<div className="login-form">
				<Link href="#" className="logo" title="Logo">
					<Avatar src={logo} alt="Logo" variant="square" />
				</Link>
				<TextField
					label="Username"
					variant="outlined"
					type="text"
					className="mb-20"
				/>
				<FormControl variant="outlined" className="mb-20">
					<InputLabel htmlFor="password">Password</InputLabel>
					<OutlinedInput
						id="password"
						type={showPassword ? "text" : "password"}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge="end"
								>
									{showPassword ? (
										<VisibilityOff className="password-icon" />
									) : (
										<Visibility className="password-icon" />
									)}
								</IconButton>
							</InputAdornment>
						}
						label="Password"
					/>
				</FormControl>
				<Button variant="contained" className="w-full mb-20" href="/dashboard">
					Login
				</Button>
				<div className="text-center">
					<Link href="#" className="primary-link">
						Forgot Password?
					</Link>
				</div>
			</div>
		</div>
	);
};
export default Login;
