export const enum AppRoutes {
	// Manage 2 places : my-app/src/utility/enums/common/app-menu.ts and here
	login = "/login",
	dashboard = "/",
	enquiry = "/enquiry",
	property = "/property",
	// masterActivity = "/master/activity",
	masterArea = "/master/area",
	masterBhkOffice = "/master/bhk-office",
	masterBudget = "/master/budget",
	masterBuilding = "/master/building",
	masterDraftReason = "/master/draft-reason",
	masterEnquiryStatus = "/master/enquiry-status",
	masterParentActivity = "/master/parent-activity",
	masterChildActivity = "/master/child-activity",
	masterFurnitureStatus = "/master/furniture-status",
	masterMeasurement = "/master/measurement",
	masterMinMax = "/master/min-max",
	masterNonUse = "/master/non-use",
	masterPrice = "/master/price",
	masterPropertyStatus = "/master/property-status",
	masterPropertyType = "/master/property-type",
	masterPurpose = "/master/purpose",
	masterRole = "/master/role",
	masterSegment = "/master/segment",
	masterSource = "/master/source",
	masterUser = "/master/user",
	propertyDeal = "/property-deal",
}

export const enum APIRoutes {
	login = "/auth/sign-in",

	addUser = "/user/add-new-user",
	updateUser = "/user/update-user",
	updateMyProfile = "/user/update-my-profile",
	addUpdateUser = "/user/add-update-user",
	updateUserPassword = "/user/change-password",
	changeUserPassword = "/user/reset-password ",
	getAllUsers = "/user/get-all-user-list",
	getDataOnMasterUserPageLoad = "/user/get-pageload-data",
	deleteUsers = "/user/delete-user",
	getUserProfile = "/user/get-user-profile",

	getAllArea = "/area/get-all-areas",
	appUpdateArea = "/area/add-update-area",
	deleteArea = "/area/delete-area",

	getAllBuilding = "/buildings/get-all-buildings",
	appUpdateBuilding = "/buildings/add-update-building",
	deleteBuilding = "/buildings/delete-building",

	getAllDraftReason = "/draftReason/get-all-draft-reasons",
	appUpdateDraftReason = "/draftReason/add-update-draft-reason",
	deleteDraftReason = "/draftReason/delete-draft-reason",

	getAllEnquiryStatus = "/EnquiryStatus/get-all-enquiry-status",
	appUpdateEnquiryStatus = "/EnquiryStatus/add-update-enquiry-status",
	deleteEnquiryStatus = "/EnquiryStatus/delete-enquiry-status",

	getAllFurnitureStatus = "/FurnitureStatus/get-all-furniture-status",
	appUpdateFurnitureStatus = "/FurnitureStatus/add-update-furniture-status",
	deleteFurnitureStatus = "/FurnitureStatus/delete-furniture-status",

	getAllMeasurements = "/Measurements/get-all-measurements",
	appUpdateMeasurement = "/Measurements/add-update-measurement",
	deleteMeasurement = "/Measurements/delete-measurement",

	getAllNonUse = "/Nonuse/get-all-nonuse",
	appUpdateNonUse = "/Nonuse/add-update-nonuse",
	deleteNonUse = "/Nonuse/delete-nonuse",

	getAllPropertyStatus = "/PropertyStatus/get-all-property-status",
	appUpdatePropertyStatus = "/PropertyStatus/add-update-property-status",
	deletePropertyStatus = "/PropertyStatus/delete-property-status",

	getAllPropertyTypes = "/propertyType/get-all-property-types",
	appUpdatePropertyType = "/propertyType/add-update-property-type",
	deletePropertyType = "/propertyType/delete-property-type",

	getAllPurposes = "/Purpose/get-all-purpose",
	appUpdatePurpose = "/Purpose/add-update-purpose",
	deletePurpose = "/Purpose/delete-purpose",

	getAllRoles = "/roles/get-all-roles",
	addUpdateRole = "/roles/add-update-role",
	deleteRole = "/roles/delete-role",

	getAllSources = "/Source/get-all-sources",
	addUpdateSource = "/Source/add-update-source",
	deleteSource = "/Source/delete-source",

	getAllBhkOffice = "/bhkOffice/get-all-bhkOffice",
	addUpdateBhkOffice = "/bhkOffice/add-update-bhkOffice",
	deleteBhkOffice = "/bhkOffice/delete-bhkOffice",

	getAllSegment = "/Segment/get-all-segment",
	addUpdateSegment = "/Segment/add-update-segment",
	deleteSegment = "/Segment/delete-segment",

	getAllBudget = "/budget/get-all-budget",
	addUpdateBudget = "/budget/add-update-budget",
	deleteBudget = "/budget/delete-budget",

	getAllMinMax = "/MinMax/get-all-min-max",
	addUpdateMinMax = "/MinMax/add-update-min-max",
	deleteMinMax = "/MinMax/delete-min-max",

	getAllPrice = "/Price/get-all-price",
	addUpdatePrice = "/Price/add-update-price",
	deletePrice = "/Price/delete-price",

	getRolePermission = "/rolepermission/get-role-permission",
	updateRolePermission = "/rolepermission/update-role-permission",

	propertyDealOnPageLoad = "/PropertyDeal/get-pageload-data",
	getAllPropertyDeal = "/PropertyDeal/get-all-propertydeal",
	addUpdatePropertyDeal = "/PropertyDeal/add-update-propertydeal",
	deletePropertyDeal = "/PropertyDeal/delete-propertydeal",

	getAllProperties = "/Property/get-all-property",
	addUpdateProperty = "/Property/add-update-property",
	deleteProperty = "/Property/delete-property",
	propertyOnPageLoad = "/Property/get-pageload-data",

	getAllParentActivities = "/ActivityParent/get-all-activityparent",
	addUpdateParentActivity = "/ActivityParent/add-update-activityparent",
	deleteParentActivity = "/ActivityParent/delete-activityparent",

	getAllChildActivities = "/ActivityChild/get-all-activitychild",
	addUpdateChildActivity = "/ActivityChild/add-update-activitychild",
	deleteChildActivity = "/ActivityChild/delete-activitychild",
	getPageChildActivityPageLoad = "/ActivityChild/get-pageload-data",
	getAllEnquiries = "/Enquiry/get-all-enquiry",
	checkMobileNumber = "/Enquiry/check-mobile",
	addUpdateEnquiry = "/Enquiry/add-update-enquiry",
	deleteEnquiry = "/Enquiry/delete-enquiry",
	enquiryOnPageLoad = "/Enquiry/get-pageload-data",
	addEnquiryRemark = "/Enquiry/add-enquiry-remark",
	getEnquiryRemark = "/Enquiry/get-enquiry-remark",
	sendEmail = "/enquiry/send-mail",

	getUserDetails = "/user/get-user-details/",
	getMyProfileDetails = "/user/get-my-profile/",
	getAllDashboardEnquiries = "/Dashboard/get-enquiry",
	getTeamLoginsReport = "/report/get-login-report/",
	getTeamActivityReport = "/report/get-log-report/",
	getTeamActivityReportPageLoad = "/report/get-pageload-data",
	getSegmentPageLoad = "/segment/get-pageload-data",

	getBhkOfficePageLoad = "/bhkOffice/get-pageload-data",

	getBudgetPageLoad = "/budget/get-pageload-data",
	forgotPassword = "/forgotpassword/forgot-password",
	sendOtp = "/forgotpassword/send-otp",
}
