import React, { useEffect } from "react";
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	IconButton,
	TextField,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import { addNewCommonRecord, updateCommonRecord } from "../../../services";
import { HttpStatusCodes } from "../../../utility/enums";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

type ComponentProps = {
	dialogOpen: boolean;
	handleOnClickClose: any;
	dialogTitle: string;
	apiPath: string;
	getAllRecords: any;
	data: any | undefined;
};
export function AddUpdateCommonRecord(props: ComponentProps) {
	const {
		dialogOpen,
		handleOnClickClose,
		dialogTitle,
		data,
		apiPath,
		getAllRecords,
	} = props;
	const [name, setName] = React.useState("");
	const [isActive, setIsActive] = React.useState(true);
	const [id, setId] = React.useState(0);
	const [currentApiPath, setCurrentApiPathApiPath] = React.useState("");

	const formValidationSchema = object({
		name: string().required("Name is required"),
	});

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		resolver: yupResolver(formValidationSchema),
	});

	const handleOnClickSubmit = async () => {
		let response = null;
		if (id === 0)
			response = await addNewCommonRecord(name, isActive, currentApiPath);
		else
			response = await updateCommonRecord(name, isActive, id, currentApiPath);

		if (response?.data?.status === HttpStatusCodes.Ok) {
			await getAllRecords();
			toast.success(response.data.message);
		} else toast.error(response.data.message);
		handleOnClickClose();
	};

	// useEffects
	useEffect(() => {
		reset();
		setName(data?.name);
		setIsActive(data ? data.isActive : true);
		setCurrentApiPathApiPath(apiPath);
		setId(data?.id || 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dialogOpen]);

	return (
		<Dialog
			open={dialogOpen}
			onClose={handleOnClickClose}
			maxWidth="xs"
			fullWidth
		>
			<DialogTitle>
				{dialogTitle}
				<IconButton aria-label="Close" onClick={handleOnClickClose}>
					<CloseOutlined />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<TextField
					id="name"
					{...register("name")}
					error={errors?.name?.message ? true : false}
					helperText={errors?.name?.message?.toString()}
					label="Name"
					type="text"
					value={name}
					onChange={(e) => setName(e.target.value)}
					fullWidth
					focused
					tabIndex={1}
					autoFocus
					variant="outlined"
					className="mb-8"
				/>
				<FormControlLabel
					className="mb-ng-8"
					control={
						<Checkbox
							checked={isActive}
							onClick={() => setIsActive(!isActive)}
						/>
					}
					label="Active"
				/>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={handleSubmit(handleOnClickSubmit)}>
					Submit
				</Button>
				<Button variant="outlined" onClick={handleOnClickClose}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}
