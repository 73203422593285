import { CloseOutlined } from "@mui/icons-material";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Typography,
} from "@mui/material";
import { getDealTypeText } from "../../utility/enums/common/deal-type";

type ComponentProps = {
	dialogOpen: boolean;
	handleOnClickClose: any;
	data: any;
};
export function ViewProperty(props: ComponentProps) {
	const { dialogOpen, handleOnClickClose, data } = props;

	return (
		<Dialog
			open={dialogOpen}
			onClose={handleOnClickClose}
			maxWidth="md"
			fullWidth
		>
			<DialogTitle>
				Property Details
				<IconButton aria-label="Close" onClick={handleOnClickClose}>
					<CloseOutlined />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Property For
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{getDealTypeText(data?.propertyFor)}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Available From
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.availableFrom}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Bhk/Office
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.bhkOfficeName}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Building
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.buildingName}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Area
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.areaName}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Block
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.block}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Flat Number
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.flatNumber}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Measurement
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.measurementName}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Super Buildup Area
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.superBuiltupArea}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Carpet Area
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.carpetArea}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Buildup Area
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.builtupArea}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Furniture Status
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.furnitureStatusName}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Parking
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.parking}
						</Typography>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Key Status
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.keyStatus}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Price
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.propertyPrice}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Owner Name
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.ownerName}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Mobile Number
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.mobile}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Mobile Number 1
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.mobile1}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Mobile Number 2
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.mobile2}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Source
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.sourceName}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Status
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.propertyStatusName}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Typography variant="body2" className="text-light-grey">
							Commission
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.comission}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<Typography variant="body2" className="text-light-grey">
							Address
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.email}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Typography variant="body2" className="text-light-grey">
							Remark
						</Typography>
						<Typography variant="body1" className="text-dark-black">
							{data?.remark}
						</Typography>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={handleOnClickClose}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}
