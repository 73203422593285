import { AxiosResponse } from "axios";
import httpClient from "./base-service";
import { IHttpsResponse } from "../utility/interfaces";
import { APIRoutes } from "../utility/app-routes";

export const getAllEnquiry = async (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(APIRoutes.getAllEnquiries, request);

export const addUpdateEnquiry = async (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(APIRoutes.addUpdateEnquiry, request);

export const getAllEnquiryRemark = async (
	enquiryId: number
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.get<IHttpsResponse<any>>(
		`${APIRoutes.getEnquiryRemark}/${enquiryId}`
	);

export const addEnquiryRemark = async (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(APIRoutes.addEnquiryRemark, request);

export const checkMobileNumber = async (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(APIRoutes.checkMobileNumber, request);
