import { CloseOutlined } from "@mui/icons-material";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
import { sendEmail } from "../../services";
import { HttpStatusCodes } from "../../utility/enums";
import { toast } from "react-toastify";

type ComponentProps = {
	emailId: string;
	subjectLine: string;
	messageLine: string;
	dialogOpen: boolean;
	handleOnClickClose: any;
};
export function SendEmail(props: ComponentProps) {
	const { emailId, subjectLine, messageLine, dialogOpen, handleOnClickClose } =
		props;

	const handleOnClickSend = async () => {
		const response = await sendEmail(email, subject, message);
		if (response?.data?.status === HttpStatusCodes.Ok) {
			toast.success(response.data.message);
			handleOnClickClose();
		} else toast.error(response.data.message);
	};

	// Yup resolver
	const formValidationSchema = object({
		email: string()
			.email("Invalid email format.")
			.required("Email is required."),
		message: string().required("Message is required."),
		subject: string().required("Subject is required."),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm({
		resolver: yupResolver(formValidationSchema),
	});
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [subject, setSubject] = useState("");

	useEffect(() => {
		if (dialogOpen) {
			setEmail(emailId || "");
			setMessage(messageLine || "");
			setSubject(subjectLine || "");
		}
		reset();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dialogOpen]);

	useEffect(() => {
		setValue("email", email);
		setValue("subject", subject);
		setValue("message", message);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [email]);

	return (
		<Dialog
			open={dialogOpen}
			onClose={handleOnClickClose}
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle>
				Send email
				<IconButton aria-label="Close" onClick={handleOnClickClose}>
					<CloseOutlined />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<TextField
							{...register("email")}
							error={errors?.email?.message ? true : false}
							helperText={errors?.email?.message?.toString()}
							id="email"
							label="Email"
							type="text"
							fullWidth
							variant="outlined"
							value={email}
							onChange={(e) => {
								setEmail(e.target.value);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<TextField
							{...register("subject")}
							error={errors?.subject?.message ? true : false}
							helperText={errors?.subject?.message?.toString()}
							id="subject"
							label="subject"
							type="text"
							fullWidth
							variant="outlined"
							value={subject}
							onChange={(e) => {
								setSubject(e.target.value);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<TextField
							{...register("message")}
							error={errors?.message?.message ? true : false}
							helperText={errors?.message?.message?.toString()}
							id="message"
							multiline
							minRows={10}
							label="Message"
							type="text"
							fullWidth
							variant="outlined"
							value={message}
							onChange={(e) => {
								setMessage(e.target.value);
							}}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={handleSubmit(handleOnClickSend)}>
					Send
				</Button>
				<Button variant="outlined" onClick={handleOnClickClose}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}
