import {
	Avatar,
	IconButton,
	TableCell,
	TableRow,
	Tooltip,
} from "@mui/material";
import { useState } from "react";
import { IDropDownFields } from "../../utility/interfaces";
import { DeleteCommonRecord } from "../common/common-master-record/delete-common-record";
import { APIRoutes } from "../../utility/app-routes";
import {
	AddCircleOutline,
	Delete,
	Edit,
	Visibility,
	Phone,
	Email,
} from "@mui/icons-material";
import { whatsappIcon } from "../../../src/assets/images";
import { AddUpdateEnquiry } from "./add-update-dialog";
import { AddNewRemark } from "./add-remarks";
import { ViewEnquiry } from "./view-enquiry";
import { ViewAllRemarks } from "./view-all-remarks";
import { IChildActivityDropDownFields } from "../../utility/interfaces/master";
import { SendEmail } from "../common/send-email";
import { IDropDownFieldsWithBhkofficeId } from "../../utility/interfaces/common";

type ComponentProps = {
	data: any;
	getAllRecords: any;
	bhkOfficeList: IDropDownFields[];
	budgetList: IDropDownFieldsWithBhkofficeId[];
	sourceList: IDropDownFields[];
	enquiryStatusList: IDropDownFields[];
	nonUseList: IDropDownFields[];
	employeeList: IDropDownFields[];
	areaList: IDropDownFields[];
	parentActivityList: IDropDownFields[];
	childActivityList: IChildActivityDropDownFields[];
	getPageLoadData: any;
};

export function EnquiryRow(props: ComponentProps) {
	const {
		data,
		getAllRecords,
		bhkOfficeList,
		budgetList,
		sourceList,
		enquiryStatusList,
		nonUseList,
		employeeList,
		areaList,
		parentActivityList,
		childActivityList,
		getPageLoadData,
	} = props;
	const [isUpdateRecordOpen, setIsUpdateRecordOpen] = useState(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [isAddNewRemarkOpen, setIsAddNewRemarkOpen] = useState(false);
	const [isViewEnquiryOpen, setIsViewEnquiryOpen] = useState(false);
	const [isViewAllRemarkOpen, setIsViewAllRemarkOpen] = useState(false);
	const [isSendEmail, setIsSendEmail] = useState(false);

	return (
		<>
			<TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
				<TableCell>{data.createdOn}</TableCell>
				<TableCell>{data.nameOfClient}</TableCell>
				<TableCell>{data.mobileNo}</TableCell>
				<TableCell>{data.bhkOfficeName}</TableCell>
				<TableCell>{data.enquiryStatusName}</TableCell>
				<TableCell
					style={{ maxWidth: "300px", whiteSpace: "normal", cursor: "pointer" }}
				>
					{data.lastRemark ? (
						<div onClick={() => setIsViewAllRemarkOpen(true)}>
							{data.lastRemark}
						</div>
					) : (
						<></>
					)}
				</TableCell>
				<TableCell>{data.nfd}</TableCell>
				<TableCell>{data.assignToName}</TableCell>
				<TableCell className="action-col" align="center">
					<div className="button-wrapper">
						<Tooltip title="Add Remark">
							<IconButton
								onClick={() => setIsAddNewRemarkOpen(true)}
								className="ml-ng-10"
							>
								<AddCircleOutline className="blue-icon" />
							</IconButton>
						</Tooltip>
						<Tooltip title="WhatsApp">
							<IconButton
								target="_blank"
								href={`http://wa.me/${data.mobileNo}`}
							>
								<Avatar
									style={{ height: "20px", width: "20px" }}
									src={whatsappIcon}
									variant="square"
									className="blue-icon"
								/>
							</IconButton>
						</Tooltip>
						<Tooltip title="View Enquiry">
							<IconButton onClick={() => setIsViewEnquiryOpen(true)}>
								<Visibility className="blue-icon" />
							</IconButton>
						</Tooltip>
						<Tooltip title="Send Email">
							<IconButton onClick={() => setIsSendEmail(true)}>
								<Email className="blue-icon" />
							</IconButton>
						</Tooltip>
					</div>
					<div className="button-wrapper">
						<Tooltip title="Call">
							<IconButton
								href={`tel: +91 ${data.mobileNo}`}
								aria-label="Phone"
								color="primary"
							>
								<Phone />
							</IconButton>
						</Tooltip>
						<Tooltip title="Edit Enquiry">
							<IconButton
								onClick={() => setIsUpdateRecordOpen(true)}
								aria-label="Edit"
								color="primary"
							>
								<Edit />
							</IconButton>
						</Tooltip>
						<Tooltip title="Delete Enquiry">
							<IconButton
								aria-label="Delete"
								onClick={() => setIsDeleteDialogOpen(true)}
								color="primary"
							>
								<Delete />
							</IconButton>
						</Tooltip>
					</div>
				</TableCell>
			</TableRow>
			{/* <TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={60}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1 }}>
							<Typography gutterBottom component="div">
								{
									<>
										<div
											style={{
												lineHeight: "1em",
												overflow: "hidden",
												whiteSpace: "initial",
												textAlign: "justify",
											}}
										>
											{"Last remark:  "}
											{data?.lastRemark}
											{"\u00A0"}
											{"\u00A0"}
											{"\u00A0"}
											{"\u00A0"}
											<Link
												onClick={() => setIsViewAllRemarkOpen(true)}
												style={{ cursor: "pointer" }}
												role="button"
											>
												View all remarks
											</Link>
										</div>
									</>
								}
							</Typography>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow> */}
			{/* Update record  */}
			<AddUpdateEnquiry
				dialogOpen={isUpdateRecordOpen}
				handleOnClickClose={() => setIsUpdateRecordOpen(false)}
				data={data}
				getAllRecords={getAllRecords}
				bhkOfficeList={bhkOfficeList}
				budgetList={budgetList}
				sourceList={sourceList}
				enquiryStatusList={enquiryStatusList}
				nonUseList={nonUseList}
				employeeList={employeeList}
				areaList={areaList}
				getPageLoadData={getPageLoadData}
			/>
			<DeleteCommonRecord
				getAllRecords={getAllRecords}
				apiPath={`${APIRoutes.deleteEnquiry}/${data.id}`}
				dialogOpen={isDeleteDialogOpen}
				handleOnClickClose={() => setIsDeleteDialogOpen(false)}
			/>
			<AddNewRemark
				childActivityList={childActivityList}
				parentActivityList={parentActivityList}
				data={data}
				enquiryStatusList={enquiryStatusList}
				dialogOpen={isAddNewRemarkOpen}
				handleOnClickClose={() => setIsAddNewRemarkOpen(false)}
				getAllRecords={getAllRecords}
				getPageLoadData={getPageLoadData}
			/>
			<ViewEnquiry
				data={data}
				dialogOpen={isViewEnquiryOpen}
				handleOnClickClose={() => setIsViewEnquiryOpen(false)}
			/>
			<ViewAllRemarks
				rowData={data}
				dialogOpen={isViewAllRemarkOpen}
				handleOnClickClose={() => setIsViewAllRemarkOpen(false)}
			/>
			<SendEmail
				dialogOpen={isSendEmail}
				handleOnClickClose={() => setIsSendEmail(false)}
				emailId={data.email}
				subjectLine=""
				messageLine=""
			/>
		</>
	);
}
