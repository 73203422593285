import Resizer from "react-image-file-resizer";

export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
};

export const getComparator = <Key extends keyof any>(
	order: any,
	orderBy: Key
): ((a: { [key in Key]: string }, b: { [key in Key]: string }) => number) => {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = <T>(
	array: T[],
	comparator: (a: T, b: T) => number
) => {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
};

const loaderDiv =
	typeof window !== "undefined" && document.getElementById("loader");

export const showLoader = () => {
	if (loaderDiv) {
		loaderDiv.style.opacity = "1";
		loaderDiv.style.visibility = "visible";
	}
};

export const hideLoader = () => {
	if (loaderDiv) {
		loaderDiv.style.opacity = "0";
		loaderDiv.style.visibility = "hidden";
	}
};

export const resizeImageAndGetBase64 = (file: any) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			300,
			300,
			"JPEG",
			100,
			0,
			(uri) => {
				resolve(uri);
			},
			"base64"
		);
	});
export const getBase64FromImage = (file: any) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
};

export const isInputOnlyNumber = (value: any) => {
	if (!isNaN(+value)) {
		return true;
	}
	return false;
};

export const isInputOnlyChar = (value: any) => {
	var letters = /^[A-Za-z]+$/;
	if (value.match(letters) || value === "") {
		return true;
	}
	return false;
};
