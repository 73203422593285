import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	IconButton,
	Radio,
	RadioGroup,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
} from "@mui/material";
import Header from "../../../components/layouts/header";
import Sidebar from "../../../components/layouts/sidebar";
import {
	Add,
	ArrowDownwardOutlined,
	ArrowUpwardOutlined,
	CloseOutlined,
	Delete,
	Edit,
} from "@mui/icons-material";
import React from "react";

const BudgetMinMax: React.FC = () => {
	// Table Pagination
	const [page, setPage] = React.useState(2);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// Dialog
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const dialogHandleClickOpen = () => {
		setDialogOpen(true);
	};
	const dialogHandleClose = () => {
		setDialogOpen(false);
	};

	const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
	const deleteDialogHandleClickOpen = () => {
		setDeleteDialogOpen(true);
	};
	const deleteDialogHandleClose = () => {
		setDeleteDialogOpen(false);
	};
	return (
		<div className="main-content">
			<Header />
			<div className="page-content">
				<Sidebar />
				<div className="page-inner">
					<div className="page-heading-wrapper">
						<h4 className="page-heading">Budget Min Max</h4>
						<IconButton
							aria-label="add"
							color="primary"
							className="primary-icon"
							onClick={dialogHandleClickOpen}
						>
							<Add />
						</IconButton>
					</div>
					<div className="white-paper">
						<div className="table-heading-block">
							<TextField label="Search" variant="outlined" />
						</div>
						<div className="table-negative-space">
							<TableContainer>
								<Table aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>
												For
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowDownwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Min
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Max
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell className="action-col" align="center">
												Action
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell>Buy</TableCell>
											<TableCell>5 Lac</TableCell>
											<TableCell>10 Lac</TableCell>
											<TableCell className="action-col" align="center">
												<div className="button-wrapper">
													<IconButton
														aria-label="Edit"
														color="primary"
														onClick={dialogHandleClickOpen}
													>
														<Edit />
													</IconButton>
													<IconButton
														aria-label="Delete"
														color="primary"
														onClick={deleteDialogHandleClickOpen}
													>
														<Delete />
													</IconButton>
												</div>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Buy</TableCell>
											<TableCell>10 Lac</TableCell>
											<TableCell>20 Lac</TableCell>
											<TableCell className="action-col" align="center">
												<div className="button-wrapper">
													<IconButton aria-label="Edit" color="primary">
														<Edit />
													</IconButton>
													<IconButton
														aria-label="Delete"
														color="primary"
														onClick={deleteDialogHandleClickOpen}
													>
														<Delete />
													</IconButton>
												</div>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Buy</TableCell>
											<TableCell>20 Lac</TableCell>
											<TableCell>30 Lac</TableCell>
											<TableCell className="action-col" align="center">
												<div className="button-wrapper">
													<IconButton aria-label="Edit" color="primary">
														<Edit />
													</IconButton>
													<IconButton
														aria-label="Delete"
														color="primary"
														onClick={deleteDialogHandleClickOpen}
													>
														<Delete />
													</IconButton>
												</div>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Rent</TableCell>
											<TableCell>5000</TableCell>
											<TableCell>10000</TableCell>
											<TableCell className="action-col" align="center">
												<div className="button-wrapper">
													<IconButton aria-label="Edit" color="primary">
														<Edit />
													</IconButton>
													<IconButton
														aria-label="Delete"
														color="primary"
														onClick={deleteDialogHandleClickOpen}
													>
														<Delete />
													</IconButton>
												</div>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</div>
						<div className="pagination-wrapper">
							<TablePagination
								component="div"
								count={100}
								page={page}
								onPageChange={handleChangePage}
								rowsPerPage={rowsPerPage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</div>
					</div>
				</div>

				{/* Add New Dialog */}
				<Dialog
					open={dialogOpen}
					onClose={dialogHandleClose}
					maxWidth="sm"
					fullWidth
				>
					<DialogTitle>
						Add New Budget Min Max
						<IconButton aria-label="Close" onClick={dialogHandleClose}>
							<CloseOutlined />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<FormControl className="grid-radio radio-control">
									<FormLabel id="demo-row-radio-buttons-group-label">
										For
									</FormLabel>
									<RadioGroup
										row
										aria-labelledby="demo-row-radio-buttons-group-label"
										name="row-radio-buttons-group"
									>
										<FormControlLabel
											value="rent"
											control={<Radio />}
											label="Rent"
										/>
										<FormControlLabel
											value="buy"
											control={<Radio />}
											label="Buy"
										/>
									</RadioGroup>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormControlLabel
									className="mb-ng-8"
									control={<Checkbox defaultChecked />}
									label="Active"
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									id="min title"
									label="Min Title"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									id="max title"
									label="Max Title"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									id="min value"
									label="Min Value"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									id="max value"
									label="Max Value"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" onClick={dialogHandleClose}>
							Submit
						</Button>
						<Button variant="outlined" onClick={dialogHandleClose}>
							Cancel
						</Button>
					</DialogActions>
				</Dialog>

				{/* Delete Dialog */}
				<Dialog
					open={deleteDialogOpen}
					onClose={deleteDialogHandleClose}
					maxWidth="xs"
					fullWidth
				>
					<DialogTitle>
						Delete Confirmation
						<IconButton aria-label="Close" onClick={deleteDialogHandleClose}>
							<CloseOutlined />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure want to delete this recored?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="error"
							onClick={deleteDialogHandleClose}
						>
							Delete
						</Button>
						<Button variant="outlined" onClick={deleteDialogHandleClose}>
							Cancel
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		</div>
	);
};
export default BudgetMinMax;
