import {
	Autocomplete,
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
} from "@mui/material";
import { CustomDatePicker } from "../common/custom-date-picker";
import {
	IDropDownFields,
	IDropDownFieldsWithBhkofficeId,
	IEnquirySearchCriteria,
} from "../../utility/interfaces/common";
import {
	DealType,
	getDealTypeText,
} from "../../utility/enums/common/deal-type";
import { useEffect } from "react";

type ComponentProps = {
	isOpen: boolean;
	values: IEnquirySearchCriteria;
	onChangeValue: any;
	bhkOfficeList: IDropDownFields[];
	budgetList: IDropDownFieldsWithBhkofficeId[];
	sourceList: IDropDownFields[];
	enquiryStatusList: IDropDownFields[];
	nonUseList: IDropDownFields[];
	employeeList: IDropDownFields[];
	handleOnClearSearchCriteria: any;
	handleOnClickSearch: any;
	getPageLoadData: any;
};
export function EnquiryFilter(props: ComponentProps) {
	const {
		isOpen,
		values,
		onChangeValue,
		bhkOfficeList,
		budgetList,
		sourceList,
		enquiryStatusList,
		nonUseList,
		employeeList,
		handleOnClearSearchCriteria,
		handleOnClickSearch,
		getPageLoadData,
	} = props;

	useEffect(() => {
		if (isOpen) getPageLoadData();
	}, [isOpen]);

	return (
		<>
			{isOpen ? (
				<div className="white-paper mb-20">
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<FormControl className="grid-radio radio-control">
								<FormLabel id="demo-row-radio-buttons-group-label">
									For
								</FormLabel>
								<RadioGroup
									row
									aria-labelledby="demo-row-radio-buttons-group-label"
									name="row-radio-buttons-group"
									value={values.enquiryFor}
								>
									<FormControlLabel
										value={DealType.All}
										control={<Radio />}
										label={getDealTypeText(DealType.All)}
										onClick={() => onChangeValue(DealType.All, "enquiryFor")}
									/>
									<FormControlLabel
										value={DealType.Buy}
										control={<Radio />}
										label={getDealTypeText(DealType.Buy)}
										onClick={() => onChangeValue(DealType.Buy, "enquiryFor")}
									/>

									<FormControlLabel
										value={DealType.Sell}
										control={<Radio />}
										label={getDealTypeText(DealType.Sell)}
										onClick={() => onChangeValue(DealType.Sell, "enquiryFor")}
									/>
									<FormControlLabel
										value={DealType.Rent}
										control={<Radio />}
										label={getDealTypeText(DealType.Rent)}
										onClick={() => onChangeValue(DealType.Rent, "enquiryFor")}
									/>
								</RadioGroup>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<FormControl fullWidth>
								<InputLabel id="bhk-office-label">Bhk/Office</InputLabel>
								<Select
									labelId="bhk-office-label"
									id="bhk-office-select"
									value={values.bhkOfficeId}
									label="Bhk/Office"
									onChange={(e) =>
										onChangeValue(+e.target.value, "bhkOfficeId")
									}
								>
									<MenuItem value="0">Select</MenuItem>
									{bhkOfficeList.map((record) => {
										return (
											<MenuItem
												key={record.id}
												disabled={!record.isActive}
												value={record.id}
											>
												{record.text}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
							{/* <FormControl fullWidth>
								<Autocomplete
									id="budget-select"
									disablePortal
									options={bhkOfficeList.map((x) => {
										return {
											label: x.text,
											id: x.id,
										};
									})}
									clearIcon={false}
									popupIcon={false}
									value={
										bhkOfficeList.find((x) => x.id === values.bhkOfficeId)
											? {
													label: bhkOfficeList.find(
														(x) => x.id === values.bhkOfficeId
													)?.text,
													id: bhkOfficeList.find(
														(x) => x.id === values.bhkOfficeId
													)?.id,
											  }
											: undefined
									}
									sx={{ width: 300 }}
									renderInput={(params) => (
										<TextField {...params} label="Bhk/Office" />
									)}
									onChange={(e, value) =>
										onChangeValue(value?.id, "bhkOfficeId")
									}
								/>
							</FormControl> */}
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<FormControl fullWidth>
								<InputLabel id="budget-label">Budget</InputLabel>
								<Select
									labelId="budget-label"
									id="budget-select"
									value={values.budgetId}
									label="Budget"
									onChange={(e) => onChangeValue(+e.target.value, "budgetId")}
								>
									<MenuItem value="0">Select</MenuItem>
									{budgetList
										.filter(
											(x) =>
												x.bhkOfficeId === values.bhkOfficeId ||
												values.bhkOfficeId === 0
										)
										.map((record) => {
											return (
												<MenuItem
													key={record.id}
													disabled={!record.isActive}
													value={record.id}
												>
													{record.text}
												</MenuItem>
											);
										})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<FormControl fullWidth className="mb-8">
								<InputLabel id="source-label">Source</InputLabel>
								<Select
									labelId="source-label"
									id="source-select"
									value={values.sourceId}
									label="Source"
									onChange={(e) => onChangeValue(+e.target.value, "sourceId")}
								>
									<MenuItem value="0">Select</MenuItem>
									{sourceList.map((record) => {
										return (
											<MenuItem
												key={record.id}
												disabled={!record.isActive}
												value={record.id}
											>
												{record.text}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={4}
							lg={3}
							className="datepicker-wrappper"
						>
							<CustomDatePicker
								disabled={false}
								label="Created From"
								onChangeValue={(newValue: any) =>
									onChangeValue(newValue, "fromNfd")
								}
								value={values.fromNfd}
								maxDate={values.toNfd}
								minDate={new Date().setFullYear(new Date().getFullYear() - 100)}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={4}
							lg={3}
							className="datepicker-wrappper"
						>
							<CustomDatePicker
								disabled={false}
								label="Created To"
								onChangeValue={(newValue: any) =>
									onChangeValue(newValue, "toNfd")
								}
								value={values.toNfd}
								maxDate={new Date().setFullYear(new Date().getFullYear() + 100)}
								minDate={values.fromNfd}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<FormControl fullWidth>
								<InputLabel id="status-label">Status</InputLabel>
								<Select
									labelId="status-label"
									id="status-select"
									value={values.enquiryStatusId}
									label="Status"
									onChange={(e) =>
										onChangeValue(+e.target.value, "enquiryStatusId")
									}
								>
									<MenuItem value="0">Select</MenuItem>
									{enquiryStatusList.map((record) => {
										return (
											<MenuItem
												key={record.id}
												disabled={!record.isActive}
												value={record.id}
											>
												{record.text}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						{/* <Grid item xs={12} sm={6} md={4} lg={3}>
							<FormControl fullWidth className="mb-8">
								<InputLabel id="non-use-label">Non Use</InputLabel>
								<Select
									labelId="non-use-label"
									id="non-use-select"
									value={values.nonUseId}
									label="Non Use"
									onChange={(e) => onChangeValue(+e.target.value, "nonUseId")}
								>
									<MenuItem value="0">Select</MenuItem>
									{nonUseList.map((record) => {
										return (
											<MenuItem
												key={record.id}
												disabled={!record.isActive}
												value={record.id}
											>
												{record.text}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid> */}
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<FormControl fullWidth className="mb-8">
								<InputLabel id="employee-label">Employee</InputLabel>
								<Select
									labelId="employee-label"
									id="employee-select"
									value={values.employeeId}
									label="Employee"
									onChange={(e) => onChangeValue(+e.target.value, "employeeId")}
								>
									<MenuItem value="0">Select</MenuItem>
									{employeeList.map((record) => {
										return (
											<MenuItem
												key={record.id}
												disabled={!record.isActive}
												value={record.id}
											>
												{record.text}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
					<Grid
						container
						spacing={2}
						alignItems="center"
						justifyContent="end"
						sx={{ pt: { xs: "20px", sm: 0 } }}
					>
						<Grid item xs="auto">
							<Button
								style={{ marginRight: 10 }}
								variant="contained"
								onClick={handleOnClickSearch}
							>
								Search
							</Button>
							<Button variant="outlined" onClick={handleOnClearSearchCriteria}>
								Clear
							</Button>
						</Grid>
					</Grid>
				</div>
			) : null}
		</>
	);
}
