import { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { number, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { IDropDownFields } from "../../utility/interfaces";
import { phoneRegExp } from "../../utility/constant";
import {
	DealType,
	getDealTypeText,
} from "../../utility/enums/common/deal-type";
import { CustomDatePicker } from "../common/custom-date-picker";
import { addUpdateProperty } from "../../services/property-service";
import { toast } from "react-toastify";
import { HttpStatusCodes } from "../../utility/enums";
import dayjs from "dayjs";
import { isInputOnlyNumber } from "../../utility/helpers/common";
import { getAllCommonRecords } from "../../services";
import { APIRoutes } from "../../utility/app-routes";

type ComponentProps = {
	dialogOpen: boolean;
	handleOnClickClose: any;
	getAllRecords: any;
	data: any | undefined;
};
export function AddUpdateProperty(props: ComponentProps) {
	const { dialogOpen, handleOnClickClose, data, getAllRecords } = props;

	const [id, setId] = useState(0);
	const [propertyFor, setPropertyFor] = useState(DealType.Rent);
	const [bhkOfficeId, setBhkOfficeId] = useState(0);
	const [buildingId, setBuildingId] = useState(0);
	const [areaId, setAreaId] = useState(0);
	const [address, setAddress] = useState("");
	const [block, setBlock] = useState("");
	const [flatNumber, setFlatNumber] = useState(0);
	const [measurementId, setMeasurementId] = useState(0);
	const [size, setSize] = useState(0);
	const [carpetArea, setCarpetArea] = useState(0);
	const [builtupConstruction, setBuiltupConstruction] = useState(0);
	const [furnitureStatusId, setFurnitureStatusId] = useState(0);
	const [parking, setParking] = useState(0);
	const [keyStatus, setKeyStatus] = useState("");
	const [propertyPrice, setPropertyPrice] = useState(0);
	const [ownerName, setOwnerName] = useState("");
	const [mobile, setMobile] = useState("");
	const [mobile1, setMobile1] = useState("");
	const [mobile2, setMobile2] = useState("");
	const [sourceId, setSourceId] = useState(0);
	const [propertyStatusId, setPropertyStatusId] = useState(0);
	const [comission, setComission] = useState("");
	const [remark, setRemark] = useState("");
	const [availableFrom, setAvailableFrom] = useState(0);

	const handleOnClickSubmit = async () => {
		const response = await addUpdateProperty({
			id,
			propertyFor,
			bhkOfficeId,
			buildingId,
			areaId,
			address,
			block,
			flatNumber: flatNumber.toString(),
			measurementId,
			superBuiltupArea: size,
			carpetArea,
			builtupArea: builtupConstruction,
			furnitureStatusId,
			parking: parking.toString(),
			keyStatus,
			propertyPrice,
			ownerName,
			mobile,
			mobile1,
			mobile2,
			sourceId,
			propertyStatusId,
			comission,
			remark,
			userId: 0,
			availableFrom,
		});
		if (response?.data?.status === HttpStatusCodes.Ok) {
			await getAllRecords();
			toast.success(response.data.message);
		} else toast.error(response.data.message);

		getAllRecords();
		handleOnClickClose();
	};

	const [bhkOfficeList, setBhkOfficeList] = useState<IDropDownFields[]>([]);
	const [propertyTypeList, setPropertyTypeList] = useState<IDropDownFields[]>(
		[]
	);
	const [propertySourceList, setPropertySourceList] = useState<
		IDropDownFields[]
	>([]);
	const [propertyStatusList, setPropertyStatusList] = useState<
		IDropDownFields[]
	>([]);

	const [buildingList, setBuildingList] = useState<IDropDownFields[]>([]);
	const [areaList, setAreaList] = useState<IDropDownFields[]>([]);
	const [measurementList, setMeasurementList] = useState<IDropDownFields[]>([]);
	const [furnitureStatusList, setFurnitureStatusList] = useState<
		IDropDownFields[]
	>([]);
	const [segmentList, setSegmentList] = useState<IDropDownFields[]>([]);

	// Yup resolver
	const formValidationSchema = object({
		bhkOffice: number().min(1, "Please select bhk Office."),
		building: number().min(1, "Please select building."),
		area: number().min(1, "Please select area."),
		address: string().required("Address is required."),
		block: string().required("Block is required."),
		faltNumber: number().min(1, "Enter valid flat number."),
		measurement: number().min(1, "Please select measurement."),
		size: number().min(1, "Super builtup area is required."),
		carpetArea: number().min(1, "CarpetArea is required."),
		builtup: number().min(1, "Enter valid builtup area."),
		furnitureStatus: number().min(1, "Please select furniture status."),
		parking: number().min(0, "Enter valid number of parking."),
		keyStatus: string().required("Key status is required."),
		propertyPrice: number().min(1, "Enter valid property price."),
		ownerName: string().required("Owner name is required."),
		mobileNo: string()
			.matches(phoneRegExp, "Mobile number is not valid.")
			.min(10, "Mobile number is not valid.")
			.max(10, "Mobile number is not valid."),
		source: number().min(1, "Please select source."),
		status: number().min(1, "Please select status."),
		commission: string().required("Commission is required."),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm({
		resolver: yupResolver(formValidationSchema),
	});

	useEffect(() => {
		if (dialogOpen) {
			setValue("bhkOffice", bhkOfficeId);
			setValue("building", buildingId);
			setValue("area", areaId);
			setValue("address", address);
			setValue("block", block);
			setValue("faltNumber", flatNumber);
			setValue("measurement", measurementId);
			setValue("size", size);
			setValue("carpetArea", carpetArea);
			setValue("builtup", builtupConstruction);
			setValue("furnitureStatus", furnitureStatusId);
			setValue("parking", parking);
			setValue("keyStatus", keyStatus);
			setValue("propertyPrice", propertyPrice);
			setValue("ownerName", ownerName);
			setValue("mobileNo", mobile);
			setValue("source", sourceId);
			setValue("status", propertyStatusId);
			setValue("commission", comission);
		}
	}, [bhkOfficeId]);

	const getPageLoadRecords = async () => {
		const { data } = await getAllCommonRecords(APIRoutes.propertyOnPageLoad);
		if (data && data.status === HttpStatusCodes.Ok) {
			setPropertyTypeList(data.response.propertyTypeList);
			setSegmentList(data.response.segmentList);
			setBhkOfficeList(data.response.bhkOfficeList);
			setBuildingList(data.response.buildingList);
			setAreaList(data.response.areaList);
			setMeasurementList(data.response.measurementList);
			setFurnitureStatusList(data.response.furnitureStatusList);
			setPropertySourceList(data.response.sourceList);
			setPropertyStatusList(data.response.propertyStatusList);
		} else {
			toast.error(data.message);
		}
	};

	useEffect(() => {
		if (dialogOpen) {
			getPageLoadRecords();
			setId(data?.id || 0);
			setPropertyFor(data?.propertyFor || DealType.Rent);
			setBhkOfficeId(data?.bhkOfficeId || 0);
			setBuildingId(data?.buildingId || 0);
			setAreaId(data?.areaId || 0);
			setAddress(data?.address || "");
			setBlock(data?.block || "");
			setFlatNumber(data?.flatNumber || 0);
			setMeasurementId(data?.measurementId || 0);
			setSize(data?.superBuiltupArea || "");
			setCarpetArea(data?.carpetArea || "");
			setBuiltupConstruction(data?.builtupArea || 0);
			setFurnitureStatusId(data?.furnitureStatusId || 0);
			setParking(data?.parking || 0);
			setKeyStatus(data?.keyStatus || "");
			setPropertyPrice(data?.propertyPrice || 0);
			setOwnerName(data?.ownerName || "");
			setMobile(data?.mobile || "");
			setMobile1(data?.mobile1 || "");
			setMobile2(data?.mobile2 || "");
			setSourceId(data?.sourceId || 0);
			setPropertyStatusId(data?.propertyStatusId || 0);
			setComission(data?.comission || "");
			setRemark(data?.remark || "");
			setAvailableFrom(data?.availableFrom || dayjs(new Date()));
		}
		reset();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dialogOpen]);

	return (
		<Dialog
			open={dialogOpen}
			onClose={handleOnClickClose}
			maxWidth="lg"
			fullWidth
		>
			<DialogTitle>
				Add New Property
				<IconButton aria-label="Close" onClick={handleOnClickClose}>
					<CloseOutlined />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<FormControl className="grid-radio radio-control">
							<FormLabel id="demo-row-radio-buttons-group-label">For</FormLabel>
							<RadioGroup
								row
								aria-labelledby="demo-row-radio-buttons-group-label"
								name="row-radio-buttons-group"
								value={propertyFor}
							>
								<FormControlLabel
									value={DealType.Rent}
									control={<Radio />}
									label={getDealTypeText(DealType.Rent)}
									onClick={() => setPropertyFor(DealType.Rent)}
								/>
								<FormControlLabel
									value={DealType.Sell}
									control={<Radio />}
									label={getDealTypeText(DealType.Sell)}
									onClick={() => setPropertyFor(DealType.Sell)}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>
					{propertyFor === DealType.Rent ? (
						<Grid
							item
							xs={12}
							sm={6}
							md={4}
							lg={3}
							className="datepicker-wrappper"
						>
							<CustomDatePicker
								disabled={false}
								label="Available from"
								onChangeValue={(newValue: any) => setAvailableFrom(newValue)}
								value={availableFrom}
								maxDate={new Date().setFullYear(new Date().getFullYear() + 200)}
								minDate={new Date().setFullYear(new Date().getFullYear() - 200)}
							/>
						</Grid>
					) : null}
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<FormControl fullWidth>
							<InputLabel id="bhk-office-label">Bhk/Office</InputLabel>
							<Select
								{...register("bhkOffice")}
								error={errors?.bhkOffice?.message ? true : false}
								labelId="bhk-office-label"
								id="bhkOffice"
								value={bhkOfficeId}
								label="Bhk/Office"
								onChange={(e) => setBhkOfficeId(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{bhkOfficeList.map((record) => {
									return (
										<MenuItem
											key={record.id}
											disabled={!record.isActive}
											value={record.id}
										>
											{record.text}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.bhkOffice?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<FormControl fullWidth>
							<InputLabel id="bhk-office-label">Building</InputLabel>
							<Select
								{...register("building")}
								error={errors?.building?.message ? true : false}
								labelId="bhk-office-label"
								id="building"
								value={buildingId}
								label="Building"
								onChange={(e) => setBuildingId(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{buildingList.map((record) => {
									return (
										<MenuItem
											key={record.id}
											disabled={!record.isActive}
											value={record.id}
										>
											{record.text}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.building?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<FormControl fullWidth>
							<InputLabel id="bhk-office-label">Area</InputLabel>
							<Select
								{...register("area")}
								error={errors?.area?.message ? true : false}
								labelId="bhk-office-label"
								id="area"
								value={areaId}
								label="Area"
								onChange={(e) => setAreaId(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{areaList.map((record) => {
									return (
										<MenuItem
											key={record.id}
											disabled={!record.isActive}
											value={record.id}
										>
											{record.text}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.area?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={8} lg={6}>
						<TextField
							id="address"
							{...register("address")}
							error={errors?.address ? true : false}
							helperText={errors?.address?.message?.toString()}
							label="Address"
							type="text"
							fullWidth
							variant="outlined"
							value={address}
							onChange={(e) => setAddress(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							id="block"
							{...register("block")}
							error={errors?.block?.message ? true : false}
							helperText={errors?.block?.message?.toString()}
							label="Block"
							type="text"
							fullWidth
							variant="outlined"
							value={block}
							onChange={(e) => setBlock(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							id="faltNumber"
							{...register("faltNumber")}
							error={errors?.faltNumber?.message ? true : false}
							helperText={errors?.faltNumber?.message?.toString()}
							label="Flat Number"
							type="text"
							fullWidth
							variant="outlined"
							value={flatNumber}
							onChange={(e) => {
								if (isInputOnlyNumber(e.target.value)) {
									setFlatNumber(+e.target.value);
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<FormControl fullWidth className="mb-8">
							<InputLabel id="measurement-label">Measurement</InputLabel>
							<Select
								{...register("measurement")}
								error={errors?.measurement?.message ? true : false}
								labelId="measurement-label"
								id="measurement-select"
								value={measurementId}
								label="Measurement"
								onChange={(e) => setMeasurementId(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{measurementList.map((record) => {
									return (
										<MenuItem
											key={record.id}
											disabled={!record.isActive}
											value={record.id}
										>
											{record.text}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.measurement?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							id="size"
							{...register("size")}
							error={errors?.size?.message ? true : false}
							helperText={errors?.size?.message?.toString()}
							label="Super Builtup Area"
							type="text"
							fullWidth
							variant="outlined"
							value={size}
							onChange={(e) => {
								if (isInputOnlyNumber(e.target.value)) setSize(+e.target.value);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							id="carpetArea"
							{...register("carpetArea")}
							error={errors?.carpetArea?.message ? true : false}
							helperText={errors?.carpetArea?.message?.toString()}
							label="Carpet Area"
							type="text"
							fullWidth
							variant="outlined"
							value={carpetArea}
							onChange={(e) => {
								if (isInputOnlyNumber(e.target.value)) {
									setCarpetArea(+e.target.value);
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							id="builtup"
							{...register("builtup")}
							error={errors?.builtup?.message ? true : false}
							helperText={errors?.builtup?.message?.toString()}
							label="Builtup Area"
							type="text"
							fullWidth
							variant="outlined"
							value={builtupConstruction}
							onChange={(e) => {
								if (isInputOnlyNumber(e.target.value)) {
									setBuiltupConstruction(+e.target.value);
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<FormControl fullWidth>
							<InputLabel id="furnitureStatus-label">
								Furniture Status
							</InputLabel>
							<Select
								{...register("furnitureStatus")}
								error={errors?.furnitureStatus?.message ? true : false}
								labelId="furnitureStatus-label"
								id="furnitureStatus-select"
								value={furnitureStatusId}
								label="Furniture Status"
								onChange={(e) => setFurnitureStatusId(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{furnitureStatusList.map((record) => {
									return (
										<MenuItem
											key={record.id}
											disabled={!record.isActive}
											value={record.id}
										>
											{record.text}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.furnitureStatus?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							id="parking"
							{...register("parking")}
							error={errors?.parking?.message ? true : false}
							helperText={errors?.parking?.message?.toString()}
							label="Parking"
							type="text"
							fullWidth
							variant="outlined"
							value={parking}
							onChange={(e) => {
								if (isInputOnlyNumber(e.target.value)) {
									setParking(+e.target.value);
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							id="keyStatus"
							{...register("keyStatus")}
							error={errors?.keyStatus?.message ? true : false}
							helperText={errors?.keyStatus?.message?.toString()}
							label="Key Status"
							type="text"
							fullWidth
							variant="outlined"
							value={keyStatus}
							onChange={(e) => setKeyStatus(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							id="propertyPrice"
							{...register("propertyPrice")}
							error={errors?.propertyPrice?.message ? true : false}
							helperText={errors?.propertyPrice?.message?.toString()}
							label="Property price"
							type="text"
							fullWidth
							variant="outlined"
							value={propertyPrice}
							onChange={(e) => {
								if (isInputOnlyNumber(e.target.value)) {
									setPropertyPrice(+e.target.value);
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							id="ownerName"
							{...register("ownerName")}
							error={errors?.ownerName?.message ? true : false}
							helperText={errors?.ownerName?.message?.toString()}
							label="Owner name"
							type="text"
							fullWidth
							variant="outlined"
							value={ownerName}
							onChange={(e) => setOwnerName(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							id="mobileNo"
							{...register("mobileNo")}
							error={errors?.mobileNo?.message ? true : false}
							helperText={errors?.mobileNo?.message?.toString()}
							label="Mobile no"
							type="text"
							fullWidth
							variant="outlined"
							value={mobile}
							inputProps={{ maxLength: 10 }}
							onChange={(e) => {
								if (isInputOnlyNumber(e.target.value)) {
									setMobile(e.target.value);
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							id="mobileNo1"
							label="Mobile no 1 (Optional)"
							type="text"
							fullWidth
							variant="outlined"
							value={mobile1}
							inputProps={{ maxLength: 10 }}
							onChange={(e) => {
								if (isInputOnlyNumber(e.target.value)) {
									setMobile1(e.target.value);
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							id="mobileNo2"
							label="Mobile no 2 (Optional)"
							type="text"
							fullWidth
							variant="outlined"
							value={mobile2}
							inputProps={{ maxLength: 10 }}
							onChange={(e) => {
								if (isInputOnlyNumber(e.target.value)) {
									setMobile2(e.target.value);
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<FormControl fullWidth className="mb-8">
							<InputLabel id="source-label">Source</InputLabel>
							<Select
								{...register("source")}
								error={errors?.source?.message ? true : false}
								labelId="source-label"
								id="source"
								value={sourceId}
								label="Source"
								onChange={(e) => setSourceId(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{propertySourceList.map((record) => {
									return (
										<MenuItem
											key={record.id}
											disabled={!record.isActive}
											value={record.id}
										>
											{record.text}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.source?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<FormControl fullWidth>
							<InputLabel id="status-label">Status</InputLabel>
							<Select
								{...register("status")}
								error={errors?.status?.message ? true : false}
								labelId="status-label"
								id="status"
								value={propertyStatusId}
								label="Status"
								onChange={(e) => setPropertyStatusId(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{propertyStatusList.map((record) => {
									return (
										<MenuItem
											key={record.id}
											disabled={!record.isActive}
											value={record.id}
										>
											{record.text}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.status?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<TextField
							{...register("commission")}
							error={errors?.commission?.message ? true : false}
							helperText={errors?.commission?.message?.toString()}
							id="commission"
							label="Commission"
							type="text"
							fullWidth
							variant="outlined"
							value={comission}
							onChange={(e) => {
								setComission(e.target.value);
							}}
						/>
					</Grid>
					<Grid item xs={12} lg={12}>
						<TextField
							id="remark"
							label="Remark"
							type="text"
							multiline
							minRows={4}
							fullWidth
							variant="outlined"
							value={remark}
							onChange={(e) => setRemark(e.target.value)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={handleSubmit(handleOnClickSubmit)}>
					Submit
				</Button>
				<Button variant="outlined" onClick={handleOnClickClose}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}
