import { TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";

type ComponentProps = {
	data: any;
};
export function TeamActivityRow(props: ComponentProps) {
	const { data } = props;

	return (
		<>
			<TableRow>
				<TableCell>{data.userName}</TableCell>
				<TableCell>{data.pageName}</TableCell>
				<TableCell>{data.description}</TableCell>
				<TableCell>
					{data.createdOn
						? dayjs(data.createdOn).format("DD-MM-YYYY hh:mm A")
						: ""}
				</TableCell>
			</TableRow>
		</>
	);
}
