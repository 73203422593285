import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { TeamLoginRow } from "./team-login-row";
import { NoRecordFound } from "./no-record-found";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAllCommonRecords } from "../../services";
import { APIRoutes } from "../../utility/app-routes";
import { HttpStatusCodes } from "../../utility/enums";

type ComponentProps = {
	dialogOpen: boolean;
	handleOnClickClose: any;
};
export function TeamLoginDialog(props: ComponentProps) {
	const { dialogOpen, handleOnClickClose } = props;
	const [tableData, setTableData] = useState<any>([]);

	const getPageLoadRecords = async () => {
		const { data } = await getAllCommonRecords(APIRoutes.getTeamLoginsReport);
		if (data && data.status === HttpStatusCodes.Ok) {
			setTableData(data.response);
		} else {
			toast.error(data.message);
		}
	};
	useEffect(() => {
		if (dialogOpen) getPageLoadRecords();
	}, [dialogOpen]);

	// useEffects
	return (
		<Dialog
			open={dialogOpen}
			onClose={handleOnClickClose}
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle>
				Team's Login
				<IconButton aria-label="Close" onClick={handleOnClickClose}>
					<CloseOutlined />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<div className="white-paper">
					<div className="table-negative-space">
						<TableContainer>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>User Name</TableCell>
										<TableCell>Last Login Time</TableCell>
										<TableCell>Last Login IP</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{tableData.map((data: any) => {
										return <TeamLoginRow data={data} key={data.id} />;
									})}
								</TableBody>
								{tableData?.length <= 0 && <NoRecordFound />}
							</Table>
						</TableContainer>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}
