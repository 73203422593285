import { Grid, IconButton, Link, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DashboardEnquiryTable } from "../../components";
import {
	IDashboardSearchCriteria,
	IDropDownFields,
	IDropDownFieldsWithBhkofficeId,
} from "../../utility/interfaces/common";
import { IChildActivityDropDownFields } from "../../utility/interfaces/master";
import { getAllCommonRecords, getAllDashboardEnquiries } from "../../services";
import { HttpStatusCodes } from "../../utility/enums";
import { toast } from "react-toastify";
import { APIRoutes } from "../../utility/app-routes";
import { DashboardSearchType } from "../../utility/enums/common/dashboard";
import { tableRowPerPage } from "../../utility/constant";
import { FileDownload, Refresh } from "@mui/icons-material";
import { downloadExcel } from "react-export-table-to-excel";
import {
	DealType,
	getDealTypeText,
} from "../../utility/enums/common/deal-type";

const Dashboard: React.FC = () => {
	const [searchCriteria, setSearchCriteria] =
		useState<IDashboardSearchCriteria>({
			rowPerPage: tableRowPerPage,
			pageNumber: 1,
			sortBy: "",
			sortDirection: "asc",
			text: "",
			nfdType: DashboardSearchType.Today,
		});
	const [bhkOfficeList, setBhkOfficeList] = useState<IDropDownFields[]>([]);
	const [todaysCount, setTodaysCount] = useState(0);
	const [tomorrowsCount, setTomorrowsCount] = useState(0);
	const [pendingCount, setPendingCount] = useState(0);
	const [budgetList, setBudgetList] = useState<
		IDropDownFieldsWithBhkofficeId[]
	>([]);
	const [sourceList, setSourceList] = useState<IDropDownFields[]>([]);
	const [enquiryStatusList, setEnquiryStatusList] = useState<IDropDownFields[]>(
		[]
	);
	const [nonUseList, setNonUseList] = useState<IDropDownFields[]>([]);
	const [employeeList, setEmployeeList] = useState<IDropDownFields[]>([]);
	const [areaList, setAreaList] = useState<IDropDownFields[]>([]);
	const [childActivityList, setChildActivityList] = useState<
		IChildActivityDropDownFields[]
	>([]);
	const [parentActivityList, setParentActivityList] = useState<
		IDropDownFields[]
	>([]);
	const [tableData, setTableData] = useState<any>([]);
	const [totalRecords, setTotalRecords] = useState(0);

	const getAllRecords = async () => {
		const { data } = await getAllDashboardEnquiries(searchCriteria);
		if (data && data.status === HttpStatusCodes.Ok) {
			setTotalRecords(data.totalCount);
			setTodaysCount(data.response.todaysCount);
			setTomorrowsCount(data.response.tomorrowsCount);
			setPendingCount(data.response.pendingCount);
			if (data.response.enquiryList.length > 0)
				setTableData(data.response.enquiryList);
			else setTableData([]);
		} else if (data && data.status === HttpStatusCodes.NotFound) {
			setTableData([]);
		} else {
			setTableData([]);
			toast.error(data.message);
		}
	};
	const exportToExcel = async () => {
		if (tableData.length > 0) {
			const header = [
				"Client Name",
				"Mobile Number",
				"Email",
				"Enquiry For",
				"Source Name",
				"Bhk Office",
				"Segment",
				"Property Type",
				"Enquiry Status",
				"Budget",
				"Non Use",
				"Remark",
				"Last Remark",
				"Assign To",
				"Assign By",
				"NFD",
				"Is Closed?",
				"Mobile1",
				"Mobile2",
				"Mobile3",
				"Created On",
			];
			const body: any = [];

			tableData.forEach((element: any) => {
				body.push([
					element.nameOfClient,
					element.mobileNo,
					element.email,
					getDealTypeText(element.enquiryFor),
					element.sourceName,
					element.bhkOfficeName,
					element.segmentName,
					element.propertyTypeName,
					element.enquiryStatusName,
					element.budgetName,
					element.nonuseName,
					element.remark,
					element.lastRemark,
					element.assignToName,
					element.assignByName,
					element.nfd,
					element.isClosed === true ? "Yes" : "No",
					element.mobile1,
					element.mobile2,
					element.mobile3,
					element.createdOn,
				]);
			});
			downloadExcel({
				fileName: `${Date.now()}_Enquiry_Export`,
				sheet: "Enquiry",
				tablePayload: {
					header,
					body,
				},
			});
		} else {
			toast.error("No data found in below table.");
		}
	};

	const handleOnChangeSearchCriteria = (value: any, name: string) => {
		if (name !== "pageNumber")
			setSearchCriteria({ ...searchCriteria, pageNumber: 1, [name]: value });
		else setSearchCriteria({ ...searchCriteria, [name]: value });
	};

	const getPageLoadRecords = async () => {
		const { data } = await getAllCommonRecords(APIRoutes.enquiryOnPageLoad);
		if (data && data.status === HttpStatusCodes.Ok) {
			setSourceList(data.response.sourceList);
			setBhkOfficeList(data.response.bhkOfficeList);
			setBudgetList(data.response.budgetList);
			setNonUseList(data.response.nonuseList);
			setEmployeeList(data.response.userList);
			setAreaList(data.response.areaList);
			setEnquiryStatusList(data.response.enquiryStatusList);
			setChildActivityList(data.response.activityChildList);
			setParentActivityList(data.response.activityParentList);
		} else {
			toast.error(data.message);
		}
	};

	// useEffects
	useEffect(() => {
		getAllRecords();
	}, [searchCriteria]);

	return (
		<>
			<div className="page-inner">
				<div className="page-heading-wrapper">
					<h4 className="page-heading">Dashboard</h4>
					<Tooltip title="Refresh">
						<IconButton
							aria-label="refresh"
							color="primary"
							className="primary-icon"
							onClick={getAllRecords}
						>
							<Refresh />
						</IconButton>
					</Tooltip>
					<Tooltip title="Export To Excel">
						<IconButton
							aria-label="export"
							color="primary"
							className="primary-icon"
							onClick={exportToExcel}
						>
							<FileDownload />
						</IconButton>
					</Tooltip>
				</div>
				<Grid container spacing={2} className="mb-20">
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<Link
							title="Today"
							className={`white-paper ${
								searchCriteria.nfdType === DashboardSearchType.Today
									? "statistic-cardActive"
									: "statistic-card"
							}`}
							underline="none"
							onClick={() =>
								handleOnChangeSearchCriteria(
									DashboardSearchType.Today,
									"nfdType"
								)
							}
						>
							<Typography variant="h6">Today</Typography>
							<Typography variant="body1">{todaysCount}</Typography>
						</Link>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<Link
							title="Tomorrow"
							className={`white-paper ${
								searchCriteria.nfdType === DashboardSearchType.Tomorrow
									? "statistic-cardActive"
									: "statistic-card"
							}`}
							underline="none"
							onClick={() =>
								handleOnChangeSearchCriteria(
									DashboardSearchType.Tomorrow,
									"nfdType"
								)
							}
						>
							<Typography variant="h6">Tomorrow</Typography>
							<Typography variant="body1">{tomorrowsCount}</Typography>
						</Link>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<Link
							title="Pending"
							underline="none"
							className={`white-paper ${
								searchCriteria.nfdType === DashboardSearchType.Pending
									? "statistic-cardActive"
									: "statistic-card"
							}`}
							onClick={() =>
								handleOnChangeSearchCriteria(
									DashboardSearchType.Pending,
									"nfdType"
								)
							}
						>
							<Typography variant="h6">Pending</Typography>
							<Typography variant="body1">{pendingCount}</Typography>
						</Link>
					</Grid>
				</Grid>
				<DashboardEnquiryTable
					tableData={tableData}
					getAllRecords={getAllRecords}
					bhkOfficeList={bhkOfficeList}
					budgetList={budgetList}
					sourceList={sourceList}
					enquiryStatusList={enquiryStatusList}
					nonUseList={nonUseList}
					employeeList={employeeList}
					areaList={areaList}
					childActivityList={childActivityList}
					handleOnChangeSearchCriteria={handleOnChangeSearchCriteria}
					parentActivityList={parentActivityList}
					searchCriteria={searchCriteria}
					getPageLoadData={getPageLoadRecords}
					totalRecords={totalRecords}
				/>
			</div>
		</>
	);
};
export default Dashboard;
