import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormControlLabel,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
} from "@mui/material";
import Header from "../../../components/layouts/header";
import Sidebar from "../../../components/layouts/sidebar";
import {
	Add,
	ArrowDownwardOutlined,
	ArrowUpwardOutlined,
	CloseOutlined,
	Delete,
	Edit,
} from "@mui/icons-material";
import React from "react";

const BhkOffice: React.FC = () => {
	// Table Pagination
	const [page, setPage] = React.useState(2);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// Dialog
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const dialogHandleClickOpen = () => {
		setDialogOpen(true);
	};
	const dialogHandleClose = () => {
		setDialogOpen(false);
	};

	const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
	const deleteDialogHandleClickOpen = () => {
		setDeleteDialogOpen(true);
	};
	const deleteDialogHandleClose = () => {
		setDeleteDialogOpen(false);
	};

	// Select
	const [selectPropertyType, setSelectPropertyType] = React.useState("");
	const selectHandleChange = (event: SelectChangeEvent) => {
		setSelectPropertyType(event.target.value as string);
	};
	const [selectSegment, setSelectSegment] = React.useState("");
	const selectSegmentChange = (event: SelectChangeEvent) => {
		setSelectSegment(event.target.value as string);
	};

	return (
		<div className="main-content">
			<Header />
			<div className="page-content">
				<Sidebar />
				<div className="page-inner">
					<div className="page-heading-wrapper">
						<h4 className="page-heading">Bhk/Office</h4>
						<IconButton
							aria-label="add"
							color="primary"
							className="primary-icon"
							onClick={dialogHandleClickOpen}
						>
							<Add />
						</IconButton>
					</div>
					<div className="white-paper">
						<div className="table-heading-block">
							<TextField label="Search" variant="outlined" />
						</div>
						<div className="table-negative-space">
							<TableContainer>
								<Table aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>
												Name
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowDownwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Property Type
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Segement
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Actives
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowDownwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell className="action-col" align="center">
												Action
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell>1BHK</TableCell>
											<TableCell>Residential</TableCell>
											<TableCell>Flat</TableCell>
											<TableCell>
												<FormControlLabel
													control={<Checkbox defaultChecked />}
													label="Active"
												/>
											</TableCell>
											<TableCell className="action-col" align="center">
												<div className="button-wrapper">
													<IconButton
														aria-label="Edit"
														color="primary"
														onClick={dialogHandleClickOpen}
													>
														<Edit />
													</IconButton>
													<IconButton
														aria-label="Delete"
														color="primary"
														onClick={deleteDialogHandleClickOpen}
													>
														<Delete />
													</IconButton>
												</div>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>2BHK</TableCell>
											<TableCell>Residential</TableCell>
											<TableCell>Villa</TableCell>
											<TableCell>
												<FormControlLabel
													control={<Checkbox defaultChecked />}
													label="Active"
												/>
											</TableCell>
											<TableCell className="action-col" align="center">
												<div className="button-wrapper">
													<IconButton aria-label="Edit" color="primary">
														<Edit />
													</IconButton>
													<IconButton
														aria-label="Delete"
														color="primary"
														onClick={deleteDialogHandleClickOpen}
													>
														<Delete />
													</IconButton>
												</div>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>3BHK</TableCell>
											<TableCell>Residential</TableCell>
											<TableCell>Villa</TableCell>
											<TableCell>
												<FormControlLabel
													control={<Checkbox defaultChecked />}
													label="Active"
												/>
											</TableCell>
											<TableCell className="action-col" align="center">
												<div className="button-wrapper">
													<IconButton aria-label="Edit" color="primary">
														<Edit />
													</IconButton>
													<IconButton
														aria-label="Delete"
														color="primary"
														onClick={deleteDialogHandleClickOpen}
													>
														<Delete />
													</IconButton>
												</div>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>2BHK</TableCell>
											<TableCell>Residential</TableCell>
											<TableCell>Flat</TableCell>
											<TableCell>
												<FormControlLabel
													control={<Checkbox defaultChecked />}
													label="Active"
												/>
											</TableCell>
											<TableCell className="action-col" align="center">
												<div className="button-wrapper">
													<IconButton aria-label="Edit" color="primary">
														<Edit />
													</IconButton>
													<IconButton
														aria-label="Delete"
														color="primary"
														onClick={deleteDialogHandleClickOpen}
													>
														<Delete />
													</IconButton>
												</div>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</div>
						<div className="pagination-wrapper">
							<TablePagination
								component="div"
								count={100}
								page={page}
								onPageChange={handleChangePage}
								rowsPerPage={rowsPerPage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</div>
					</div>
				</div>

				{/* Add New Dialog */}
				<Dialog
					open={dialogOpen}
					onClose={dialogHandleClose}
					maxWidth="xs"
					fullWidth
				>
					<DialogTitle>
						Add New Bhk/Office
						<IconButton aria-label="Close" onClick={dialogHandleClose}>
							<CloseOutlined />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<TextField
							id="name"
							label="Name"
							type="text"
							fullWidth
							variant="outlined"
							className="mb-20"
						/>
						<FormControl fullWidth className="mb-20">
							<InputLabel id="select-label">Property Type</InputLabel>
							<Select
								labelId="select-label"
								id="simple-select"
								value={selectPropertyType}
								label="Property Type"
								onChange={selectHandleChange}
							>
								<MenuItem value="PhoneCall">Phone Call</MenuItem>
								<MenuItem value="visit">Visit</MenuItem>
								<MenuItem value="meeting">Meeting</MenuItem>
								<MenuItem value="deal">Deal</MenuItem>
							</Select>
						</FormControl>
						<FormControl fullWidth className="mb-8">
							<InputLabel id="select-label">Segment</InputLabel>
							<Select
								labelId="select-label"
								id="simple-select"
								value={selectSegment}
								label="Segment"
								onChange={selectSegmentChange}
							>
								<MenuItem value="PhoneCall">Phone Call</MenuItem>
								<MenuItem value="visit">Visit</MenuItem>
								<MenuItem value="meeting">Meeting</MenuItem>
								<MenuItem value="deal">Deal</MenuItem>
							</Select>
						</FormControl>
						<FormControlLabel
							className="mb-ng-8"
							control={<Checkbox defaultChecked />}
							label="Active"
						/>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" onClick={dialogHandleClose}>
							Submit
						</Button>
						<Button variant="outlined" onClick={dialogHandleClose}>
							Cancel
						</Button>
					</DialogActions>
				</Dialog>

				{/* Delete Dialog */}
				<Dialog
					open={deleteDialogOpen}
					onClose={deleteDialogHandleClose}
					maxWidth="xs"
					fullWidth
				>
					<DialogTitle>
						Delete Confirmation
						<IconButton aria-label="Close" onClick={deleteDialogHandleClose}>
							<CloseOutlined />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure want to delete this recored?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="error"
							onClick={deleteDialogHandleClose}
						>
							Delete
						</Button>
						<Button variant="outlined" onClick={deleteDialogHandleClose}>
							Cancel
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		</div>
	);
};
export default BhkOffice;
