import React, { useContext, useEffect, useState } from "react";
import {
	Avatar,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import { CloseOutlined, FileUploadOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import {
	addUser,
	changeUserPassword,
	getAllCommonRecords,
	getRecordsOnPageLoad,
	updateUser,
	updateUserPassword,
} from "../../../services";
import { HttpStatusCodes } from "../../../utility/enums";
import { IDropDownFields, IUserDetails } from "../../../utility/interfaces";
import {
	hideLoader,
	isInputOnlyChar,
	isInputOnlyNumber,
	resizeImageAndGetBase64,
	showLoader,
} from "../../../utility/helpers/common";
import { APIRoutes } from "../../../utility/app-routes";
import { CustomDatePicker } from "../custom-date-picker";
import {
	UserContext,
	UserDispatchContext,
} from "../../../contexts/user-context";
import dayjs from "dayjs";
import { number, object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { passwordRegex, phoneRegExp } from "../../../utility/constant";

type ComponentProps = {
	dialogOpen: boolean;
	handleOnClickClose: any;
	getAllRecords: any;
	recordId: number | undefined;
};
export function AddUpdateUserDialog(props: ComponentProps) {
	const setUserContext = useContext(UserDispatchContext);
	const currentUserDetails: IUserDetails = useContext(UserContext);

	const { dialogOpen, handleOnClickClose, recordId, getAllRecords } = props;
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [mobile, setMobile] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [dateOfJoin, setDateOfJoin] = useState(dayjs(new Date()));
	const [isActive, setIsActive] = useState(true);
	const [id, setId] = useState(0);
	const [role, setRole] = useState(0);
	const [parent, setParent] = useState(0);
	const [profileImage, setProfileImage] = useState<any>(null);
	const [roles, setRoles] = useState<IDropDownFields[]>([]);
	const [users, setUsers] = useState<IDropDownFields[]>([]);

	const getPageLoadRecords = async () => {
		const { data } = await getAllCommonRecords(
			APIRoutes.getUserDetails + `${recordId}`
		);
		if (data && data.status === HttpStatusCodes.Ok) {
			setFirstName(data.response.firstName);
			setDateOfJoin(data.response.dateOfJoin);
			setLastName(data.response.lastName);
			setMobile(data.response.mobile);
			setEmail(data.response.email);
			setPassword(data.response.password);
			setIsActive(data.response.isActive);
			setId(data.response.id);
			setRole(data.response.roleId);
			setParent(data.response.parentId);
			setProfileImage(data.response.profileImage);
		} else if (data && data.status === HttpStatusCodes.NotFound) {
		} else {
			toast.error(data.message);
		}
	};
	const handleOnClickSubmit = async () => {
		let response = null;
		if (id === null || id === 0) {
			const isPasswordValidate = passwordRegex.test(password);
			if (!password || password === "" || !isPasswordValidate) {
				toast.error(
					"Please make your password more secure, for an example: Example@123"
				);
				return;
			}
			response = await addUser({
				firstName,
				lastName,
				email,
				password,
				phoneNumber: mobile,
				roleId: role,
				parentId: parent,
				isActive,
				profileImage,
				dateOfJoin,
			});
		} else
			response = await updateUser({
				firstName,
				lastName,
				email,
				phoneNumber: mobile,
				roleId: role,
				isActive,
				parentId: parent,
				id,
				profileImage,
				dateOfJoin,
			});
		if (response?.data.status === HttpStatusCodes.Ok) {
			currentUserDetails.ProfileImage = profileImage;
			setUserContext(currentUserDetails);
			handleOnClickClose();
			await getAllRecords();
			toast.success(response.data.message);
		} else toast.error(response.data.message);
	};
	const handlePropfilePhotoSelect = async (event: any) => {
		showLoader();
		if (event.target.files.length > 0) {
			if (
				event.target.files[0].type !== "image/jpeg" &&
				event.target.files[0].type !== "image/png"
			) {
				toast.error("Selected file not allowed.");
				return;
			}
			const base64 = await resizeImageAndGetBase64(event.target.files[0]);
			setProfileImage(base64);
		}
		hideLoader();
	};

	const getDataOnPageLoad = async () => {
		const { data } = await getRecordsOnPageLoad(
			APIRoutes.getDataOnMasterUserPageLoad
		);
		if (data && data.status === HttpStatusCodes.Ok) {
			setUsers(data.response.userList);
			setRoles(data.response.roleList);
		} else if (data && data.status === HttpStatusCodes.NotFound) {
			setUsers([]);
			setRoles([]);
		} else {
			setUsers([]);
			setRoles([]);
			toast.error(data.message);
		}
	};

	const handleOnUpdatePassword = async () => {
		const isPasswordValidate = passwordRegex.test(password);
		if (!password || password === "" || !isPasswordValidate) {
			toast.error(
				"Please make password more secure, for an example: Example@123"
			);
			return;
		}
		const response = await changeUserPassword({
			userId: recordId,
			newPassword: password,
		});
		if (response?.status === HttpStatusCodes.Ok) {
			toast.success("Successfully updated.");
		} else toast.error("Something went wrong, please try again.");
	};

	// useEffects
	useEffect(() => {
		if (dialogOpen) {
			getDataOnPageLoad();
		}
		if (dialogOpen && recordId !== 0) {
			getPageLoadRecords();
		} else {
			setFirstName("");
			setLastName("");
			setMobile("");
			setEmail("");
			setPassword("");
			setIsActive(true);
			setId(0);
			setRole(0);
			setParent(0);
			setProfileImage("");
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dialogOpen]);
	const formValidationSchema = object({
		fname: string().required("First name is required"),
		lname: string().required("Last name is required"),
		role: number().min(1, "Please select role."),
		parent: number().min(1, "Please select parent."),
		mobile: string()
			.matches(phoneRegExp, "Mobile number is not valid.")
			.min(10, "Mobile number is not valid.")
			.max(10, "Mobile number is not valid."),
		email: string()
			.email("Invalid email format.")
			.required("Email is required."),
		password: string().required("Password is required"),
	});

	useEffect(() => {
		setValue("fname", firstName);
		setValue("lname", lastName);
		setValue("role", role);
		setValue("parent", parent);
		setValue("mobile", mobile);
		setValue("email", email);
		setValue("password", password);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstName]);

	// Yup resolver
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
	} = useForm({
		resolver: yupResolver(formValidationSchema),
	});
	useEffect(() => {
		reset();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Dialog
			open={dialogOpen}
			onClose={handleOnClickClose}
			maxWidth="md"
			fullWidth
		>
			<DialogTitle>
				User
				<IconButton aria-label="Close" onClick={handleOnClickClose}>
					<CloseOutlined />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2} alignItems="center" className="mb-20">
					<Grid item xs={12} sm={12} md={12}>
						<Avatar
							style={{ textAlign: "center", maxWidth: "50px", height: "50px" }}
							alt="Profile Image"
							src={profileImage}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<TextField
							id="fname"
							{...register("fname")}
							error={errors?.fname?.message ? true : false}
							helperText={errors?.fname?.message?.toString()}
							label="First Name"
							type="text"
							fullWidth
							variant="outlined"
							value={firstName}
							onChange={(e) => {
								if (isInputOnlyChar(e.target.value))
									setFirstName(e.target.value);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<TextField
							id="lname"
							{...register("lname")}
							error={errors?.lname?.message ? true : false}
							helperText={errors?.lname?.message?.toString()}
							label="Last Name"
							type="text"
							fullWidth
							variant="outlined"
							value={lastName}
							onChange={(e) => {
								if (isInputOnlyChar(e.target.value))
									setLastName(e.target.value);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<FormControl fullWidth>
							<InputLabel id="select-label">Role</InputLabel>
							<Select
								labelId="select-label"
								error={errors?.role?.message ? true : false}
								id="role"
								{...register("role")}
								value={role}
								label="Role"
								onChange={(e: any) => setRole(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{roles?.map((record: any) => {
									return (
										<MenuItem disabled={!record.isActive} value={record.id}>
											{record.text}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.role?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<FormControl fullWidth>
							<InputLabel id="select-label">Parent</InputLabel>
							<Select
								labelId="select-label"
								error={errors?.parent?.message ? true : false}
								id="parent"
								{...register("parent")}
								value={parent}
								label="Parent"
								onChange={(e: any) => setParent(+e.target.value)}
							>
								<MenuItem value="0">Select</MenuItem>
								{users?.map((record: any) => {
									return (
										<MenuItem disabled={!record.isActive} value={record.id}>
											{record.text}
										</MenuItem>
									);
								})}
							</Select>
							<FormHelperText error id="component-error-text">
								<>{errors?.parent?.message}</>
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<TextField
							id="mobile"
							{...register("mobile")}
							error={errors?.mobile?.message ? true : false}
							helperText={errors?.mobile?.message?.toString()}
							label="Mobile"
							disabled={id === null || id === 0 ? false : true}
							type="text"
							inputProps={{ maxLength: 10 }}
							fullWidth
							variant="outlined"
							value={mobile}
							onChange={(e) => {
								if (isInputOnlyNumber(e.target.value)) {
									setMobile(e.target.value);
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<TextField
							id="email"
							{...register("email")}
							error={errors?.email?.message ? true : false}
							helperText={errors?.email?.message?.toString()}
							disabled={id === null || id === 0 ? false : true}
							label="Email"
							type="email"
							fullWidth
							variant="outlined"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</Grid>
					{id === null || id === 0 ? (
						<Grid item xs={12} sm={6} md={4}>
							<TextField
								{...register("password")}
								error={errors?.password?.message ? true : false}
								helperText={errors?.password?.message?.toString()}
								id="password"
								label="Password"
								type="text"
								fullWidth
								variant="outlined"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</Grid>
					) : null}
					<Grid item xs={12} sm={6} md={4}>
						<label htmlFor="contained-button-file" className="upload">
							<input
								onChange={handlePropfilePhotoSelect}
								id="contained-button-file"
								type="file"
								accept="image/*"
							/>
							<Button
								variant="outlined"
								component="span"
								startIcon={<FileUploadOutlined />}
								className="w-full"
							>
								Upload Profile
							</Button>
						</label>
					</Grid>
					<Grid item xs={12} sm={6} md={4} className="datepicker-wrappper">
						<CustomDatePicker
							disabled={false}
							label="Date of join"
							value={dateOfJoin}
							onChangeValue={(newValue: any) => setDateOfJoin(newValue)}
							maxDate={new Date().setFullYear(new Date().getFullYear() + 200)}
							minDate={new Date().setFullYear(new Date().getFullYear() - 200)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<FormControlLabel
							className="mb-ng-8"
							control={
								<Checkbox
									checked={isActive}
									onClick={() => setIsActive(!isActive)}
								/>
							}
							label="Active"
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={handleSubmit(handleOnClickSubmit)}>
					Submit
				</Button>
				<Button variant="outlined" onClick={handleOnClickClose}>
					Cancel
				</Button>
			</DialogActions>
			{recordId !== null && recordId !== 0 ? (
				<>
					<DialogContent>
						<Grid container spacing={2} alignItems="center" className="mb-20">
							<Grid item xs={12} sm={6} md={4}>
								<TextField
									id="password"
									label="Password"
									type="text"
									fullWidth
									variant="outlined"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" onClick={handleOnUpdatePassword}>
							Update Password
						</Button>
					</DialogActions>
				</>
			) : null}
		</Dialog>
	);
}
