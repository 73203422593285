import { AxiosResponse } from "axios";
import httpClient from "./base-service";
import { IHttpsResponse } from "../utility/interfaces";
import { APIRoutes } from "../utility/app-routes";

export const getRecordsOnPageLoad = async (
	url: string
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.get<IHttpsResponse<any>>(url);

export const sendEmail = async (
	recipientEmail: string,
	mailSubject: string,
	mailBody: string
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(APIRoutes.sendEmail, {
		recipientEmail,
		mailSubject,
		mailBody,
	});
