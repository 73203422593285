import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	IconButton,
	InputLabel,
	Link,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	SelectChangeEvent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import Header from "../../../components/layouts/header";
import { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Sidebar from "../../../components/layouts/sidebar";
import {
	Add,
	AddCircleOutline,
	ArrowDownwardOutlined,
	ArrowUpwardOutlined,
	CloseOutlined,
	Delete,
	Edit,
	FilterAltOutlined,
	SendOutlined,
	Visibility,
} from "@mui/icons-material";
import React, { useState } from "react";
// import { whatsappIcon } from "../../../assets/images";

const Enquiry: React.FC = () => {
	// Table Pagination
	const [page, setPage] = React.useState(2);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// filterState
	const [filter, setFilter] = useState(false);

	// DatePicker
	const [value, setValue] = React.useState<Dayjs | null>(null);

	// Dialog
	const [dialogAddOpen, setDialogAddOpen] = React.useState(false);
	const dialogAddClickOpen = () => {
		setDialogAddOpen(true);
	};
	const dialogAddClose = () => {
		setDialogAddOpen(false);
	};

	const [dialogAddNewFollowupOpen, setDialogAddNewFollowupOpen] =
		React.useState(false);
	const dialogAddNewFollowupClickOpen = () => {
		setDialogAddNewFollowupOpen(true);
	};
	const dialogAddNewFollowupClose = () => {
		setDialogAddNewFollowupOpen(false);
	};

	const [enquiryDialogOpen, setEnquiryDialogOpen] = React.useState(false);
	const enquiryDialogHandleClickOpen = () => {
		setEnquiryDialogOpen(true);
	};
	const enquiryDialogHandleClose = () => {
		setEnquiryDialogOpen(false);
	};

	const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
	const deleteDialogHandleClickOpen = () => {
		setDeleteDialogOpen(true);
	};
	const deleteDialogHandleClose = () => {
		setDeleteDialogOpen(false);
	};

	const [dialogRemarkOpen, setDialogRemarkOpen] = React.useState(false);
	const dialogRemarkClickOpen = () => {
		setDialogRemarkOpen(true);
	};
	const dialogRemarkClose = () => {
		setDialogRemarkOpen(false);
	};

	// Select
	const [selectSource, setSelectSource] = React.useState("");
	const selectSourceChange = (event: SelectChangeEvent) => {
		setSelectSource(event.target.value as string);
	};

	const [selectPropertyType, setSelectPropertyType] = React.useState("");
	const selectPropertyTypeChange = (event: SelectChangeEvent) => {
		setSelectPropertyType(event.target.value as string);
	};

	const [selectSegment, setSelectSegment] = React.useState("");
	const selectSegmentChange = (event: SelectChangeEvent) => {
		setSelectSegment(event.target.value as string);
	};

	const [selectBhkOffice, setSelectBhkOffice] = React.useState("");
	const selectBhkOfficeChange = (event: SelectChangeEvent) => {
		setSelectBhkOffice(event.target.value as string);
	};

	const [selectStatus, setSelectStatus] = React.useState("");
	const selectStatusChange = (event: SelectChangeEvent) => {
		setSelectStatus(event.target.value as string);
	};

	const [selectBudget, setSelectBudget] = React.useState("");
	const selectBudgetChange = (event: SelectChangeEvent) => {
		setSelectBudget(event.target.value as string);
	};

	const [selectAssignTo, setSelectAssignTo] = React.useState("");
	const selectAssignToChange = (event: SelectChangeEvent) => {
		setSelectAssignTo(event.target.value as string);
	};

	const [selectParent, setSelectParent] = React.useState("");
	const selectParentChange = (event: SelectChangeEvent) => {
		setSelectParent(event.target.value as string);
	};

	const [selectChild, setSelectChild] = React.useState("");
	const selectChildChange = (event: SelectChangeEvent) => {
		setSelectChild(event.target.value as string);
	};

	const [selectStatus2, setSelectStatus2] = React.useState("");
	const selectStatus2Change = (event: SelectChangeEvent) => {
		setSelectStatus2(event.target.value as string);
	};

	const [selectNonUse, setSelectNonUse] = React.useState("");
	const selectNonUseChange = (event: SelectChangeEvent) => {
		setSelectNonUse(event.target.value as string);
	};

	const [selectEmployee, setSelectEmployee] = React.useState("");
	const selectEmployeeChange = (event: SelectChangeEvent) => {
		setSelectEmployee(event.target.value as string);
	};
	return (
		<div className="main-content">
			<Header />
			<div className="page-content">
				<Sidebar />
				<div className="page-inner">
					<div className="page-heading-wrapper">
						<h4 className="page-heading">Enquiry</h4>
						<IconButton
							aria-label="add"
							color="primary"
							className="primary-icon"
							onClick={dialogAddClickOpen}
						>
							<Add />
						</IconButton>
						<IconButton
							aria-label="filter"
							color="primary"
							className="primary-icon"
							onClick={() => setFilter(!filter)}
						>
							<FilterAltOutlined />
						</IconButton>
					</div>
					{filter && (
						<div className="white-paper mb-20">
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<FormControl className="grid-radio radio-control">
										<FormLabel id="radio-label">For</FormLabel>
										<RadioGroup
											row
											aria-labelledby="radio-label"
											name="row-radio-buttons-group"
										>
											<FormControlLabel
												value="rent"
												control={<Radio />}
												label="Rent"
											/>
											<FormControlLabel
												value="buy"
												control={<Radio />}
												label="Buy"
											/>
										</RadioGroup>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<FormControl fullWidth>
										<InputLabel id="select-label">Property Type</InputLabel>
										<Select
											labelId="select-label"
											id="simple-select"
											value={selectPropertyType}
											label="Property Type"
											onChange={selectPropertyTypeChange}
										>
											<MenuItem value="PhoneCall">Phone Call</MenuItem>
											<MenuItem value="visit">Visit</MenuItem>
											<MenuItem value="meeting">Meeting</MenuItem>
											<MenuItem value="deal">Deal</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<FormControl fullWidth>
										<InputLabel id="segment-label">Segment</InputLabel>
										<Select
											labelId="segment-label"
											id="segment-select"
											value={selectSegment}
											label="Segment"
											onChange={selectSegmentChange}
										>
											<MenuItem value="PhoneCall">Phone Call</MenuItem>
											<MenuItem value="visit">Visit</MenuItem>
											<MenuItem value="meeting">Meeting</MenuItem>
											<MenuItem value="deal">Deal</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<FormControl fullWidth>
										<InputLabel id="bhk-office-label">Bhk/Office</InputLabel>
										<Select
											labelId="bhk-office-label"
											id="bhk-office-select"
											value={selectBhkOffice}
											label="Bhk/Office"
											onChange={selectBhkOfficeChange}
										>
											<MenuItem value="PhoneCall">Phone Call</MenuItem>
											<MenuItem value="visit">Visit</MenuItem>
											<MenuItem value="meeting">Meeting</MenuItem>
											<MenuItem value="deal">Deal</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<FormControl fullWidth>
										<InputLabel id="budget-label">Budget</InputLabel>
										<Select
											labelId="budget-label"
											id="budget-select"
											value={selectBudget}
											label="Budget"
											onChange={selectBudgetChange}
										>
											<MenuItem value="PhoneCall">Phone Call</MenuItem>
											<MenuItem value="visit">Visit</MenuItem>
											<MenuItem value="meeting">Meeting</MenuItem>
											<MenuItem value="deal">Deal</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<FormControl fullWidth className="mb-8">
										<InputLabel id="source-label">Source</InputLabel>
										<Select
											labelId="source-label"
											id="source-select"
											value={selectSource}
											label="Source"
											onChange={selectSourceChange}
										>
											<MenuItem value="PhoneCall">Phone Call</MenuItem>
											<MenuItem value="visit">Visit</MenuItem>
											<MenuItem value="meeting">Meeting</MenuItem>
											<MenuItem value="deal">Deal</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextField
										id="min"
										label="Min"
										type="text"
										fullWidth
										variant="outlined"
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextField
										id="max"
										label="Max"
										type="text"
										fullWidth
										variant="outlined"
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={4}
									lg={3}
									className="datepicker-wrappper"
								>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DemoContainer components={["DatePicker"]}>
											<DatePicker
												value={value}
												className="w-full"
												label="Start Date"
												onChange={(newValue) => setValue(newValue)}
											/>
										</DemoContainer>
									</LocalizationProvider>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={4}
									lg={3}
									className="datepicker-wrappper"
								>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DemoContainer components={["DatePicker"]}>
											<DatePicker
												value={value}
												className="w-full"
												label="End Date"
												onChange={(newValue) => setValue(newValue)}
											/>
										</DemoContainer>
									</LocalizationProvider>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<FormControl fullWidth>
										<InputLabel id="status-label">Status</InputLabel>
										<Select
											labelId="status-label"
											id="status-select"
											value={selectStatus}
											label="Status"
											onChange={selectStatusChange}
										>
											<MenuItem value="PhoneCall">Phone Call</MenuItem>
											<MenuItem value="visit">Visit</MenuItem>
											<MenuItem value="meeting">Meeting</MenuItem>
											<MenuItem value="deal">Deal</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<FormControl fullWidth className="mb-8">
										<InputLabel id="non-use-label">Non Use</InputLabel>
										<Select
											labelId="non-use-label"
											id="non-use-select"
											value={selectNonUse}
											label="Non Use"
											onChange={selectNonUseChange}
										>
											<MenuItem value="PhoneCall">Phone Call</MenuItem>
											<MenuItem value="visit">Visit</MenuItem>
											<MenuItem value="meeting">Meeting</MenuItem>
											<MenuItem value="deal">Deal</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<FormControl fullWidth className="mb-8">
										<InputLabel id="employee-label">Employee</InputLabel>
										<Select
											labelId="employee-label"
											id="employee-select"
											value={selectEmployee}
											label="Employee"
											onChange={selectEmployeeChange}
										>
											<MenuItem value="PhoneCall">Phone Call</MenuItem>
											<MenuItem value="visit">Visit</MenuItem>
											<MenuItem value="meeting">Meeting</MenuItem>
											<MenuItem value="deal">Deal</MenuItem>
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							<Grid
								container
								spacing={2}
								alignItems="center"
								justifyContent="end"
								sx={{ pt: { xs: "20px", sm: 0 } }}
							>
								<Grid item xs="auto">
									<Button variant="contained" onClick={() => setFilter(false)}>
										Filter
									</Button>
								</Grid>
								<Grid item xs="auto">
									<Button variant="outlined" onClick={() => setFilter(false)}>
										Cancel
									</Button>
								</Grid>
							</Grid>
						</div>
					)}
					<div className="white-paper">
						<div className="table-heading-block">
							<TextField label="Search" variant="outlined" />
						</div>
						<div className="table-negative-space">
							<TableContainer>
								<Table aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>
												Sr.
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowDownwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Added At
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												For
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Name
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Mobile
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowDownwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Type
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Segment
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Bhk/Office
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell className="remark-col">
												Last Remark
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												NFD
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Status
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												Employee
												<IconButton aria-label="Sort" className="sort-icon">
													<ArrowUpwardOutlined />
												</IconButton>
											</TableCell>
											<TableCell>
												<div className="d-flex justify-content-center align-item-center">
													Add
													<IconButton aria-label="Sort" className="sort-icon">
														<ArrowUpwardOutlined />
													</IconButton>
												</div>
											</TableCell>
											<TableCell>
												<div className="d-flex justify-content-center align-item-center">
													M/W/V
													<IconButton aria-label="Sort" className="sort-icon">
														<ArrowUpwardOutlined />
													</IconButton>
												</div>
											</TableCell>
											<TableCell className="action-col" align="center">
												Action
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell>1</TableCell>
											<TableCell>21/05/23</TableCell>
											<TableCell>Buy</TableCell>
											<TableCell>Prakashbhai Thakkar</TableCell>
											<TableCell>9909545734</TableCell>
											<TableCell>Residential</TableCell>
											<TableCell>Flat</TableCell>
											<TableCell>2Bhk</TableCell>
											<TableCell className="remark-col">
												<Link href="#" onClick={dialogRemarkClickOpen}>
													Looking for his relatives, bharatbhai reference, he
													will revert after discussion
												</Link>
											</TableCell>
											<TableCell>22/05/23</TableCell>
											<TableCell>Open</TableCell>
											<TableCell>Bhargav Rakholiya</TableCell>
											<TableCell align="center">
												<IconButton
													onClick={dialogAddNewFollowupClickOpen}
													className="ml-ng-10"
												>
													<AddCircleOutline className="blue-icon" />
												</IconButton>
											</TableCell>
											<TableCell align="center">
												<div className="button-wrapper ml-ng-10">
													<IconButton href="http://wa.me/917742073462">
														<SendOutlined className="blue-icon" />
													</IconButton>
													<IconButton onClick={enquiryDialogHandleClickOpen}>
														<Visibility className="blue-icon" />
													</IconButton>
												</div>
											</TableCell>
											<TableCell className="action-col" align="center">
												<div className="button-wrapper">
													<IconButton
														aria-label="Edit"
														color="primary"
														onClick={dialogAddClickOpen}
													>
														<Edit />
													</IconButton>
													<IconButton
														aria-label="Delete"
														color="primary"
														onClick={deleteDialogHandleClickOpen}
													>
														<Delete />
													</IconButton>
												</div>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>2</TableCell>
											<TableCell>13/05/23</TableCell>
											<TableCell>Buy</TableCell>
											<TableCell>Chirag Thakkar</TableCell>
											<TableCell>9913802767</TableCell>
											<TableCell>Residential</TableCell>
											<TableCell>Villa</TableCell>
											<TableCell>3Bhk</TableCell>
											<TableCell className="remark-col">
												<Link href="#" onClick={dialogRemarkClickOpen}>
													Need bungalow 3Cr, 2-3Car parking, share details he
													will visit weekdays
												</Link>
											</TableCell>
											<TableCell>15/05/23</TableCell>
											<TableCell>Open</TableCell>
											<TableCell>Bhargav Rakholiya</TableCell>
											<TableCell align="center">
												<IconButton
													onClick={dialogAddNewFollowupClickOpen}
													className="ml-ng-10"
												>
													<AddCircleOutline className="blue-icon" />
												</IconButton>
											</TableCell>
											<TableCell align="center">
												<div className="button-wrapper ml-ng-10">
													<IconButton href="http://wa.me/917742073462">
														<SendOutlined className="blue-icon" />
													</IconButton>
													<IconButton onClick={enquiryDialogHandleClickOpen}>
														<Visibility className="blue-icon" />
													</IconButton>
												</div>
											</TableCell>
											<TableCell className="action-col" align="center">
												<div className="button-wrapper">
													<IconButton
														aria-label="Edit"
														color="primary"
														onClick={dialogAddClickOpen}
													>
														<Edit />
													</IconButton>
													<IconButton
														aria-label="Delete"
														color="primary"
														onClick={deleteDialogHandleClickOpen}
													>
														<Delete />
													</IconButton>
												</div>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>3</TableCell>
											<TableCell>13/05/23</TableCell>
											<TableCell>Buy</TableCell>
											<TableCell>Girish Thakkar</TableCell>
											<TableCell>9924276006</TableCell>
											<TableCell>Residential</TableCell>
											<TableCell>Flat</TableCell>
											<TableCell>3Bhk</TableCell>
											<TableCell className="remark-col">
												<Link href="#" onClick={dialogRemarkClickOpen}>
													He is on vacation trip, follow Saturday
												</Link>
											</TableCell>
											<TableCell>03/06/23</TableCell>
											<TableCell>Open</TableCell>
											<TableCell>Bhargav Rakholiya</TableCell>
											<TableCell align="center">
												<IconButton
													onClick={dialogAddNewFollowupClickOpen}
													className="ml-ng-10"
												>
													<AddCircleOutline className="blue-icon " />
												</IconButton>
											</TableCell>
											<TableCell align="center">
												<div className="button-wrapper ml-ng-10">
													<IconButton href="http://wa.me/917742073462">
														<SendOutlined className="blue-icon" />
													</IconButton>
													<IconButton onClick={enquiryDialogHandleClickOpen}>
														<Visibility className="blue-icon" />
													</IconButton>
												</div>
											</TableCell>
											<TableCell className="action-col" align="center">
												<div className="button-wrapper">
													<IconButton
														aria-label="Edit"
														color="primary"
														onClick={dialogAddClickOpen}
													>
														<Edit />
													</IconButton>
													<IconButton
														aria-label="Delete"
														color="primary"
														onClick={deleteDialogHandleClickOpen}
													>
														<Delete />
													</IconButton>
												</div>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
							<div className="pagination-wrapper">
								<TablePagination
									component="div"
									count={100}
									page={page}
									onPageChange={handleChangePage}
									rowsPerPage={rowsPerPage}
									onRowsPerPageChange={handleChangeRowsPerPage}
								/>
							</div>
						</div>
					</div>
				</div>

				{/* Add New Dialog */}
				<Dialog
					open={dialogAddOpen}
					onClose={dialogAddClose}
					maxWidth="lg"
					fullWidth
				>
					<DialogTitle>
						Add New Enquiry
						<IconButton aria-label="Close" onClick={dialogAddClose}>
							<CloseOutlined />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="name"
									label="Name"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="mo-no"
									label="Mobile No"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="email"
									label="Email"
									type="email"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl className="grid-radio radio-control">
									<FormLabel id="demo-row-radio-buttons-group-label">
										For
									</FormLabel>
									<RadioGroup
										row
										aria-labelledby="demo-row-radio-buttons-group-label"
										name="row-radio-buttons-group"
									>
										<FormControlLabel
											value="rent"
											control={<Radio />}
											label="Rent"
										/>
										<FormControlLabel
											value="buy"
											control={<Radio />}
											label="Buy"
										/>
									</RadioGroup>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth>
									<InputLabel id="select-label">Property Type</InputLabel>
									<Select
										labelId="select-label"
										id="simple-select"
										value={selectPropertyType}
										label="Property Type"
										onChange={selectPropertyTypeChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth>
									<InputLabel id="segment-label">Segment</InputLabel>
									<Select
										labelId="segment-label"
										id="segment-select"
										value={selectSegment}
										label="Segment"
										onChange={selectSegmentChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth>
									<InputLabel id="bhk-office-label">Bhk/Office</InputLabel>
									<Select
										labelId="bhk-office-label"
										id="bhk-office-select"
										value={selectBhkOffice}
										label="Bhk/Office"
										onChange={selectBhkOfficeChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth>
									<InputLabel id="budget-label">Budget</InputLabel>
									<Select
										labelId="budget-label"
										id="budget-select"
										value={selectBudget}
										label="Budget"
										onChange={selectBudgetChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="minSize"
									label="Min Size"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="maxSize"
									label="Max Size"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControlLabel
									control={<Checkbox defaultChecked />}
									label="Non Use?"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth className="mb-8">
									<InputLabel id="source-label">Source</InputLabel>
									<Select
										labelId="source-label"
										id="source-select"
										value={selectSource}
										label="Source"
										onChange={selectSourceChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth>
									<InputLabel id="status-label">Status</InputLabel>
									<Select
										labelId="status-label"
										id="status-select"
										value={selectStatus}
										label="Status"
										onChange={selectStatusChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={8} lg={6}>
								<TextField
									id="area"
									label="Area"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<FormControl fullWidth>
									<InputLabel id="assign-to-label">Assign To</InputLabel>
									<Select
										labelId="assign-to-label"
										id="assign-to-select"
										value={selectAssignTo}
										label="Assign To"
										onChange={selectAssignToChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={8} lg={6}>
								<TextField
									id="remark"
									label="Remark"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>

							<Grid
								item
								xs={12}
								sm={6}
								md={4}
								lg={3}
								className="datepicker-wrappper"
							>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DemoContainer components={["DatePicker"]}>
										<DatePicker
											value={value}
											className="w-full"
											label="NFD"
											onChange={(newValue) => setValue(newValue)}
										/>
									</DemoContainer>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="phone-1"
									label="Phone Number 1"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="phone-2"
									label="Phone Number 2"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="phone-3"
									label="Phone Number 3"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextField
									id="phone-4"
									label="Phone Number 4"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" onClick={dialogAddClose}>
							Submit
						</Button>
						<Button variant="outlined" onClick={dialogAddClose}>
							Cancel
						</Button>
					</DialogActions>
				</Dialog>
				{/* Add New Dialog */}
				<Dialog
					open={dialogAddNewFollowupOpen}
					onClose={dialogAddNewFollowupClose}
					maxWidth="sm"
					fullWidth
				>
					<DialogTitle>
						<Typography variant="body1" className="d-flex align-item-center">
							Add New Followup
							<span className="d-block mx-4">Kamlesh mali</span>
							<span>7742073462</span>
						</Typography>
						<IconButton aria-label="Close" onClick={dialogAddNewFollowupClose}>
							<CloseOutlined />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<FormControl fullWidth>
									<InputLabel id="parent-label">Parent</InputLabel>
									<Select
										labelId="parent-label"
										id="parent-select"
										value={selectParent}
										label="Parent"
										onChange={selectParentChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormControl fullWidth>
									<InputLabel id="child-label">Child</InputLabel>
									<Select
										labelId="child-label"
										id="child-select"
										value={selectChild}
										label="Child"
										onChange={selectChildChange}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6} className="datepicker-wrappper">
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DemoContainer components={["DatePicker"]}>
										<DatePicker
											value={value}
											className="w-full"
											label="NFD"
											onChange={(newValue) => setValue(newValue)}
										/>
									</DemoContainer>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormControl fullWidth>
									<InputLabel id="statuslabel">Status</InputLabel>
									<Select
										labelId="statuslabel"
										id="statusselect"
										value={selectStatus2}
										label="Status"
										onChange={selectStatus2Change}
									>
										<MenuItem value="PhoneCall">Phone Call</MenuItem>
										<MenuItem value="visit">Visit</MenuItem>
										<MenuItem value="meeting">Meeting</MenuItem>
										<MenuItem value="deal">Deal</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<TextField
									id="remark"
									label="Remark"
									type="text"
									fullWidth
									variant="outlined"
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" onClick={dialogAddNewFollowupClose}>
							Add
						</Button>
						<Button variant="outlined" onClick={dialogAddNewFollowupClose}>
							Cancel
						</Button>
					</DialogActions>
				</Dialog>
				{/* Enquiry Detail */}
				<Dialog
					open={enquiryDialogOpen}
					onClose={enquiryDialogHandleClose}
					maxWidth="md"
					fullWidth
				>
					<DialogTitle>
						Enquiry Details
						<IconButton aria-label="Close" onClick={enquiryDialogHandleClose}>
							<CloseOutlined />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Typography variant="body2" className="text-light-grey">
									Added At
								</Typography>
								<Typography variant="body1" className="text-dark-black">
									18/05/23
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Typography variant="body2" className="text-light-grey">
									For
								</Typography>
								<Typography variant="body1" className="text-dark-black">
									Buy
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Typography variant="body2" className="text-light-grey">
									Name
								</Typography>
								<Typography variant="body1" className="text-dark-black">
									Kamlesh mali
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Typography variant="body2" className="text-light-grey">
									Mobile
								</Typography>
								<Typography variant="body1" className="text-dark-black">
									7742073462
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Typography variant="body2" className="text-light-grey">
									Type
								</Typography>
								<Typography variant="body1" className="text-dark-black">
									Commercial
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Typography variant="body2" className="text-light-grey">
									Segment
								</Typography>
								<Typography variant="body1" className="text-dark-black">
									Showroom / Shop
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Typography variant="body2" className="text-light-grey">
									Bhk-Floor
								</Typography>
								<Typography variant="body1" className="text-dark-black">
									Showroom
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Typography variant="body2" className="text-light-grey">
									Last Remark
								</Typography>
								<Typography variant="body1" className="text-dark-black">
									Aristo new
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Typography variant="body2" className="text-light-grey">
									Next Followup Date
								</Typography>
								<Typography variant="body1" className="text-dark-black">
									22/05/23
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Typography variant="body2" className="text-light-grey">
									Budget
								</Typography>
								<Typography variant="body1" className="text-dark-black">
									70-1cr
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Typography variant="body2" className="text-light-grey">
									Status
								</Typography>
								<Typography variant="body1" className="text-dark-black">
									Open
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Typography variant="body2" className="text-light-grey">
									Source
								</Typography>
								<Typography variant="body1" className="text-dark-black">
									Reference
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Typography variant="body2" className="text-light-grey">
									Non Use?
								</Typography>
								<Typography variant="body1" className="text-dark-black">
									-
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Typography variant="body2" className="text-light-grey">
									Assign By
								</Typography>
								<Typography variant="body1" className="text-dark-black">
									Bhargav Rakholiya
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Typography variant="body2" className="text-light-grey">
									Employee?
								</Typography>
								<Typography variant="body1" className="text-dark-black">
									Bhargav Rakholiya
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Typography variant="body2" className="text-light-grey">
									Area
								</Typography>
								<Typography variant="body1" className="text-dark-black">
									Gota
								</Typography>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" onClick={enquiryDialogHandleClose}>
							Cancel
						</Button>
					</DialogActions>
				</Dialog>
				{/* Delete Dialog */}
				<Dialog
					open={deleteDialogOpen}
					onClose={deleteDialogHandleClose}
					maxWidth="xs"
					fullWidth
				>
					<DialogTitle>
						Delete Confirmation
						<IconButton aria-label="Close" onClick={deleteDialogHandleClose}>
							<CloseOutlined />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure want to delete this recored?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="error"
							onClick={deleteDialogHandleClose}
						>
							Delete
						</Button>
						<Button variant="outlined" onClick={deleteDialogHandleClose}>
							Cancel
						</Button>
					</DialogActions>
				</Dialog>

				{/* Remark Dialog */}
				<Dialog
					open={dialogRemarkOpen}
					onClose={dialogRemarkClose}
					maxWidth="md"
					fullWidth
				>
					<DialogTitle>
						<Typography variant="body1" className="d-flex align-item-center">
							Communication
							<span className="d-block mx-4">Kamlesh mali</span>
							<span>7742073462</span>
						</Typography>
						<IconButton aria-label="Close" onClick={dialogRemarkClose}>
							<CloseOutlined />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Sr.</TableCell>
										<TableCell>Date</TableCell>
										<TableCell className="remark-col">Remark</TableCell>
										<TableCell>NFD</TableCell>
										<TableCell>User</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell>1</TableCell>
										<TableCell>05-18-2023</TableCell>
										<TableCell className="remark-col">
											Phone Call : Call back request : He will available for
											meeting on Tuesday
										</TableCell>
										<TableCell>05-22-2023</TableCell>
										<TableCell>Bhargav Rakholiya</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>2</TableCell>
										<TableCell>05-18-2023</TableCell>
										<TableCell className="remark-col">
											Phone Call : Call back request : He will available for
											meeting on Tuesday
										</TableCell>
										<TableCell>05-22-2023</TableCell>
										<TableCell>Bhargav Rakholiya</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>3</TableCell>
										<TableCell>05-18-2023</TableCell>
										<TableCell className="remark-col">
											Phone Call : Call back request : He will available for
											meeting on Tuesday
										</TableCell>
										<TableCell>05-22-2023</TableCell>
										<TableCell>Bhargav Rakholiya</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>4</TableCell>
										<TableCell>05-18-2023</TableCell>
										<TableCell className="remark-col">
											Phone Call : Call back request : He will available for
											meeting on Tuesday
										</TableCell>
										<TableCell>05-22-2023</TableCell>
										<TableCell>Bhargav Rakholiya</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" onClick={dialogRemarkClose}>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		</div>
	);
};
export default Enquiry;
