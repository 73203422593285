import { Delete } from "@mui/icons-material";
import {
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TableCell,
	TableRow,
	TextField,
} from "@mui/material";
import { CustomDatePicker } from "../common/custom-date-picker";
import { isInputOnlyNumber } from "../../utility/helpers/common";

type ComponentProps = {
	data: any | undefined;
	onUpdateRowData: any;
	onRemovePaymentRowData: any;
	index: any;
};

export function PaymentOptionRow(props: ComponentProps) {
	const { data, onUpdateRowData, index, onRemovePaymentRowData } = props;

	const onUpdatingRowData = (name: any, value: any) => {
		onUpdateRowData(index, { ...data, [name]: value });
	};

	return (
		<TableRow>
			<TableCell>
				<FormControl fullWidth>
					<InputLabel id="Select-Payment-Option-label">
						Payment Option
					</InputLabel>
					<Select
						labelId="Select-Payment-Option-label"
						id="simple-select"
						value={data.paymentOption}
						label="Select Payment Option"
						onChange={(e) =>
							onUpdatingRowData("paymentOption", +e.target.value)
						}
					>
						<MenuItem value="1">Cash</MenuItem>
						<MenuItem value="2">Bank/Cheque/UPI</MenuItem>
					</Select>
				</FormControl>
			</TableCell>
			<TableCell>
				<TextField
					label="Amount"
					onChange={(e) => {
						if (isInputOnlyNumber(e.target.value)) {
							onUpdatingRowData("amount", e.target.value);
						}
					}}
					type="text"
					fullWidth
					value={data.amount}
					variant="outlined"
				/>
			</TableCell>
			<TableCell className="datepicker-wrappper">
				<CustomDatePicker
					disabled={false}
					label="Date"
					onChangeValue={(newValue: any) => onUpdatingRowData("date", newValue)}
					value={data.date}
					maxDate={new Date().setFullYear(new Date().getFullYear() + 200)}
					minDate={new Date().setFullYear(new Date().getFullYear() - 200)}
				/>
			</TableCell>
			<TableCell>
				<TextField
					onChange={(e) => onUpdatingRowData("remark", e.target.value)}
					label="Remark"
					type="text"
					value={data.remark}
					fullWidth
					variant="outlined"
				/>
			</TableCell>
			<TableCell className="action-col" align="center">
				<div className="button-wrapper">
					<IconButton
						aria-label="Delete"
						onClick={() => onRemovePaymentRowData(index)}
						color="primary"
					>
						<Delete />
					</IconButton>
				</div>
			</TableCell>
		</TableRow>
	);
}
