import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
type ComponentProps = {
	value: any;
	onChangeValue: any;
	label: string;
	disabled: boolean | false;
	minDate: any;
	maxDate: any;
};
export function CustomDatePicker(props: ComponentProps) {
	const { value, onChangeValue, label, disabled, minDate, maxDate } = props;
	return (
		<>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DemoContainer components={["DatePicker"]}>
					<DatePicker
						value={dayjs(value, "DD-MM-YYYY")}
						className="w-full"
						label={label}
						maxDate={dayjs(maxDate, "DD-MM-YYYY")}
						minDate={dayjs(minDate, "DD-MM-YYYY")}
						disabled={disabled}
						defaultValue={dayjs(new Date(), "DD-MM-YYYY")}
						format="DD-MM-YYYY"
						onChange={(newValue: any) => {
							onChangeValue(dayjs(newValue, "DD-MM-YYYY").format("DD-MM-YYYY"));
						}}
					/>
				</DemoContainer>
			</LocalizationProvider>
		</>
	);
}
