import {
	Avatar,
	Button,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	TextField,
	Tooltip,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { logo } from "../../assets/images";
import { forgotPassword, login, sendOtp } from "../../services";
import { ILoginRequest } from "../../utility/interfaces";
import { getUserDetails, setAuthorization } from "../../utility/auth-guard";
import { HttpStatusCode } from "axios";
import { toast } from "react-toastify";
import { UserDispatchContext } from "../../contexts/user-context";
import { AppRoutes } from "../../utility/app-routes";
import { emailRegex, passwordRegex, phoneRegExp } from "../../utility/constant";
import { isInputOnlyNumber } from "../../utility/helpers/common";

const LoginPage: React.FC = () => {
	const setUserContext = useContext(UserDispatchContext);
	const navigate = useNavigate();
	enum authScreens {
		loginScreen = 1,
		forgotPasswordScreen = 2,
		otpScreen = 3,
	}
	//  useStates --Start
	const [isShowPassword, setIsShowPassword] = React.useState(false);
	const [mobile, setMobileNumber] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [otp, setOtp] = React.useState("");
	const [userId, setUserId] = React.useState(0);
	const [userName, setUserName] = React.useState("");
	const [resendOtpPendingTime, setResendOtpPendingTime] = React.useState(0);
	const [authScreen, setAuthScreen] = React.useState(authScreens.loginScreen);
	const [newPassword, setNewPassword] = React.useState("");
	const [cNewPassword, setCPassword] = React.useState("");

	const handleClickShowPassword = () => setIsShowPassword((show) => !show);
	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};
	const handleOnClickLogin = async () => {
		if (mobile === "" || password === "") {
			toast.error("Mobile number and password are mandatory fields.");
			return;
		}
		if (!phoneRegExp.test(mobile)) {
			toast.error("Enter valid mobile number.");
			return;
		}
		const request: ILoginRequest = {
			phoneNumber: mobile,
			password,
		};
		const { data } = await login(request);
		if (data.status === HttpStatusCode.Ok) {
			setAuthorization(data.response.token);
			navigate(AppRoutes.dashboard);
		} else toast.error("Unauthorized.");
		const userDetails = getUserDetails();
		setUserContext(userDetails);
	};
	const resetFormValues = () => {
		setIsShowPassword(false);
		setMobileNumber("");
		setPassword("");
		setEmail("");
		setOtp("");
	};

	const handleOnClickForgotPassword = () => {
		resetFormValues();
		setAuthScreen(authScreens.forgotPasswordScreen);
	};
	const handleOnClickSendOtp = async () => {
		const isValidEmail = emailRegex.test(email);
		if (email && email !== "" && isValidEmail) {
			const { data } = await sendOtp(email);
			if (data.status === HttpStatusCode.Ok) {
				setResendOtpPendingTime(30);
				setUserId(data.response.userId);
				setUserName(data.response.userName);
				setAuthScreen(authScreens.otpScreen);
			} else toast.error(data.message);
		} else {
			toast.error("Enter valid email address.");
		}
	};
	const handleOnClickSubmit = async () => {
		const isPasswordValidate = passwordRegex.test(newPassword);
		if (!newPassword || newPassword === "" || !isPasswordValidate) {
			toast.error("Entered password not match with all criteria.");
			return;
		}
		const { data } = await forgotPassword(userId, +otp, newPassword);
		if (data.status === HttpStatusCode.Ok) {
			setAuthScreen(authScreens.loginScreen);
			toast.success("Password updated  successfully.");
		} else toast.error(data.message);
	};

	const handleOnClickResendOtp = async () => {
		if (resendOtpPendingTime >= 0) {
			setOtp("");
			const { data } = await sendOtp(email);
			if (data.status === HttpStatusCode.Ok) {
				setResendOtpPendingTime(30);
				setAuthScreen(authScreens.otpScreen);
			} else toast.error(data.message);
		}
		// Resend OTP Screen
	};
	const handleOnClickBack = () => {
		if (authScreen === authScreens.forgotPasswordScreen) {
			resetFormValues();
			setAuthScreen(authScreens.loginScreen);
		} else if (authScreen === authScreens.otpScreen) {
			setAuthScreen(authScreens.forgotPasswordScreen);
		}
	};
	const resendOtpTimeOut = async () => {
		await setTimeout(function () {
			setResendOtpPendingTime(resendOtpPendingTime - 1);
		}, 1000);
	};
	// useEffects
	useEffect(() => {
		if (resendOtpPendingTime > 0 && authScreen === authScreens.otpScreen)
			resendOtpTimeOut();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resendOtpPendingTime]);

	return (
		<div className="login-wrapper">
			<div className="login-form">
				<Tooltip title="Visit Our Website" arrow>
					<Link to="" className="logo">
						<Avatar src={logo} alt="Logo" variant="square" />
					</Link>
				</Tooltip>
				{authScreen === authScreens.loginScreen ? (
					<>
						<TextField
							label="Mobile Number"
							variant="outlined"
							type="number"
							value={mobile}
							className="mb-20"
							onChange={(e) => {
								setMobileNumber(e.target.value);
							}}
						/>
						<FormControl variant="outlined" className="mb-20">
							<InputLabel htmlFor="password">Password</InputLabel>
							<OutlinedInput
								id="password"
								type={isShowPassword ? "text" : "password"}
								onChange={(e) => {
									setPassword(e.target.value);
								}}
								value={password}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge="end"
										>
											{isShowPassword ? (
												<Tooltip title="Show" arrow>
													<VisibilityOff className="password-icon" />
												</Tooltip>
											) : (
												<Tooltip title="Hide" arrow>
													<Visibility className="password-icon" />
												</Tooltip>
											)}
										</IconButton>
									</InputAdornment>
								}
								label="Password"
							/>
						</FormControl>
						<Tooltip title="Login" arrow followCursor>
							<Button
								onClick={handleOnClickLogin}
								variant="contained"
								className="w-full mb-20"
							>
								Login
							</Button>
						</Tooltip>
						<div className="text-center" onClick={handleOnClickForgotPassword}>
							<Tooltip title="Forgot Password?" arrow>
								<Link to="" className="primary-link">
									Forgot Password?
								</Link>
							</Tooltip>
						</div>
					</>
				) : null}
				{authScreen === authScreens.forgotPasswordScreen ||
				authScreen === authScreens.otpScreen ? (
					<>
						{authScreen === authScreens.otpScreen ? (
							<TextField
								label="User Name"
								variant="outlined"
								value={userName}
								className="mb-20"
								disabled
							/>
						) : null}
						<TextField
							label="Email"
							variant="outlined"
							type="email"
							value={email}
							className="mb-20"
							disabled={authScreen === authScreens.otpScreen}
							onChange={(e) => {
								setEmail(e.target.value);
							}}
						/>
						{authScreen === authScreens.otpScreen ? (
							<>
								<FormControl variant="outlined" className="mb-20">
									<InputLabel htmlFor="password">OTP</InputLabel>
									<OutlinedInput
										id="password"
										type={isShowPassword ? "number" : "password"}
										onChange={(e) => {
											if (isInputOnlyNumber(e.target.value)) {
												setOtp(e.target.value);
											}
										}}
										inputProps={{ maxLength: 6 }}
										value={otp}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{isShowPassword ? (
														<Tooltip title="Show" arrow>
															<VisibilityOff className="password-icon" />
														</Tooltip>
													) : (
														<Tooltip title="Hide" arrow>
															<Visibility className="password-icon" />
														</Tooltip>
													)}
												</IconButton>
											</InputAdornment>
										}
										label="OTP"
									/>
								</FormControl>
								<TextField
									label="New Password"
									variant="outlined"
									type="password"
									value={newPassword}
									className="mb-20"
									onChange={(e) => {
										setNewPassword(e.target.value);
									}}
								/>
								<TextField
									label="Confirm Password"
									variant="outlined"
									type="text"
									value={cNewPassword}
									className="mb-20"
									onChange={(e) => {
										setCPassword(e.target.value);
									}}
								/>
							</>
						) : null}
						{authScreen === authScreens.forgotPasswordScreen ? (
							<Tooltip title="Login" arrow followCursor>
								<Button
									onClick={handleOnClickSendOtp}
									variant="contained"
									className="w-full mb-20"
								>
									Send OTP
								</Button>
							</Tooltip>
						) : null}
						{authScreen === authScreens.otpScreen ? (
							<>
								<Tooltip title="Login" arrow followCursor>
									<Button
										onClick={handleOnClickSubmit}
										variant="contained"
										className="w-full mb-20"
									>
										Submit
									</Button>
								</Tooltip>
								<Tooltip title="Login" arrow followCursor>
									<Button
										onClick={handleOnClickResendOtp}
										variant="contained"
										disabled={resendOtpPendingTime !== 0}
										className="w-full mb-20"
									>
										Resend OTP{" "}
										<>
											{resendOtpPendingTime > 0
												? `in ${resendOtpPendingTime}`
												: null}
										</>
									</Button>
								</Tooltip>
							</>
						) : null}
						<div className="text-center">
							<Tooltip title="Cancel" arrow>
								<Link
									style={{ margin: "20px" }}
									onClick={handleOnClickBack}
									to=""
									className="primary-link"
								>
									Back
								</Link>
							</Tooltip>
						</div>
					</>
				) : null}
			</div>
		</div>
	);
};
export default LoginPage;
