import "../common/loader-style.css";
import { IDropDownFields } from "../../utility/interfaces";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from "@mui/material";
import { EnquiryRow } from "../enquiry/enquiry-row";
import { CustomPagination } from "./pagination";
import { NoRecordFound } from "./no-record-found";
import { IChildActivityDropDownFields } from "../../utility/interfaces/master";
import {
	IDashboardSearchCriteria,
	IDropDownFieldsWithBhkofficeId,
	IEnquirySearchCriteria,
} from "../../utility/interfaces/common";
import { useState } from "react";
type ComponentProps = {
	getAllRecords: any;
	tableData: any;
	bhkOfficeList: IDropDownFields[];
	budgetList: IDropDownFieldsWithBhkofficeId[];
	sourceList: IDropDownFields[];
	enquiryStatusList: IDropDownFields[];
	nonUseList: IDropDownFields[];
	employeeList: IDropDownFields[];
	areaList: IDropDownFields[];
	childActivityList: IChildActivityDropDownFields[];
	parentActivityList: IDropDownFields[];
	handleOnChangeSearchCriteria: any;
	searchCriteria: IDashboardSearchCriteria | IEnquirySearchCriteria;
	totalRecords: number;
	getPageLoadData: any;
};
export function DashboardEnquiryTable(props: ComponentProps) {
	const {
		tableData,
		getAllRecords,
		bhkOfficeList,
		budgetList,
		sourceList,
		enquiryStatusList,
		areaList,
		nonUseList,
		employeeList,
		childActivityList,
		parentActivityList,
		handleOnChangeSearchCriteria,
		searchCriteria,
		totalRecords,
		getPageLoadData,
	} = props;
	const [searchText, setSearchText] = useState("");

	return (
		<div className="white-paper">
			<div className="table-heading-block">
				<TextField
					label="Search"
					value={searchText}
					onChange={(e) => setSearchText(e.target.value)}
					onKeyDown={(e) => {
						if (e.keyCode === 13)
							handleOnChangeSearchCriteria(searchText, "text");
					}}
					variant="outlined"
				/>
			</div>
			<div className="table-negative-space">
				<TableContainer>
					<Table aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Created On</TableCell>
								<TableCell>Client Name</TableCell>
								<TableCell>Phone Number</TableCell>
								<TableCell>Bhk/Office</TableCell>
								<TableCell>Status</TableCell>
								<TableCell style={{ maxWidth: "300px" }}>Last Remark</TableCell>
								<TableCell>NFD</TableCell>
								<TableCell>Assign To</TableCell>
								<TableCell className="action-col" align="center">
									Actions
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tableData?.map((data: any) => {
								return (
									<EnquiryRow
										data={data}
										getPageLoadData={getPageLoadData}
										getAllRecords={getAllRecords}
										key={data.id}
										bhkOfficeList={bhkOfficeList}
										budgetList={budgetList}
										sourceList={sourceList}
										enquiryStatusList={enquiryStatusList}
										nonUseList={nonUseList}
										employeeList={employeeList}
										areaList={areaList}
										childActivityList={childActivityList}
										parentActivityList={parentActivityList}
									/>
								);
							})}
						</TableBody>
						{tableData?.length <= 0 && <NoRecordFound />}
					</Table>
				</TableContainer>
				<CustomPagination
					handleOnChangeSearchCriteria={handleOnChangeSearchCriteria}
					pageNumber={searchCriteria.pageNumber}
					rowPerPage={searchCriteria.rowPerPage}
					totalRecords={totalRecords}
				/>
			</div>
		</div>
	);
}

export default DashboardEnquiryTable;
