import { AxiosResponse } from "axios";
import httpClient from "./base-service";
import { IHttpsResponse } from "../utility/interfaces";
import { APIRoutes } from "../utility/app-routes";

export const getAllDashboardEnquiries = async (
	request: any
): Promise<AxiosResponse<IHttpsResponse<any>>> =>
	httpClient.post<IHttpsResponse<any>>(
		APIRoutes.getAllDashboardEnquiries,
		request
	);
