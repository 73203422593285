import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getToken } from "./token-manager";
import { AppRoutes } from "../app-routes";

type ComponentProps = {
	element: any;
};
export const UnProtectedRoute = (props: ComponentProps) => {
	// Extract Props
	const { element } = props;
	const sessionToken = getToken();
	return !sessionToken ? (
		element || <Outlet />
	) : (
		<Navigate to={AppRoutes.dashboard} replace />
	);
};
