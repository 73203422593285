import Header from "../../../components/layouts/header";
import Sidebar from "../../../components/layouts/sidebar";
import { APIRoutes } from "../../../utility/app-routes";
import { MasterCommonRecordPage } from "../../../components/common/common-master-record/master-common-record-page";

const PropertyType: React.FC = () => {
	// Table Pagination

	return (
		<div className="page-inner">
			<>
				<MasterCommonRecordPage
					addUpdateApiUrl={APIRoutes.appUpdatePropertyType}
					deleteApiUrl={APIRoutes.deletePropertyType}
					title="Property Type"
					getAllRecordApiUrl={APIRoutes.getAllPropertyTypes}
				/>
			</>
		</div>
	);
};
export default PropertyType;
