import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from "@mui/material";
import Header from "../../../components/layouts/header";
import Sidebar from "../../../components/layouts/sidebar";
import {
	Add,
	CloseOutlined,
	FormatListBulletedOutlined,
} from "@mui/icons-material";
import React from "react";

const Menu: React.FC = () => {
	// Dialog
	const [messageDialogOpen, setMessageDialogOpen] = React.useState(false);
	const messageDialogHandleClickOpen = () => {
		setMessageDialogOpen(true);
	};
	const messageDialogHandleClose = () => {
		setMessageDialogOpen(false);
	};

	return (
		<div className="main-content">
			<Header />
			<div className="page-content">
				<Sidebar />
				<div className="page-inner">
					<div className="page-heading-wrapper">
						<h4 className="page-heading">Menu</h4>
						<IconButton
							aria-label="add"
							color="primary"
							className="primary-icon"
						>
							<Add />
						</IconButton>
					</div>
					<div className="white-paper">
						<div className="table-heading-block">
							<TextField label="Search" variant="outlined" />
						</div>
						<div className="table-negative-space">
							<TableContainer>
								<Table aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Name</TableCell>
											<TableCell>Message</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell>Back Office</TableCell>
											<TableCell>
												<IconButton
													aria-label="list"
													onClick={messageDialogHandleClickOpen}
												>
													<FormatListBulletedOutlined />
												</IconButton>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Sales Team</TableCell>
											<TableCell>
												<IconButton
													aria-label="list"
													onClick={messageDialogHandleClickOpen}
												>
													<FormatListBulletedOutlined />
												</IconButton>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Staff</TableCell>
											<TableCell>
												<IconButton
													aria-label="list"
													onClick={messageDialogHandleClickOpen}
												>
													<FormatListBulletedOutlined />
												</IconButton>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Team Leader</TableCell>
											<TableCell>
												<IconButton
													aria-label="list"
													onClick={messageDialogHandleClickOpen}
												>
													<FormatListBulletedOutlined />
												</IconButton>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Admin</TableCell>
											<TableCell>
												<IconButton
													aria-label="list"
													onClick={messageDialogHandleClickOpen}
												>
													<FormatListBulletedOutlined />
												</IconButton>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</div>
					</div>
				</div>
				<Dialog
					open={messageDialogOpen}
					onClose={messageDialogHandleClose}
					maxWidth="xs"
					fullWidth
				>
					<DialogTitle>
						Menu Access For Sales Team
						<IconButton aria-label="Close" onClick={messageDialogHandleClose}>
							<CloseOutlined />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<TableContainer>
							<Table aria-label="message table">
								<TableHead>
									<TableRow>
										<TableCell>Name Of Main</TableCell>
										<TableCell>Name Of Sub</TableCell>
										<TableCell>Permission</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell>Dashboard</TableCell>
										<TableCell></TableCell>
										<TableCell>
											<FormControlLabel
												control={<Checkbox defaultChecked />}
												label="Active"
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Enquiry</TableCell>
										<TableCell>Enquiry</TableCell>
										<TableCell>
											<FormControlLabel
												control={<Checkbox defaultChecked />}
												label="Active"
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Enquiry</TableCell>
										<TableCell>Import</TableCell>
										<TableCell>
											<FormControlLabel
												control={<Checkbox defaultChecked />}
												label="Active"
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Enquiry</TableCell>
										<TableCell>Search</TableCell>
										<TableCell>
											<FormControlLabel
												control={<Checkbox defaultChecked />}
												label="Active"
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Master</TableCell>
										<TableCell>Activity</TableCell>
										<TableCell>
											<FormControlLabel
												control={<Checkbox defaultChecked />}
												label="Active"
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Master</TableCell>
										<TableCell>Area</TableCell>
										<TableCell>
											<FormControlLabel
												control={<Checkbox defaultChecked />}
												label="Active"
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Master</TableCell>
										<TableCell>Bhk/Office</TableCell>
										<TableCell>
											<FormControlLabel
												control={<Checkbox defaultChecked />}
												label="Active"
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Master</TableCell>
										<TableCell>Budget</TableCell>
										<TableCell>
											<FormControlLabel
												control={<Checkbox defaultChecked />}
												label="Active"
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Master</TableCell>
										<TableCell>Building</TableCell>
										<TableCell>
											<FormControlLabel
												control={<Checkbox defaultChecked />}
												label="Active"
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Master</TableCell>
										<TableCell>Draft Reason</TableCell>
										<TableCell>
											<FormControlLabel
												control={<Checkbox defaultChecked />}
												label="Active"
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Master</TableCell>
										<TableCell>Enquiry Status</TableCell>
										<TableCell>
											<FormControlLabel
												control={<Checkbox defaultChecked />}
												label="Active"
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Master</TableCell>
										<TableCell>Furniture Status</TableCell>
										<TableCell>
											<FormControlLabel
												control={<Checkbox defaultChecked />}
												label="Active"
											/>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" onClick={messageDialogHandleClose}>
							Submit
						</Button>
						<Button variant="outlined" onClick={messageDialogHandleClose}>
							Cancel
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		</div>
	);
};
export default Menu;
