import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	TextField,
	Tooltip,
} from "@mui/material";
import { AddUpdateCommonRecord } from "./add-update-common-dialog";
import { useEffect, useState } from "react";
import { CustomPagination } from "../pagination";
import { MasterCommonRecordRow } from "./master-common-record-row";
import { stableSort, getComparator } from "../../../utility/helpers/common";
import { Add, Refresh } from "@mui/icons-material";
import { ISearchCriteria } from "../../../utility/interfaces/common";
import { getAllCommonRecords } from "../../../services/master-service";
import { HttpStatusCodes } from "../../../utility/enums";
import { toast } from "react-toastify";
import { NoRecordFound } from "../..";
import { tableRowPerPage } from "../../../utility/constant";
type ComponentProps = {
	getAllRecordApiUrl: string;
	addUpdateApiUrl: string;
	deleteApiUrl: string;
	title: string;
};
export function MasterCommonRecordPage(props: ComponentProps) {
	const { getAllRecordApiUrl, addUpdateApiUrl, deleteApiUrl, title } = props;
	const [isAddNewRecordOpen, setIsAddNewRecordOpen] = useState(false);
	const [searchCriteria, setSearchCriteria] = useState<ISearchCriteria>({
		rowPerPage: tableRowPerPage,
		pageNumber: 1,
		sortBy: "",
		sortDirection: "desc",
		text: "",
	});
	const [bindPageData, setBindPageData] = useState<any>([]);
	const [tableData, setTableData] = useState<any>([]);

	const handleOnChangeSearchCriteria = (value: any, name: string) => {
		setSearchCriteria({ ...searchCriteria, [name]: value });
	};
	const handleOnChangeSorting = (sortBy: string) => {
		setSearchCriteria({
			...searchCriteria,
			sortDirection: searchCriteria.sortDirection === "asc" ? "desc" : "asc",
			sortBy,
		});
	};
	const getAllRecords = async () => {
		const { data } = await getAllCommonRecords(getAllRecordApiUrl);
		if (data && data.status === HttpStatusCodes.Ok) {
			setBindPageData(data.response);
		} else if (data && data.status === HttpStatusCodes.NotFound) {
			setBindPageData([]);
		} else {
			toast.error(data.message);
			setBindPageData([]);
		}
	};
	const handleOnChangeSearchText = (value: any) => {
		setSearchCriteria({
			...searchCriteria,
			text: value,
			pageNumber: 1,
			sortBy: "name",
			sortDirection: "asc",
		});
	};
	// useEffects
	useEffect(() => {
		getAllRecords();
	}, []);
	useEffect(() => {
		let filteredData: any = [];
		stableSort(
			searchCriteria.text.length > 0
				? bindPageData.filter((data: any) =>
						data.name
							?.toLowerCase()
							.includes(searchCriteria.text?.toLowerCase())
				  )
				: bindPageData,
			getComparator(searchCriteria.sortDirection, searchCriteria.sortBy)
		).map((data) => {
			filteredData.push({
				id: +data.id,
				isActive: data.isActive ? true : false,
				name: data.name,
			});
		});
		filteredData = filteredData.slice(
			(searchCriteria.pageNumber - 1) * searchCriteria.rowPerPage,
			(searchCriteria.pageNumber - 1) * searchCriteria.rowPerPage +
				searchCriteria.rowPerPage
		);
		setTableData(filteredData);
	}, [bindPageData, searchCriteria]);

	return (
		<>
			<div className="page-heading-wrapper">
				<h4 className="page-heading">{title}</h4>
				<Tooltip title="Refresh">
					<IconButton
						aria-label="refresh"
						color="primary"
						className="primary-icon"
						onClick={getAllRecords}
					>
						<Refresh />
					</IconButton>
				</Tooltip>
				<Tooltip title="Add">
					<IconButton
						aria-label="add"
						color="primary"
						className="primary-icon"
						onClick={() => setIsAddNewRecordOpen(true)}
					>
						<Add />
					</IconButton>
				</Tooltip>
			</div>
			<div className="white-paper">
				<div className="table-heading-block">
					<TextField
						value={searchCriteria.text}
						autoFocus
						onChange={(e) => {
							handleOnChangeSearchText(e.target.value);
						}}
						label="Search"
						variant="outlined"
					/>
				</div>
				<div className="table-negative-space">
					<TableContainer>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell sortDirection={searchCriteria.sortDirection}>
										<TableSortLabel
											active={searchCriteria.sortBy === "name"}
											direction={searchCriteria.sortDirection}
											onClick={() => handleOnChangeSorting("name")}
										>
											Name
										</TableSortLabel>
									</TableCell>
									<TableCell>
										<TableSortLabel
											active={searchCriteria.sortBy === "isActive"}
											direction={searchCriteria.sortDirection}
											onClick={() => handleOnChangeSorting("isActive")}
										>
											Status
										</TableSortLabel>
									</TableCell>
									<TableCell className="action-col" align="center">
										Action
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{tableData.length > 0 &&
									tableData.map((data: any) => {
										return (
											<MasterCommonRecordRow
												addUpdateApiUrl={addUpdateApiUrl}
												deleteApiUrl={deleteApiUrl}
												title={title}
												getAllRecords={getAllRecords}
												data={data}
											/>
										);
									})}
							</TableBody>
							{tableData?.length <= 0 && <NoRecordFound />}
						</Table>
					</TableContainer>
				</div>
				<CustomPagination
					handleOnChangeSearchCriteria={handleOnChangeSearchCriteria}
					pageNumber={searchCriteria.pageNumber}
					rowPerPage={searchCriteria.rowPerPage}
					totalRecords={bindPageData?.length}
				/>
			</div>
			<>
				{/* Add record */}
				<AddUpdateCommonRecord
					dialogOpen={isAddNewRecordOpen}
					handleOnClickClose={() => setIsAddNewRecordOpen(false)}
					dialogTitle={title}
					getAllRecords={getAllRecords}
					apiPath={addUpdateApiUrl}
					data={undefined}
				/>
			</>
		</>
	);
}
