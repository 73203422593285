import { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	TextField,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import { updateUserPassword } from "../../services";
import { HttpStatusCodes } from "../../utility/enums";
import { passwordRegex } from "../../utility/constant";

type ComponentProps = {
	dialogOpen: boolean;
	handleOnClickClose: any;
};
export function PasswordChangeDialog(props: ComponentProps) {
	const { dialogOpen, handleOnClickClose } = props;
	const [password, setPassword] = useState("");
	const [oPassword, setOPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const handleOnUpdatePassword = async () => {
		if (oPassword === "" || password === "" || confirmPassword === "") {
			toast.error(
				"Old password, Password and Confirm password are mandatory fields."
			);
			return;
		}
		if (password !== confirmPassword) {
			toast.error("Password and confirm password are not matched.");
			return;
		}
		const isPasswordValidate = passwordRegex.test(password);
		if (!password || password === "" || !isPasswordValidate) {
			toast.error(
				"Please make your password more secure, for an example: Example@123"
			);
			return;
		}
		const response = await updateUserPassword({
			newPassword: password,
			oldPassword: oPassword,
		});
		if (response?.data.status === HttpStatusCodes.Ok) {
			toast.success(response.data.message);
			handleOnClickClose();
		} else toast.error(response.data.message);
	};
	// useEffects
	useEffect(() => {
		setPassword("");
		setOPassword("");
		setConfirmPassword("");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dialogOpen]);

	return (
		<Dialog
			open={dialogOpen}
			onClose={handleOnClickClose}
			maxWidth="xs"
			fullWidth
		>
			<DialogTitle>
				Change Password
				<IconButton aria-label="Close" onClick={handleOnClickClose}>
					<CloseOutlined />
				</IconButton>
			</DialogTitle>
			<>
				<DialogContent>
					<Grid container spacing={2} alignItems="center" className="mb-20">
						<Grid item xs={12} sm={12} md={12}>
							<TextField
								id="oldPassword"
								label="Old password"
								type="password"
								fullWidth
								variant="outlined"
								value={oPassword}
								onChange={(e) => setOPassword(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<TextField
								id="password"
								label="Password"
								type="password"
								fullWidth
								variant="outlined"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<TextField
								id="cpassword"
								label="Confirm Password"
								type="text"
								fullWidth
								variant="outlined"
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
							/>
						</Grid>
					</Grid>
					<DialogActions>
						<Button variant="contained" onClick={handleOnUpdatePassword}>
							Update
						</Button>
					</DialogActions>
				</DialogContent>
			</>
		</Dialog>
	);
}
