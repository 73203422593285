import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import {
	DesignerRouterConfig,
	DeveloperRouterConfig,
} from "./utility/auth-guard";
import { ThemeProvider } from "@mui/material";
import customTheme from "./App.theme";
import { ENVIRONMENT } from "./config";
import { environment } from "./utility/enums";
import { UserProvider } from "./contexts/user-context";

function App() {
	return (
		<UserProvider>
			<ThemeProvider theme={customTheme}>
				<React.Suspense fallback={<>Error Occurred</>}>
					<BrowserRouter>
						<ToastContainer />
						{environment.design.toString() === ENVIRONMENT.toString() ? (
							<DesignerRouterConfig />
						) : (
							<DeveloperRouterConfig />
						)}
					</BrowserRouter>
				</React.Suspense>
			</ThemeProvider>
		</UserProvider>
	);
}

export default App;
