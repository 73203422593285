import { CloseOutlined } from "@mui/icons-material";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { HttpStatusCodes } from "../../utility/enums";
import { getAllEnquiryRemark } from "../../services";
import { NoRecordFound } from "../common/no-record-found";

type ComponentProps = {
	dialogOpen: boolean;
	handleOnClickClose: any;
	rowData: any;
};
export function ViewAllRemarks(props: ComponentProps) {
	const { dialogOpen, handleOnClickClose, rowData } = props;

	const [tableData, setTableData] = useState<any>([]);

	const getAllRemarks = async () => {
		const { data } = await getAllEnquiryRemark(rowData.id);
		if (data && data.status === HttpStatusCodes.Ok) {
			if (data.response.length > 0) setTableData(data.response);
			else setTableData([]);
		} else {
			setTableData([]);
		}
	};

	useEffect(() => {
		if (dialogOpen) {
			getAllRemarks();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dialogOpen]);

	return (
		<Dialog
			open={dialogOpen}
			onClose={handleOnClickClose}
			maxWidth="xl"
			fullWidth
		>
			<DialogTitle>
				<Typography variant="body1" className="d-flex align-item-center">
					<span className="d-block mx-4">{rowData?.nameOfClient}</span>
					<span>{`(${rowData?.phoneNumber})`}</span>
				</Typography>
				<IconButton aria-label="Close" onClick={handleOnClickClose}>
					<CloseOutlined />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Created On</TableCell>
								<TableCell className="remark-col">Remark</TableCell>
								<TableCell>NFD</TableCell>
								<TableCell>Added By</TableCell>
								<TableCell>Enquiry Status</TableCell>
								<TableCell>Activity</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tableData?.map((data: any) => {
								return (
									<TableRow key={data?.id}>
										<TableCell>{data?.createdOn}</TableCell>
										<TableCell className="remark-col">{data.remark}</TableCell>
										<TableCell>{data?.nfd}</TableCell>
										<TableCell>{data.createdByName}</TableCell>
										<TableCell>{data?.enquiryStatusName}</TableCell>
										<TableCell>{data.activityChildName}</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
						{tableData?.length <= 0 && <NoRecordFound />}
					</Table>
				</TableContainer>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={handleOnClickClose}>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
}
