export enum DealType {
	All = 0,
	Rent = 1,
	Buy = 2,
	Sell = 3,
}

export const getDealTypeText = (type: number) => {
	if (type === DealType.Buy) return "Buy";
	else if (type === DealType.Sell) return "Sell";
	else if (type === DealType.Rent) return "Rent";
	else if (type === DealType.All) return "All";
	else return "";
};
