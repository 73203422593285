import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { useState } from "react";
import { AddUpdateProperty } from "./add-update-dialog";
import { DeleteCommonRecord } from "../common/common-master-record/delete-common-record";
import { APIRoutes } from "../../utility/app-routes";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import { getDealTypeText } from "../../utility/enums/common/deal-type";
import { ViewProperty } from "./view-property";

type ComponentProps = {
	data: any;
	getAllRecords: any;
};
export function PropertyRow(props: ComponentProps) {
	const { data, getAllRecords } = props;
	const [isUpdateRecordOpen, setIsUpdateRecordOpen] = useState(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [isViewPropertyOpen, setIsViewPropertyOpen] = useState(false);

	return (
		<>
			<TableRow key={data.id}>
				<TableCell>{getDealTypeText(data.propertyFor)}</TableCell>
				<TableCell>{data.buildingName}</TableCell>
				<TableCell>{data.measurementName}</TableCell>
				<TableCell>{+data.propertyPrice}</TableCell>
				<TableCell>{data.ownerName}</TableCell>
				<TableCell>{data.mobile}</TableCell>
				<TableCell>{data.propertyStatusName}</TableCell>
				<TableCell className="action-col" align="center">
					<div className="button-wrapper">
						<Tooltip title="View Property">
							<IconButton onClick={() => setIsViewPropertyOpen(true)}>
								<Visibility className="blue-icon" />
							</IconButton>
						</Tooltip>
						<Tooltip title="Edit Property">
							<IconButton
								onClick={() => setIsUpdateRecordOpen(true)}
								aria-label="Edit"
								color="primary"
							>
								<Edit />
							</IconButton>
						</Tooltip>
						<Tooltip title="Delete Property">
							<IconButton
								aria-label="Delete"
								onClick={() => setIsDeleteDialogOpen(true)}
								color="primary"
							>
								<Delete />
							</IconButton>
						</Tooltip>
					</div>
				</TableCell>
			</TableRow>
			<ViewProperty
				data={data}
				dialogOpen={isViewPropertyOpen}
				handleOnClickClose={() => setIsViewPropertyOpen(false)}
			/>
			{/* Update record  */}
			<AddUpdateProperty
				dialogOpen={isUpdateRecordOpen}
				handleOnClickClose={() => setIsUpdateRecordOpen(false)}
				data={data}
				getAllRecords={getAllRecords}
			/>
			<DeleteCommonRecord
				getAllRecords={getAllRecords}
				apiPath={`${APIRoutes.deleteProperty}/${data.id}`}
				dialogOpen={isDeleteDialogOpen}
				handleOnClickClose={() => setIsDeleteDialogOpen(false)}
			/>
		</>
	);
}
