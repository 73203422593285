import { TablePagination } from "@mui/material";

type ComponentProps = {
	pageNumber: number;
	rowPerPage: number;
	totalRecords: number;
	handleOnChangeSearchCriteria: any;
};
export function CustomPagination(props: ComponentProps) {
	// Extract props
	const { pageNumber, rowPerPage, handleOnChangeSearchCriteria, totalRecords } =
		props;
	return (
		<div className="pagination-wrapper">
			<TablePagination
				component="div"
				count={totalRecords}
				page={pageNumber - 1}
				onPageChange={(e: any, newPage: number) => {
					handleOnChangeSearchCriteria(newPage + 1, "pageNumber");
				}}
				rowsPerPageOptions={[10, 25, 40, 80, 100]}
				rowsPerPage={rowPerPage}
				onRowsPerPageChange={(e) =>
					handleOnChangeSearchCriteria(e.target.value, "rowPerPage")
				}
			/>
		</div>
	);
}
