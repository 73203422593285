import { Navigate, Outlet } from "react-router-dom";
import { getToken } from "./token-manager";
import { AppRoutes } from "../app-routes";
type ComponentProps = {
	element: any;
	menuId: number;
	userPermission: number[] | undefined;
};
export const ProtectedRoute = (props: ComponentProps) => {
	// Extract Props

	const { element, menuId, userPermission } = props;
	const sessionToken = getToken();
	return sessionToken ? (
		<>{userPermission?.includes(menuId) ? element || <Outlet /> : <></>}</>
	) : (
		<Navigate to={AppRoutes.login} replace />
	);
};
