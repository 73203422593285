import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Tooltip,
} from "@mui/material";
import { Add, Refresh } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CustomPagination, NoRecordFound } from "../../components";
import { AddUpdatePropertyDeal } from "../../components/property-deals/add-update-dialog";
import { IDropDownFields, ISearchCriteria } from "../../utility/interfaces";
import { getComparator, stableSort } from "../../utility/helpers/common";
import { PropertyDealRow } from "../../components/property-deals/property-deal-row";
import { getAllCommonRecords, getAllPropertyDeal } from "../../services";
import { HttpStatusCodes } from "../../utility/enums";
import { APIRoutes } from "../../utility/app-routes";
import { tableRowPerPage } from "../../utility/constant";

const PropertyDeal: React.FC = () => {
	const [isAddNewRecordOpen, setIsAddNewRecordOpen] = useState(false);
	const [searchCriteria, setSearchCriteria] = useState<ISearchCriteria>({
		rowPerPage: tableRowPerPage,
		pageNumber: 1,
		sortBy: "dealDate",
		sortDirection: "asc",
		text: "",
	});
	const [bindPageData, setBindPageData] = useState<any>([]);
	const [tableData, setTableData] = useState<any>([]);

	const handleOnChangeSearchCriteria = (value: any, name: string) => {
		setSearchCriteria({ ...searchCriteria, [name]: value });
	};
	const handleOnChangeSorting = (sortBy: string) => {
		setSearchCriteria({
			...searchCriteria,
			sortDirection: searchCriteria.sortDirection === "asc" ? "desc" : "asc",
			sortBy,
		});
	};
	const getAllRecords = async () => {
		const { data } = await getAllPropertyDeal();
		if (data && data.status === HttpStatusCodes.Ok) {
			if (data.response.length > 0) setBindPageData(data.response);
		} else if (data && data.status === HttpStatusCodes.NotFound) {
			setBindPageData([]);
		} else {
			setBindPageData([]);
			toast.error(data.message);
		}
	};

	useEffect(() => {
		let filteredData: any = [];
		stableSort(
			searchCriteria.text.length > 0
				? bindPageData.filter((data: any) =>
						data.name
							?.toLowerCase()
							.includes(searchCriteria.text?.toLowerCase())
				  )
				: bindPageData,
			getComparator(searchCriteria.sortDirection, searchCriteria.sortBy)
		).map((data: any) => {
			filteredData.push(data);
		});
		filteredData = filteredData.slice(
			(searchCriteria.pageNumber - 1) * searchCriteria.rowPerPage,
			(searchCriteria.pageNumber - 1) * searchCriteria.rowPerPage +
				searchCriteria.rowPerPage
		);
		setTableData(filteredData);
	}, [bindPageData, searchCriteria]);

	// useEffects
	useEffect(() => {
		getAllRecords();
	}, []);

	return (
		<div className="page-inner">
			<div className="page-heading-wrapper">
				<h4 className="page-heading">Property Deal</h4>
				<Tooltip title="Refresh">
					<IconButton
						aria-label="refresh"
						color="primary"
						className="primary-icon"
						onClick={getAllRecords}
					>
						<Refresh />
					</IconButton>
				</Tooltip>
				<Tooltip title="Add Property Deal">
					<IconButton
						aria-label="add"
						color="primary"
						className="primary-icon"
						onClick={() => {
							setIsAddNewRecordOpen(true);
						}}
					>
						<Add />
					</IconButton>
				</Tooltip>
			</div>
			<div className="white-paper">
				{/* <div className="table-heading-block">
					<TextField
						label="Search"
						onChange={(e) => handleOnChangeSearchText(e.target.value)}
						variant="outlined"
					/>
				</div> */}
				<div className="table-negative-space">
					<TableContainer>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell sortDirection={searchCriteria.sortDirection}>
										<TableSortLabel
											active={searchCriteria.sortBy === "dealDate"}
											direction={searchCriteria.sortDirection}
											onClick={() => handleOnChangeSorting("dealDate")}
										>
											Deal Date
										</TableSortLabel>
									</TableCell>
									<TableCell sortDirection={searchCriteria.sortDirection}>
										<TableSortLabel
											active={searchCriteria.sortBy === "dealTypeId"}
											direction={searchCriteria.sortDirection}
											onClick={() => handleOnChangeSorting("dealTypeId")}
										>
											Type
										</TableSortLabel>
									</TableCell>
									<TableCell sortDirection={searchCriteria.sortDirection}>
										<TableSortLabel
											active={searchCriteria.sortBy === "buyerName"}
											direction={searchCriteria.sortDirection}
											onClick={() => handleOnChangeSorting("buyerName")}
										>
											Buyer Name
										</TableSortLabel>
									</TableCell>
									<TableCell sortDirection={searchCriteria.sortDirection}>
										<TableSortLabel
											active={searchCriteria.sortBy === "propertyName"}
											direction={searchCriteria.sortDirection}
											onClick={() => handleOnChangeSorting("propertyName")}
										>
											Property Name
										</TableSortLabel>
									</TableCell>
									<TableCell sortDirection={searchCriteria.sortDirection}>
										<TableSortLabel
											active={searchCriteria.sortBy === "flatOfficeNo"}
											direction={searchCriteria.sortDirection}
											onClick={() => handleOnChangeSorting("flatOfficeNo")}
										>
											Flat/Office No.
										</TableSortLabel>
									</TableCell>
									<TableCell sortDirection={searchCriteria.sortDirection}>
										<TableSortLabel
											active={searchCriteria.sortBy === "employeeName"}
											direction={searchCriteria.sortDirection}
											onClick={() => handleOnChangeSorting("employeeName")}
										>
											Employee
										</TableSortLabel>
									</TableCell>
									<TableCell>Action</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{tableData.map((data: any) => {
									return (
										<PropertyDealRow
											data={data}
											getAllRecords={getAllRecords}
											key={data.id}
										/>
									);
								})}
							</TableBody>
							{tableData?.length <= 0 && <NoRecordFound />}
						</Table>
					</TableContainer>
				</div>
				<CustomPagination
					handleOnChangeSearchCriteria={handleOnChangeSearchCriteria}
					pageNumber={searchCriteria.pageNumber}
					rowPerPage={searchCriteria.rowPerPage}
					totalRecords={bindPageData?.length}
				/>
			</div>
			<AddUpdatePropertyDeal
				dialogOpen={isAddNewRecordOpen}
				handleOnClickClose={() => setIsAddNewRecordOpen(false)}
				data={undefined}
				getAllRecords={getAllRecords}
			/>
		</div>
	);
};

export default PropertyDeal;
