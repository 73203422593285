import { TableBody, TableCell, TableRow } from "@mui/material";

export function NoRecordFound() {
	return (
		<TableBody>
			<TableRow>
				<TableCell colSpan={11} align="center" className="no-data">
					No record(s) found
				</TableCell>
			</TableRow>
		</TableBody>
	);
}
